.form-container.video-statistic {
  .short-cards-wrapper {
    margin-top: 32px;
    display: flex;
    position: relative;
    width: 100%;
    gap: 16px;
    .card-wrapper {
      position: relative;
      display: flex;
      width: 100%;
      min-height: 124px;
      height: auto;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 24px;
      border-radius: 16px;
      padding: 24px 24px;
      &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #94a0b1;
        line-height: 18px;
      }
      &__value {
        margin-top: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
      }
      &.blue {
        background: #d3e5ff;
      }
      &.pink {
        background: #ffd0e9;
      }
      &.yellow {
        background: #ffe6a6;
      }
      &.purple {
        background: #e9d6ff;
      }
      &.double {
        .card-wrapper__first-col {
          width: 50%;
        }
        .card-wrapper__second-col {
          padding-left: 24px;
          width: 50%;
        }
        .divider {
          display: block;
          position: absolute;
          width: 1px;
          top: 16px;
          left: 50%;
          background: #d0bee3;
          height: calc(100% - 32px);
        }
      }
    }
  }
  .statistic-type-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    * + * {
      margin-left: 10px;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
    }
    &:hover {
      color: #7027c2;
      path {
        fill: #7027c2;
      }
    }
  }
  .meaningful-statistic-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;
    width: 100%;
    .card-wrapper {
      position: relative;
      background: #ffffff;
      border-radius: 16px;
      padding: 24px;
      height: 168px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: calc((100% - 32px) / 3);
      &.double {
        .card-description + .card-main-info {
          margin-top: 16px;
        }
        .card-main-info {
          &__value {
            b {
              font-size: 24px;
            }
          }
        }
        .card-description {
          font-size: 13px;
        }
      }
      .card-main-info {
        display: flex;
        justify-content: space-between;
        &__value {
          * + * {
            margin-left: 12px;
          }
          display: flex;
          b {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
          }
        }
      }
      .card-description {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
      }
      .card-graph {
        display: block;
        width: 113px;
        height: 43px;
        position: absolute;
        top: 31px;
        right: 24px;
      }
      &.purple {
        background: #7027c2;
        .card-main-info {
          b {
            color: #ffffff;
          }
        }
        .card-description {
          color: #ffffff;
        }
      }
    }
  }
  .columns-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    &__col {
      width: 100%;
      display: flex;
      flex-direction: column;
      .statistic-block-container {
        width: 100%;
        position: relative;
        .pie-chart-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .income-info-block__content {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .description-wrapper {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 13px;
          margin-bottom: 9px;
          .statistic-description-content {
            display: flex;
            width: 100%;
            .statistic-content-wrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .statistic-description-content__title {
                .form-container-value-block-right {
                  display: flex;
                  justify-content: flex-end;
                  b {
                    width: 40px;
                    display: flex;
                    justify-content: flex-end;
                    margin-left: 16px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                  }
                  span {
                    color: #94a0b1;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                  }
                }
                span.title {
                  padding-left: 22px;
                  position: relative;
                  white-space: nowrap;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  &::before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 0;
                    border-radius: 10px;
                    top: calc(50% - 5px);
                  }
                  &.blue {
                    &::before {
                      background: #7027c2;
                    }
                  }
                  &.purple {
                    &::before {
                      background: #cc5ef5;
                    }
                  }
                  &.light-blue {
                    &::before {
                      background: #abcdff;
                    }
                  }
                  &.pink {
                    &::before {
                      background: #fd4aa7;
                    }
                  }
                  &.green {
                    &::before {
                      background: #0dc268;
                    }
                  }
                  &.orange {
                    &::before {
                      background: #ff9e00;
                    }
                  }
                  &.less-blue {
                    &::before {
                      background: #3586ff;
                    }
                  }
                }
              }
            }
          }
        }
        .form-container-progress-blocks {
          margin-top: 32px;
        }
        .form-container-progress-blocks,
        .home-table.half {
          width: 100%;
          .form-container-right-b {
            font-size: 15px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 11px;
            position: relative;
            display: flex;
            justify-content: space-between;
            span {
              display: block;
              font-weight: 600;
              font-size: 15px;
              line-height: 23px;
            }
          }
          .form-container-right-color {
            display: block;
            font-size: 14px;
            line-height: 23px;
            margin-bottom: 4px;
            padding-left: 14px;
            position: relative;
            &:after {
              background: #d1d8e1;
              border-radius: 6px;
              content: "";
              display: block;
              height: 6px;
              left: 0;
              position: absolute;
              top: calc(50% - 3px);
              width: 6px;
            }
            span {
              display: block;
              font-weight: 400;
              position: absolute;
              right: 0;
              top: 0;
              font-size: 14px;
              line-height: 23px;
              span {
                text-align: right;
                width: 40px;
                margin-left: 6px;
                color: #94a0b1;
                display: inline-block;
                position: relative;
              }
            }
            &.pink::after {
              background: #fd4aa7;
            }
          }
          .form-container-right-progress {
            background: #e2e8f0;
            border-radius: 3px;
            display: block;
            height: 6px;
            margin-bottom: 24px;
            margin-top: 19px;
            overflow: hidden;
            position: relative;
            .form-container-right-progressline {
              background: #fd4aa7;
              border-radius: 3px;
              height: 6px;
              position: absolute;
            }
          }
          .form-container-progress-block {
            display: flex;
            font-size: 14px;
            height: 24px;
            justify-content: space-between;
            line-height: 24px;
            margin-bottom: 10px;
            position: relative;
            width: 100%;
            &:last-child {
              margin-bottom: 0;
            }
            .form-container-progress-block-right {
              align-items: center;
              display: flex;
              justify-content: flex-end;
              position: relative;
              width: 60%;
              gap: 16px;
              .form-container-progress-block-line {
                border-radius: 6px;
                height: 6px;
                overflow: hidden;
                position: relative;
                width: calc(100% - 88px);
                .form-container-progress-block-linefill {
                  border-radius: 6px;
                  display: block;
                  height: 6px;
                  position: absolute;
                  right: 0;
                  top: 0;
                }
              }
              span {
                display: block;
                position: relative;
                text-align: right;
                width: 44px;
                white-space: nowrap;
                &:last-child {
                  width: 90px;
                }
              }
            }
          }
          &.pink {
            .form-container-progress-block-linefill {
              background: #cc5ef5;
            }
            .form-container-progress-block-right {
              span {
                color: #cc5ef5;
              }
            }
          }
          &.blue {
            .form-container-progress-block-linefill {
              background: #7027c2;
            }
            .form-container-progress-block-right {
              span {
                color: #7027c2;
              }
            }
          }
          &.green {
            .form-container-progress-block-linefill {
              background: #0dc268;
            }
            .form-container-progress-block-right {
              span {
                color: #0dc268;
              }
            }
          }
        }
      }
    }
  }
  .video-gender-ages-statistic {
    .home-table.half {
      .form-container-progress-blocks {
        &:last-child {
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid #e2e8f0;
        }
      }
    }
  }
  .video-region-statistic {
    .home-table.half {
      .form-container-progress-blocks {
        .accordion-wrapper {
          margin-top: 11px;
          &:first-child {
            margin-top: 0;
          }
          .accordion-header {
            padding-right: 36px;
            position: relative;
            .form-container-progress-block {
              display: flex;
              font-size: 13px;
              height: 21px;
              justify-content: space-between;
              line-height: 21px;
              position: relative;
              width: 100%;
              margin-bottom: 0;
            }
            span {
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
            }
            svg {
              position: absolute;
              right: 0;
              width: 24px;
              height: 24px;
              top: calc(50% - 12px);
            }
          }
          .accordion-content {
            padding-right: 36px;
            .form-container-progress-block {
              display: flex;
              font-size: 13px;
              height: 21px;
              justify-content: space-between;
              line-height: 21px;
              margin-top: 11px;
              position: relative;
              width: 100%;
              span {
                height: 21px;
              }
              .form-container-progress-block-right {
                height: 21px;
              }
              &:first-child {
                margin-top: 19px;
              }
            }
            &.open {
              padding-bottom: 17px;
              border-bottom: 1px solid #e2e8f0;
            }
          }
        }
      }
    }
  }
}

.video-depth.gadgets-statistic-header {
  .gadgets-statistic-header__title {
    display: flex;
    gap: 8px;
    align-items: center;
    padding-bottom: 21px;
    margin-bottom: 24px;
    &::after {
      content: "";
      height: 1px;
      display: block;
      width: 100%;
      position: absolute;
      top: 64px;
      left: 0;
      background: #e2e8f0;
    }
    .button_show-info {
      border: none;
      outline: none;
      background: transparent;
      cursor: pointer;
      width: 24px;
      height: 24px;
      svg {
        path {
          fill: #94a0b1;
        }
      }
      &:hover {
        svg {
          path {
            fill: #7027c2;
          }
        }
      }
    }
  }
}

.income-info-block__preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .tips-count-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 6px 10px 6px 30px;
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 12px;
        background: #ffffff;
        border: 1px solid #fe3891;
        border-radius: 80px;
        width: 12px;
        height: 12px;
      }
    }
  }
  .video-preview-wrapper {
    width: 580px;
    height: 343px;
    img {
      width: 580px;
      height: 343px;
    }
  }
}
