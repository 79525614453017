.accordion-wrapper {
    .accordion-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        svg {
            margin-left: 12px;
        }
        svg {
            transition: transform 0.3s;
        }
        &.open {
            svg {
                transform: rotate3d(1, 0, 0, 180deg);
            }
        }
    }

    .accordion-content {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.3s;
        &.open {
            max-height: 1000px;
        }
    }
}
