.bardge-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .badge-wrapper {
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 6px;
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: #ffffff;
            line-height: 21px;
        }
    }
}
