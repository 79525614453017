.select-episodes-wrapper {
    margin-top: -8px;
    .episodes-search {
        height: 24px;
        cursor: pointer;
        // &:focus {
        //     border: none !important;
        //     color: #005bff !important;
        //     &::after {
        //         background: url("/public/images/icons/ui/navigation/drop_down-blue.svg") no-repeat;
        //     }
        // }
        span,
        .select__indicators {
            display: none;
        }
        .select__control.select__control--is-focused {
            border: none !important;
            position: relative;
            box-shadow: none;
            &::after {
                background: url("/public/images/icons/ui/navigation/drop_down-blue.svg") no-repeat;
            }
            .select__single-value {
                color: #005bff !important;
            }
        }
        .select__control {
            cursor: pointer;
            border: none;
            border-radius: 8px;
            box-sizing: border-box;
            color: #212121;
            font-family: "Averta CY";
            font-size: 15px;
            height: auto;
            font-weight: 600;
            min-height: 24px;
            outline: none;
            position: relative;
            width: 100% !important;
            background: transparent;
            padding: 0;
            display: flex;
            div {
                padding: 0;
                margin: 0;
            }
            .select__placeholder {
                font-weight: 400;
                font-size: 15px;
                color: #212121;
            }
            &:hover {
                border: none;
            }
            &::after {
                background: url("/public/images/icons/ui/navigation/drop_down.svg") no-repeat;
                content: "";
                display: block;
                height: 24px;
                pointer-events: none;
                position: absolute;
                right: 0px;
                width: 24px;
                margin: 0;
                padding: 0;
            }
            input {
                font-family: "Averta CY";
                font-size: 15px;
                cursor: text;
                height: auto;
                margin: 0;
                overflow: hidden;
                padding: 0;
                position: relative;
                max-width: 100%;
                width: 100%;
                display: inline-block;
                user-select: none;
                caret-color: transparent;
                &:focus {
                    border: none;
                }
                &:hover {
                    border: none;
                }
            }
            span {
                background: #d1d8e1;
                border-radius: 6px;
                box-sizing: border-box;
                color: #212121;
                cursor: pointer;
                font-size: 15px;
                line-height: 29px;
                margin-bottom: 4px;
                margin-right: 4px;
                max-width: 100%;
                padding: 0 24px 0 8px;
                position: relative;
                i {
                    display: block;
                    font-size: 1px;
                    height: 16px;
                    position: absolute;
                    right: 5px;
                    top: 6px;
                    width: 16px;
                    svg {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }
        .select__menu {
            background: #fff;
            border-radius: 8px;
            border-top: 0;
            box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
            top: calc(100% + 8px);
            overflow: hidden;
            z-index: 1010;
            div {
                color: #444;
                margin: 0;
                max-height: 240px;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 0;
                position: relative;
                width: calc(100% - 8px);
                padding: 12px 0 12px 0;
                scrollbar-width: thin;
                scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
                &::-webkit-scrollbar {
                    border-radius: 2px;
                    display: block;
                    height: calc(100% - 32px);
                    overflow: hidden;
                    pointer-events: none;
                    position: absolute;
                    right: 8px;
                    top: 16px;
                    width: 4px;
                    cursor: default;
                    pointer-events: none;
                }
                &::-webkit-scrollbar-thumb {
                    background: #94a0b160;
                    border-radius: 2px;
                    height: 30%;
                    width: 100%;
                    pointer-events: none;
                }
                &::-webkit-scrollbar-track {
                    margin: 12px 0;
                }
                div {
                    cursor: pointer;
                    display: list-item;
                    font-size: 15px;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 20px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    position: relative;
                    &:hover {
                        color: #005bff;
                    }
                    &:hover {
                        background: #f4f4f8;
                    }
                }
            }
        }
    }
}
