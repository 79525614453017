#product-tumbler {
    position: relative;
    display: block;
    width: 56px;
    height: 32px;
    background: #0dc268;
    border-radius: 80px;
    transition: all 200ms linear;
    cursor: pointer;
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 26px;
        height: 26px;
        border-radius: 26px;
        background: #fff;
        left: calc(100% - 29px);
        top: 3px;
        transition: all 200ms ease-in-out;
    }
    &.readOnly {
        opacity: 0.4;
        cursor: default;
        #product-tumbler-text {
            cursor: default;
        }
        &:after {
            cursor: default;
        }
    }
    &.disabled {
        background: #ed0a34;
        &:after {
            left: 3px;
        }
        #product-tumbler-text {
            width: 137px;
            background: rgba(237, 10, 52, 0.15);
            span {
                opacity: 0;
                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }
    }
    #product-tumbler-text {
        position: absolute;
        display: block;
        right: 64px;
        top: 0;
        width: 80px;
        height: 32px;
        background: rgba(13, 194, 104, 0.15);
        border-radius: 80px;
        text-align: center;
        overflow: hidden;
        pointer-events: none;
        transition: all 200ms ease-in-out;
        span {
            position: absolute;
            display: block;
            width: 80px;
            left: calc(50% - 40px);
            color: #0dc268;
            font-weight: 400;
            font-size: 14px;
            line-height: 32px;
            opacity: 1;
            transition: all 200ms linear;
            &:nth-child(2) {
                opacity: 0;
                width: 137px;
                left: calc(50% - 77px);
                color: #ed0a34;
            }
        }
    }
}
section.viewer-statistic {
    .statistic-type-title-seller {
        display: flex;
        align-items: center;
        * + * {
            margin-left: 10px;
        }
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
        }
        .small-container-seller {
            display: flex;
            align-items: center;
            &:hover {
                color: #005bff;
                path {
                    fill: #005bff;
                    transition: all 200ms linear;
                }
            }
        }
    }
    .short-cards-wrapper {
        margin-top: 32px;
        display: flex;
        position: relative;
        width: 100%;
        gap: 16px;
        .card-wrapper {
            position: relative;
            display: flex;
            width: 100%;
            min-height: 96px;
            height: auto;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
            border-radius: 16px;
            padding: 27px 24px;
            &__title {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                color: #94a0b1;
                line-height: 18px;
            }
            &__value {
                margin-top: 2px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
            }
            &.blue {
                background: #d3e5ff;
            }
            &.pink {
                background: #ffd0e9;
            }
            &.yellow {
                background: #ffe6a6;
            }
            &.purple {
                background: #e9d6ff;
            }
            &.double {
                .card-wrapper__first-col {
                    width: 50%;
                }
                .card-wrapper__second-col {
                    padding-left: 24px;
                    width: 50%;
                }
                .divider {
                    display: block;
                    position: absolute;
                    width: 1px;
                    top: 16px;
                    left: 50%;
                    background: #d0bee3;
                    height: calc(100% - 32px);
                }
            }
        }
    }
    .meaningful-statistic-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 40px;
        width: 100%;
        .card-wrapper {
            background: #ffffff;
            border-radius: 16px;
            padding: 24px;
            height: 168px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: calc((100% - 32px) / 3);
            position: relative;
            .card-main-info {
                display: flex;
                justify-content: space-between;
                &__value {
                    * + * {
                        margin-left: 12px;
                    }
                    display: flex;
                    b {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 32px;
                    }
                }
            }
            .card-graph {
                display: block;
                width: 113px;
                height: 43px;
                position: absolute;
                top: 31px;
                right: 24px;
            }
            .card-description {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
            }
            &.purple {
                background: #7027c2;
                .card-main-info {
                    b {
                        color: #ffffff;
                    }
                }
                .card-description {
                    color: #ffffff;
                }
            }
        }
    }
    .gadgets-statistic-content {
        width: 100%;
    }
    .content-wrapper {
        width: 100%;
        .table-wrapper {
            margin-top: 34px;
            width: 100%;
            .list-table-item {
                &:last-child {
                    border: none;
                }
            }
            .list-table-header {
                margin-bottom: 9px;
                span {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
            .list-table.video-list {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                width: 100%;
                margin-bottom: 0;
                .list-table-item {
                    height: 48px;
                    .list-table-item-element {
                        line-height: 48px;
                    }
                    // span {
                    // }
                }
            }
        }
    }
    .flex-wrapper {
        display: flex;
        width: 100%;
        gap: 16px;
        .content-wrapper {
            width: 100%;
            span {
                &::after {
                    display: none;
                }
            }
        }
    }
    .list-table-header {
        width: 100%;
        &.home-best-categories {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
            position: relative;
            height: 21px;
            .list-table-header-element {
                position: relative;
                padding-left: 10px;
                pointer-events: none;
                &:after {
                    display: none;
                }

                span {
                    position: absolute;
                    display: inline-block;
                    max-width: calc(100% - 8px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    &::after {
                        display: none;
                    }
                }
            }
            .list-table-header-element:nth-child(1) {
                width: 244%;
            }
            .list-table-header-element:nth-child(2) {
                width: 102%;
            }
            .list-table-header-element:nth-child(3) {
                width: 102%;
            }
            .list-table-header-element:nth-child(4) {
                width: 56%;
            }
        }
        &.home-best-products {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
            position: relative;
            height: 21px;
            .list-table-header-element {
                position: relative;
                padding-left: 8px;
                pointer-events: none;
                &:after {
                    display: none;
                }

                span {
                    position: absolute;
                    display: inline-block;
                    max-width: calc(100% - 8px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    &::after {
                        display: none;
                    }
                }
            }
            .list-table-header-element:nth-child(1) {
                width: 210%;
                span {
                    padding-left: 44px;
                }
            }
            .list-table-header-element:nth-child(2) {
                width: 102%;
            }
            .list-table-header-element:nth-child(3) {
                width: 102%;
            }
            .list-table-header-element:nth-child(4) {
                width: 54%;
            }
        }
    }
    .list-table {
        width: 100%;
        &.home-best-categories {
            margin-bottom: 0;
            .list-table-item {
                height: 48px;
                justify-content: space-between;
                position: relative;
                border-bottom: 1px solid #e2e8f0;
                display: flex;
            }
            .list-table-item-element {
                font-size: 13px;
                padding-left: 8px;
                height: 48px;
                line-height: 48px;
                white-space: nowrap;
                span {
                    width: calc(100% - 10px);
                }
                &.green {
                    color: #0dc268;
                    text-align: right;
                }
                &.orange {
                    color: #ff9e00;
                    text-align: right;
                }
            }
            .list-table-item-element:nth-child(1) {
                width: 244%;
            }
            .list-table-item-element:nth-child(2) {
                width: 102%;
            }
            .list-table-item-element:nth-child(3) {
                width: 102%;
            }
            .list-table-item-element:nth-child(4) {
                width: 56%;
                span {
                    padding-left: 0;
                    padding-right: 8px;
                    // width: calc(100% - 18px);
                }
            }
        }
        &.home-best-products {
            margin-bottom: 0;
            .list-table-item {
                height: 48px;
                justify-content: space-between;
                position: relative;
                border-bottom: 1px solid #e2e8f0;
                display: flex;
            }
            .list-table-item-element {
                font-size: 13px;
                padding-left: 8px;
                cursor: default;
                height: 48px;
                line-height: 48px;
                span {
                    width: calc(100% - 10px);
                }
                &.green {
                    color: #0dc268;
                    text-align: right;
                }
                &.orange {
                    color: #ff9e00;
                    text-align: right;
                }
            }
            .list-table-item-element:nth-child(1) {
                width: 210%;
                img {
                    border-radius: 8px;
                    display: block;
                    height: 32px;
                    object-fit: cover;
                    position: absolute;
                    width: 32px;
                    left: 0;
                    top: calc(50% - 16px);
                }
                span {
                    padding-left: 44px;
                }
            }
            .list-table-item-element:nth-child(2) {
                width: 102%;
            }
            .list-table-item-element:nth-child(3) {
                width: 102%;
            }
            .list-table-item-element:nth-child(4) {
                width: 54%;
                span {
                    padding-left: 0;
                    padding-right: 8px;
                }
            }
        }
    }
}

.home-best-videos.list-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
    height: 21px;
    .list-table-header-element {
        position: relative;
        padding-left: 10px;
        pointer-events: none;
        background: rgba(0, 0, 0, 0);
        border: none;
        outline: none;
        box-sizing: border-box;
        color: #94a0b1;
        cursor: pointer;
        font-size: 14px;
        line-height: 24px;
        padding-left: 20px;
        display: flex;
        position: relative;
        &:after {
            display: none;
        }

        span {
            position: absolute;
            display: inline-block;
            max-width: calc(100% - 8px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            &::after {
                display: none !important;
            }
        }
    }
    .list-table-header-element:nth-child(1) {
        width: 605%;
    }
    .list-table-header-element:nth-child(2) {
        width: 148%;
    }
    .list-table-header-element:nth-child(3) {
        width: 131%;
    }
    .list-table-header-element:nth-child(4) {
        width: 138%;
    }
    .list-table-header-element:nth-child(5) {
        width: 143%;
    }
}

.list-table {
    &.home-best-videos {
        position: relative;
        margin-bottom: 0;
        .list-table-item {
            height: calc(72px + 32px);
            justify-content: space-between;
            position: relative;
            border-top: 1px solid #e2e8f0;
            display: flex;
            padding: 16px 0;
            cursor: pointer;
            color: #212121;
            text-decoration: none;
            &:hover {
                background: #f4f4f8;
            }
            &:hover {
                .tooltip {
                    display: none;
                }
                background: #f4f4f8;
            }
        }

        .list-table-item-element {
            font-size: 13px;
            padding-left: 20px;
            height: 72px;
            // line-height: 96px;
            white-space: nowrap;

            display: flex;
            position: relative;
            align-items: center;
            .preview-container {
                position: relative;
                left: 0;
                min-width: 104px;
                width: 104px;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                overflow: hidden;
                cursor: pointer;
            }
            img {
                height: 72px;
                z-index: 1;
                // object-fit: cover;
            }
            .blur-bg {
                border-radius: 8px;
                z-index: 0;
                position: absolute;
                width: 104px;
                height: 72px;
                filter: blur(10px);
                -webkit-filter: blur(10px);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                // object-fit: "contain";
            }
            span {
                display: flex;
                align-items: flex-start;
                vertical-align: top;
                position: absolute;
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                width: calc(100% - 10px);
            }
        }
        .list-table-item-element:nth-child(1) {
            width: 605%;
            display: flex;
            gap: 16px;
            align-items: center;
            display: flex;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            .wrapper {
                position: absolute;
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }
        }
        .list-table-item-element:nth-child(2) {
            width: 148%;
            &:hover {
                .tooltip {
                    display: block;
                    width: 200px;
                    left: calc(30px);
                }
            }
        }
        .list-table-item-element:nth-child(3) {
            width: 131%;
        }
        .list-table-item-element:nth-child(4) {
            width: 138%;
        }
        .list-table-item-element:nth-child(5) {
            width: 143%;
        }
    }
}

.list-table-item {
    .tooltip {
        background-color: #fff;
        right: unset;
        padding: 12px 16px;
        left: 15%;
        white-space: pre-wrap;
        // left: -6%;
        // left: 48
        transform: translate(-50%, -100%);
        &-text {
            max-width: 190px;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.1px;
            text-align: center;
        }
        &-arrow {
            right: unset;
            left: calc(100% / 2);
        }
    }
    &:hover {
        .tooltip {
            display: block;
        }
    }
}
