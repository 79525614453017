* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    outline: none;
}

@mixin auth-font-style {
    color: #212121;
    font-family: "Averta CY";
}

section#auth {
    background: url("/public/images/index/auth/authbg.jpeg") no-repeat 50% 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    height: 100vh;
    overflow-x: hidden;
    background-size: cover;
    #auth-form {
        position: relative;
        display: block;
        width: 410px;
        padding: 56px 45px 52px;
        background: #ffffff;
        box-shadow: 16px 48px 64px rgba(54, 79, 199, 0.2), 8px 8px 32px rgba(54, 79, 199, 0.2);
        border-radius: 16px;
        box-sizing: border-box;

        h2.title {
            text-align: center;
            margin-bottom: 32px;
            font-size: 32px;
            line-height: 37px;
            font-weight: 600;
            @include auth-font-style;
        }

        img#logo {
            position: relative;
            display: block;
            margin: 0 auto 48px;
        }
        .flex-block {
            display: flex;
            gap: 16px;
            justify-content: space-between;
            margin-bottom: 24px;
            position: relative;
            @include auth-font-style;

            .form-field {
                display: block;
                position: relative;
                width: 100%;
                .form-field-label {
                    color: #94a0b1;
                    display: block;
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 5px;
                    margin-top: -3px;
                    position: relative;
                }
                .input-field {
                    display: block;
                    position: relative;
                    @include auth-font-style;

                    input {
                        background: #f4f4f8;
                        border: 1px solid #f4f4f8;
                        border-radius: 8px;
                        box-sizing: border-box;
                        display: block;
                        font-size: 15px;
                        height: 54px;
                        outline: none;
                        padding: 0 20px;
                        position: relative;
                        width: 100%;
                        @include auth-font-style;
                    }
                    .input-icon {
                        position: absolute;
                        display: block;
                        width: 20px;
                        height: 20px;
                        top: 18px;
                        right: 16px;
                        pointer-events: none;
                    }
                    .input-field-eye {
                        background: url("/public/images/icons/ui/forms/eye_off.svg");
                        background-size: contain;
                        cursor: pointer;
                        pointer-events: all;
                        opacity: 0.4;
                        &.show-password {
                            background: url("/public/images/icons/ui/forms/eye.svg");
                            background-size: contain;
                            opacity: 1;
                        }
                    }
                }
                .button.big-button {
                    width: 100%;
                    border: none;
                    background: #005bff;
                    box-sizing: border-box;
                    color: #fff;
                    cursor: pointer;
                    display: block;
                    font-size: 15px;
                    padding: 0 32px;
                    position: relative;
                    text-align: center;
                    border-radius: 28px;
                    height: 56px;
                    line-height: 56px;
                }
                .form-field-error {
                    color: #ed0a34;
                    display: none;
                    font-size: 13px;
                    line-height: 21px;
                    margin-top: 8px;
                    position: relative;
                }
            }
            .form-field.error {
                .input-field {
                    input {
                        border: 1px solid #ed0a34;
                    }
                }
                .form-field-error {
                    display: block;
                }
            }
        }
    }
}
