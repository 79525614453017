.list-table {
    margin-bottom: 64px;
    &.user-list {
        .list-table-item {
            display: flex;
            height: 64px;
            justify-content: space-between;
            position: relative;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            margin-bottom: 8px;
            &:hover {
                background: none;
                border: 1px solid #005bff;
            }
            .list-table-item-element {
                line-height: 64px;
                padding-left: 20px;
                position: relative;
                span {
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: calc(100% - 20px);
                    line-height: 64px;
                    a {
                        height: 64px;
                        display: block;
                        color: #212121;
                        text-decoration: none;
                        &:hover {
                            color: #005bff;
                        }
                    }
                }
            }
            .list-table-item-element:nth-child(1) {
                width: 31.5%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
                img,
                .form-container-block-preview-image {
                    width: 32px;
                    height: 32px;
                    border-radius: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        cursor: default;
                        width: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 32px;
                        font-size: 12px;
                        color: white;
                        max-height: 32px;
                        text-transform: uppercase;
                        &:hover {
                            color: white;
                        }
                    }
                }
                .user-icon {
                    position: relative;
                    display: block;
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    border-radius: 32px;
                    background: #005bff;
                    color: #fff;
                    font-size: 14px;
                }
                span {
                    position: relative;
                    display: block;
                    width: calc(100% - 48px);
                    cursor: pointer;
                    &:hover {
                        color: #005bff;
                    }
                }
            }
            .list-table-item-element:nth-child(2) {
                width: 25.6%;
            }
            // .list-table-item-element:nth-child(3) {
            //     width: 25.6%;
            // }
            .list-table-item-element:nth-child(3) {
                width: 20.9%;
                align-items: center;
                display: flex;
                justify-content: flex-start;
                span {
                    padding-right: 40px;
                }
                .list-table-item-menu {
                    align-items: center;
                    background-size: 24px 24px;
                    cursor: pointer;
                    display: flex;
                    height: 40px;
                    justify-content: center;
                    right: 0;
                    position: absolute;
                    width: 40px;
                    .list-table-item-submenu {
                        display: none;
                        &.active {
                            display: block;
                            background: #fff;
                            border-radius: 8px;
                            box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
                            padding: 12px 0;
                            position: absolute;
                            right: 0;
                            top: 40px;
                            z-index: 10;
                            .list-table-item-submenu-item.action-button {
                                cursor: pointer;
                                display: block;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 30px;
                                padding: 4px 24px 4px 20px;
                                position: relative;
                                white-space: nowrap;
                                &:hover {
                                    background: #f4f4f8;
                                    color: #005bff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
