section.sellers {
    .list-table-header {
        &.seller-list {
            margin-top: 48px;
            .list-table-header-element {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                box-sizing: border-box;
                span {
                    line-height: 17px;
                    white-space: normal;
                }
            }
        }
    }

    .list-table {
        a {
            text-decoration: none;
            color: #212121;
            font-family: "Averta CY";
        }
        &.selers-list {
            .list-table-item {
                height: 64px;
                border-bottom: 1px solid #e2e8f0;
                display: flex;
                justify-content: space-between;
                position: relative;
                align-items: flex-start;
                margin-bottom: 8px;
                border: 1px solid #e2e8f0;
                border-radius: 8px;
                box-sizing: border-box;
                padding: 12px 0;
                cursor: pointer;
                &:hover {
                    background: none;
                    border: 1px solid #005bff;
                }

                .list-table-item-element-info {
                    padding-left: 14px;
                    line-height: 30px;
                    margin-top: 0;
                    margin-bottom: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    white-space: nowrap;
                    position: absolute;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 68px);
                    height: 40px;
                    span {
                        display: block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: 22px;
                    }
                    small {
                        margin-top: 22px;
                        display: block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: 22px;
                        text-align: left;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #94a0b1;
                    }
                }
                .list-table-item-element {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: 30px;
                    font-size: 14px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    position: relative;
                    height: 40px;
                    span,
                    small {
                        display: block;
                        align-items: center;
                        overflow: hidden;
                        position: absolute;
                        text-overflow: ellipsis;
                        white-space: nowrap !important;
                        width: calc(100% - 20px);
                    }
                }
            }
        }
    }
}
.active-seller-page {
    // header
    .list-table-header-element:nth-child(1) {
        width: 240%;
        padding-left: 0 !important;
    }
    .list-table-header-element:nth-child(2) {
        width: 166%;
    }
    .list-table-header-element:nth-child(3) {
        width: 214%;
    }
    .list-table-header-element:nth-child(4) {
        width: 195%;
    }
    .list-table-header-element:nth-child(5) {
        width: 174%;
    }
    .list-table-header-element:nth-child(6) {
        min-width: 115px;
        margin-right: 16px;
    }
    // content
    .list-table-item-element:nth-child(1) {
        width: 240%;
        padding-left: 0px !important;
        left: 60px;
        img {
            border-radius: 48px;
            left: -48px;
            position: relative;
            width: 48px;
            height: 48px;
            object-fit: cover;
        }
    }
    .list-table-item-element:nth-child(2) {
        width: 166%;
    }
    .list-table-item-element:nth-child(3) {
        width: 214%;
    }
    .list-table-item-element:nth-child(4) {
        width: 195%;
    }
    .list-table-item-element:nth-child(5) {
        width: 174%;
    }
    .list-table-item-element:nth-child(6) {
        padding-left: 0;
        margin-right: 16px;
        width: 115px;
    }
}
.review-seller-page {
    // header
    .list-table-header-element:nth-child(1) {
        width: 100%;
        padding-left: 0 !important;
    }
    .list-table-header-element:nth-child(2) {
        min-width: 115px;
        margin-right: 16px;
    }
    // content
    .list-table-item-element:nth-child(1) {
        width: 100%;
        padding-left: 0px !important;
        left: 60px;
        img {
            border-radius: 48px;
            left: -48px;
            position: flex;
            position: relative;
            width: 48px;
            height: 48px;
        }
    }
    .list-table-item-element:nth-child(2) {
        padding-left: 0;
        margin-right: 16px;
        gap: 8px;
        display: flex;
        width: 218px;
    }
}

.blocked-seller-page {
    // header
    .list-table-header-element:nth-child(1) {
        width: 240%;
        padding-left: 0 !important;
    }
    .list-table-header-element:nth-child(2) {
        width: 166%;
    }
    .list-table-header-element:nth-child(3) {
        width: 214%;
    }
    .list-table-header-element:nth-child(4) {
        width: 195%;
    }
    .list-table-header-element:nth-child(5) {
        width: 174%;
    }
    .list-table-header-element:nth-child(6) {
        min-width: 136px;
        margin-right: 16px;
    }
    // content
    .list-table-item-element:nth-child(1) {
        width: 240%;
        padding-left: 0px !important;
        left: 60px;
        img {
            border-radius: 48px;
            left: -48px;
            position: flex;
            position: relative;
            width: 48px;
            height: 48px;
        }
    }
    .list-table-item-element:nth-child(2) {
        width: 166%;
    }
    .list-table-item-element:nth-child(3) {
        width: 214%;
    }
    .list-table-item-element:nth-child(4) {
        width: 195%;
    }
    .list-table-item-element:nth-child(5) {
        width: 174%;
    }
    .list-table-item-element:nth-child(6) {
        padding-left: 0;
        margin-right: 16px;
        width: 136px;
    }
}
