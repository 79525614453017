// embing
.form-container-block-embedding {
    .embedding-content {
        margin-top: 32px;
        &__title {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
        }
        .embedding-list {
            margin-top: 16px;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            padding: 8px 24px;
            &__item.first {
                border-top: none;
            }
            &__item {
                border-top: 1px solid #e2e8f0;
                display: flex;
                justify-content: space-between;
                height: 48px;
                align-items: center;
                span {
                    font-weight: 400;
                    font-size: 15px;
                }
                .embedding-control-item {
                    cursor: pointer;
                    &:hover {
                        path {
                            fill: #005bff;
                        }
                    }
                }
            }
        }
    }
    .actions-wrapper {
        height: 40px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .add-embedding {
            box-sizing: border-box;
            font-weight: 600;
            line-height: 36px;
            white-space: nowrap;
            width: 127px;
            border-radius: 20px;
            font-size: 14px;
            height: 40px;
            background: none;
            border: 2px solid #d1d8e1;
            color: #212121;
            cursor: pointer;
            display: block;
            padding: 0 32px;
            position: relative;
            text-align: center;
            padding-right: 20px;
            padding-left: 40px;
            &::before {
                background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
                content: "";
                display: block;
                height: 16px;
                left: 16px;
                position: absolute;
                top: 10px;
                width: 16px;
            }
            &:hover {
                border: 2px solid #005bff;
            }
        }
        .remove-embedding {
            color: #94a0b1;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            cursor: pointer;
            &:hover {
                color: #005bff;
            }
        }
    }
}
