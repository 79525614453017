section.viewer-statistic {
    .home-table {
        position: relative;
        display: block;
        padding: 24px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 8px;
        width: 100%;
        &.half {
            width: calc(50% - 8px);
        }

        .flex-block:last-child {
            .form-field {
                margin-bottom: 0;
            }
        }
        .form-container-block-title {
            position: relative;
            margin-bottom: 32px;
            font-size: 15px;
            line-height: 24px;
            b {
                position: relative;
                display: block;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 16px;
            }
        }
        .list-table-header {
            &.home-best-categories {
                display: flex;
                justify-content: space-between;
                margin-bottom: 13px;
                position: relative;
                height: 21px;
                // .list-table-item {
                //     //height: 48px;
                //     //line-height: 48px;
                // }
                .list-table-header-element {
                    position: relative;
                    padding-left: 10px;
                    pointer-events: none;
                    //height: 48px;
                    //line-height: 48px;
                    &:after {
                        display: none;
                    }

                    span {
                        position: absolute;
                        display: inline-block;
                        max-width: calc(100% - 8px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        &::after {
                            display: none;
                        }
                    }
                }
                .list-table-header-element:nth-child(1) {
                    width: calc(100% - 102px - 102px - 104px - 56px);
                }
                .list-table-header-element:nth-child(2) {
                    width: 102px;
                }
                .list-table-header-element:nth-child(3) {
                    width: 102px;
                }
                .list-table-header-element:nth-child(4) {
                    width: 104px;
                }
                .list-table-header-element:nth-child(5) {
                    width: 56px;
                }
            }
            &.home-best-products {
                display: flex;
                justify-content: space-between;
                margin-bottom: 13px;
                position: relative;
                height: 21px;
                .list-table-header-element {
                    position: relative;
                    padding-left: 8px;
                    pointer-events: none;
                    &:after {
                        display: none;
                    }

                    span {
                        position: absolute;
                        display: inline-block;
                        max-width: calc(100% - 8px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        &::after {
                            display: none;
                        }
                    }
                }
                .list-table-header-element:nth-child(1) {
                    width: calc(100% - 80px - 102px - 102px - 60px);
                    span {
                        padding-left: 44px;
                    }
                }
                .list-table-header-element:nth-child(2) {
                    width: 80px;
                }
                .list-table-header-element:nth-child(3) {
                    width: 102px;
                }
                .list-table-header-element:nth-child(4) {
                    width: 102px;
                }
                .list-table-header-element:nth-child(5) {
                    width: 60px;
                }
            }
            &.home-best-brightboxes {
                display: flex;
                justify-content: space-between;
                margin-bottom: 13px;
                position: relative;
                height: 21px;

                .list-table-header-element {
                    position: relative;
                    padding-left: 10px;
                    pointer-events: none;
                    //height: 48px;
                    //line-height: 48px;
                    &:after {
                        display: none;
                    }

                    span {
                        position: absolute;
                        display: inline-block;
                        max-width: calc(100% - 8px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        &::after {
                            display: none;
                        }
                    }
                }
                .list-table-header-element:nth-child(1) {
                    width: 208%;
                }
                .list-table-header-element:nth-child(2) {
                    width: 120%;
                }
                .list-table-header-element:nth-child(3) {
                    width: 120%;
                }
                .list-table-header-element:nth-child(4) {
                    width: 56%;
                }
            }
            &.home-best-salers {
                display: flex;
                justify-content: space-between;
                margin-bottom: 13px;
                position: relative;
                height: 21px;

                .list-table-header-element {
                    position: relative;
                    padding-left: 10px;
                    pointer-events: none;
                    //height: 48px;
                    //line-height: 48px;
                    &:after {
                        display: none;
                    }

                    span {
                        position: absolute;
                        display: inline-block;
                        max-width: calc(100% - 8px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        &::after {
                            display: none;
                        }
                    }
                }
                .list-table-header-element:nth-child(1) {
                    width: 208%;
                }
                .list-table-header-element:nth-child(2) {
                    width: 120%;
                }
                .list-table-header-element:nth-child(3) {
                    width: 120%;
                }
                .list-table-header-element:nth-child(4) {
                    width: 56%;
                }
            }
            &.home-best-films {
                display: flex;
                justify-content: space-between;
                margin-bottom: 13px;
                position: relative;
                height: 21px;

                .list-table-header-element {
                    position: relative;
                    padding-left: 10px;
                    pointer-events: none;
                    //height: 48px;
                    //line-height: 48px;
                    &:after {
                        display: none;
                    }

                    span {
                        position: absolute;
                        display: inline-block;
                        max-width: calc(100% - 8px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        &::after {
                            display: none;
                        }
                    }
                }
                .list-table-header-element:nth-child(1) {
                    width: 318%;
                }
                .list-table-header-element:nth-child(2) {
                    width: 112%;
                }
                .list-table-header-element:nth-child(3) {
                    width: 196%;
                }
                .list-table-header-element:nth-child(4) {
                    width: 130%;
                }
                .list-table-header-element:nth-child(5) {
                    width: 130%;
                }
                .list-table-header-element:nth-child(6) {
                    width: 130%;
                }
                .list-table-header-element:nth-child(7) {
                    width: 56%;
                }
            }
        }
        .list-table {
            &.home-best-categories {
                margin-bottom: 0;
                .list-table-item {
                    height: 48px;
                    justify-content: space-between;
                    position: relative;
                    border-bottom: 1px solid #e2e8f0;
                    display: flex;
                }
                .list-table-item-element {
                    font-size: 13px;
                    padding-left: 8px;
                    height: 48px;
                    line-height: 48px;
                    white-space: nowrap;
                    span {
                        width: calc(100% - 10px);
                    }
                    &.green {
                        color: #0dc268;
                        text-align: right;
                    }
                    &.orange {
                        color: #ff9e00;
                        text-align: right;
                    }
                }
                .list-table-item-element:nth-child(1) {
                    width: calc(100% - 102px - 102px - 104px - 56px);
                }
                .list-table-item-element:nth-child(2) {
                    width: 102px;
                }
                .list-table-item-element:nth-child(3) {
                    width: 102px;
                }
                .list-table-item-element:nth-child(4) {
                    width: 104px;
                }
                .list-table-item-element:nth-child(5) {
                    width: 56px;
                    span {
                        padding-left: 0;
                        padding-right: 8px;
                        // width: calc(100% - 18px);
                    }
                }
            }
            &.home-best-products {
                margin-bottom: 0;
                .list-table-item {
                    height: 48px;
                    justify-content: space-between;
                    position: relative;
                    border-bottom: 1px solid #e2e8f0;
                    display: flex;
                }
                .list-table-item-element {
                    font-size: 13px;
                    padding-left: 8px;
                    cursor: default;
                    height: 48px;
                    line-height: 48px;
                    span {
                        width: calc(100% - 10px);
                    }
                    &.green {
                        color: #0dc268;
                        text-align: right;
                    }
                    &.orange {
                        color: #ff9e00;
                        text-align: right;
                    }
                }
                .list-table-item-element:nth-child(1) {
                    width: calc(100% - 80px - 102px - 102px - 60px);
                    img {
                        border-radius: 8px;
                        display: block;
                        height: 32px;
                        object-fit: cover;
                        position: absolute;
                        width: 32px;
                        left: 0;
                        top: calc(50% - 16px);
                    }
                    span {
                        padding-left: 44px;
                    }
                }
                .list-table-item-element:nth-child(2) {
                    width: 80px;
                }
                .list-table-item-element:nth-child(3) {
                    width: 102px;
                }
                .list-table-item-element:nth-child(4) {
                    width: 102px;
                }
                .list-table-item-element:nth-child(5) {
                    width: 60px;
                    span {
                        padding-left: 0;
                        padding-right: 8px;
                        // width: calc(100% - 18px);
                    }
                }
            }
            &.home-best-brightboxes {
                margin-bottom: 0;
                .list-table-item {
                    height: 48px;
                    justify-content: space-between;
                    position: relative;
                    border-bottom: 1px solid #e2e8f0;
                    display: flex;
                    cursor: pointer;
                    &:hover {
                        background: #f4f4f8;
                    }
                }
                .list-table-item-element {
                    font-size: 13px;
                    padding-left: 8px;
                    height: 48px;
                    line-height: 48px;
                    span {
                        width: calc(100% - 10px);
                    }
                    &.green {
                        color: #0dc268;
                        text-align: right;
                    }
                    &.orange {
                        color: #ff9e00;
                        text-align: right;
                    }
                }
                .list-table-item-element:nth-child(1) {
                    width: 208%;
                }
                .list-table-item-element:nth-child(2) {
                    width: 120%;
                }
                .list-table-item-element:nth-child(3) {
                    width: 120%;
                }
                .list-table-item-element:nth-child(4) {
                    width: 56%;
                    span {
                        padding-left: 0;
                        padding-right: 8px;
                        // width: calc(100% - 18px);
                    }
                }
            }
            &.home-best-salers {
                margin-bottom: 0;
                .list-table-item {
                    height: 48px;
                    justify-content: space-between;
                    position: relative;
                    border-bottom: 1px solid #e2e8f0;
                    display: flex;
                    cursor: pointer;
                    &:hover {
                        background: #f4f4f8;
                    }
                }
                .list-table-item-element {
                    font-size: 13px;
                    padding-left: 8px;
                    height: 48px;
                    line-height: 48px;
                    span {
                        width: calc(100% - 10px);
                    }
                    &.green {
                        color: #0dc268;
                        text-align: right;
                    }
                    &.orange {
                        color: #ff9e00;
                        text-align: right;
                    }
                }
                .list-table-item-element:nth-child(1) {
                    width: 208%;
                }
                .list-table-item-element:nth-child(2) {
                    width: 120%;
                }
                .list-table-item-element:nth-child(3) {
                    width: 120%;
                }
                .list-table-item-element:nth-child(4) {
                    width: 56%;
                    span {
                        padding-left: 0;
                        padding-right: 8px;
                        // width: calc(100% - 18px);
                    }
                }
            }
        }
    }
    .statistic-type-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__left-side {
            cursor: pointer;
            align-items: center;
            display: flex;
            * + * {
                margin-left: 10px;
            }
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
            }
            &:hover {
                color: #005bff;
                path {
                    transition: all 200ms linear;
                    fill: #005bff;
                }
            }
        }
        &__right-side {
            display: flex;
            align-items: center;
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                margin-right: 8px;
            }
            p {
                user-select: text;
            }
        }
    }
    .short-cards-wrapper {
        margin-top: 32px;
        display: flex;
        position: relative;
        width: 100%;
        gap: 16px;
        .card-wrapper {
            display: flex;
            width: 100%;
            height: 96px;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
            border-radius: 16px;
            &__title {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                color: #94a0b1;
                line-height: 18px;
            }
            &__value {
                margin-top: 2px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
            }
            &.blue {
                background: #d3e5ff;
            }
            &.pink {
                background: #ffd0e9;
            }
            &.yellow {
                background: #ffe6a6;
            }
            &.purple {
                background: #e9d6ff;
            }
        }
    }
    .meaningful-statistic-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 40px;
        width: 100%;
        .card-wrapper {
            background: #ffffff;
            border-radius: 16px;
            padding: 24px;
            height: 168px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: calc((100% - 32px) / 3);
            position: relative;
            .card-main-info {
                display: flex;
                justify-content: space-between;
                &__value {
                    * + * {
                        margin-left: 12px;
                    }
                    display: flex;
                    b {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 32px;
                    }
                }
            }
            .card-graph {
                display: block;
                width: 113px;
                height: 43px;
                position: absolute;
                top: 31px;
                right: 24px;
            }
            .card-description {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
            }
            &.purple {
                background: #7027c2;
                .card-main-info {
                    b {
                        color: #ffffff;
                    }
                }
                .card-description {
                    color: #ffffff;
                }
            }
        }
    }
    .gadgets-statistic-content {
        width: 100%;
    }
    .content-wrapper {
        width: 100%;
        .table-wrapper {
            margin-top: 34px;
            width: 100%;
            .list-table-item {
                &:last-child {
                    border: none;
                }
            }
            .list-table-header {
                margin-bottom: 9px;
                span {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
            .list-table.video-list {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                width: 100%;
                margin-bottom: 0;
                .list-table-item {
                    height: 48px;
                    .list-table-item-element {
                        line-height: 48px;
                    }
                    // span {
                    // }
                }
            }
        }
    }
    .flex-wrapper {
        display: flex;
        width: 100%;
        gap: 16px;
        .content-wrapper {
            width: 100%;
            span {
                &::after {
                    display: none;
                }
            }
        }
    }
}
