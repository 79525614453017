section.personal-area-item.home {
    background: #f4f4f8;
    .income-info-block.video-depth {
        margin-top: 40px;
    }
    .chart.list-title {
        position: initial;
    }
    .list-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto !important;
        justify-content: space-between;
        margin-bottom: 32px;
        // position: relative;
        display: flex;
        .list-title-selects {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;
            .list-title-date {
                font-weight: 400;
                font-size: 15px;
                line-height: 160%;
                color: #94a0b1;
            }
            .list-title-select {
                position: relative;
                display: block;
                font-weight: 400;
                font-size: 15px;
                line-height: 160%;
                color: #212121;
                .list-title-select-btn {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: 15px;
                    line-height: 24px;
                    white-space: nowrap;
                    font-weight: 400;
                    svg {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                    }
                }
                &:hover {
                    .list-title-select-btn {
                        color: #005bff;
                        svg {
                            path {
                                fill: #005bff;
                            }
                        }
                    }
                }
                &.active {
                    .list-title-select-submenu {
                        display: block;
                    }
                }
                .list-title-select-submenu {
                    position: absolute;
                    display: none;
                    right: 0;
                    top: 32px;
                    padding: 16px 20px;
                    background: #ffffff;
                    box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
                    border-radius: 8px;
                    z-index: 10;
                    .list-title-select-submenu-item {
                        position: relative;
                        display: block;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        white-space: nowrap;
                        cursor: pointer;
                        margin-bottom: 8px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:hover {
                            color: #005bff;
                        }
                    }
                }
            }
        }
    }
    .home-svodka-items {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-bottom: 16px;
        .home-svodka-item {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            background: #ffffff;
            border-radius: 16px;
            height: 168px;
            padding: 24px;
            white-space: nowrap;
            z-index: 1;
            &.home-svodka-item-big {
                height: 192px;
            }

            .home-svodka-item-right {
                position: absolute;
                display: block;
                right: 0;
                width: 50%;
                top: 16px;
                height: calc(100% - 32px);
                border-left: 1px solid #e2e8f0;

                .home-svodka-item-right-circle {
                    position: absolute;
                    border-radius: 96px;
                    width: 96px;
                    height: 96px;
                    overflow: hidden;
                    //background: #3485FF;
                    top: 13px;
                    left: calc(50% - 48px);
                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 80px;
                        height: 80px;
                        border-radius: 40px;
                        left: 8px;
                        top: 8px;
                        background: #fff;
                    }
                    svg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 96px;
                        border-radius: 50%;
                        transform: rotate(-90deg);
                        pointer-events: none;
                    }
                    circle {
                        fill: none;
                        stroke-width: 32;
                        r: 16;
                        cx: 16;
                        cy: 16;
                        pointer-events: all;
                    }

                    circle.first {
                        stroke: #3485ff;
                    }
                    circle.second {
                        stroke: #3ed83b;
                    }

                    circle.third {
                        stroke: #f437df;
                    }
                }

                .home-svodka-item-right-legend {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    gap: 16px;
                    width: 100%;
                    bottom: 8px;
                    text-align: center;
                    .home-svodka-item-chart-item {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        span {
                            position: relative;
                            border-radius: 3px;
                            display: inline-block;
                            vertical-align: middle;
                            top: -2px;
                            height: 6px;
                            margin-right: 7px;
                            width: 6px;
                        }
                    }
                }
            }

            &.color-svodka {
                color: #fff;
                background: #7027c2;
            }
            .home-svodka-item-number {
                position: relative;
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                &:hover {
                    .tooltip {
                        background-color: #fff;
                        right: unset;
                        padding: 12px 16px;
                        left: 20px;
                        white-space: pre-wrap;
                        transform: translate(-50%, -100%);
                        &-text {
                            max-width: 242px;
                            font-size: 13px;
                            line-height: 18px;
                            letter-spacing: 0.1px;
                            text-align: center;
                        }
                        &-arrow {
                            right: unset;
                            left: 50%;
                        }
                    }
                    &:hover {
                        .tooltip {
                            display: block;
                        }
                    }
                }
                &.home-svodka-item-number-title {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 20px;
                    .first-row {
                        display: flex;
                        align-items: center;
                        .bardge-container {
                            height: 21px;
                            margin-left: 10px;
                        }
                        small {
                            margin-left: 6px;
                        }
                    }

                    // span {
                    //     position: relative;
                    //     display: inline-block;
                    //     font-weight: 600;
                    //     font-size: 13px;
                    //     padding: 0px 6px;
                    //     line-height: 21px;
                    //     background: #0dc268;
                    //     border-radius: 80px;
                    //     color: #fff;
                    //     text-align: center;
                    //     vertical-align: middle;
                    //     top: -4px;
                    //     margin-left: 4px;
                    // }
                    .home-svodka-item-text {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        margin-top: -2px;
                    }
                }
            }
            .home-svodka-item-info {
                position: absolute;
                bottom: 24px;
                left: 24px;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .home-tables {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        &.users-statistic {
            display: inline-block;
            width: 100%;
            & > div + div {
                margin-left: 16px;
            }
            .home-table {
                float: left;
            }
        }
        .list-table .list-table-item {
            .list-table-item-element {
                position: relative;
                span {
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .home-table {
            position: relative;
            display: block;
            padding: 24px;
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 8px;
            width: 100%;
            &.half {
                width: calc(50% - 8px);
            }

            .flex-block:last-child {
                .form-field {
                    margin-bottom: 0;
                }
            }
            .form-container-block-title {
                position: relative;
                margin-bottom: 32px;
                font-size: 15px;
                line-height: 24px;
                b {
                    position: relative;
                    display: block;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 16px;
                }
            }
            .list-table-header {
                &.home-best-categories {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 13px;
                    position: relative;
                    height: 21px;
                    // .list-table-item {
                    //     //height: 48px;
                    //     //line-height: 48px;
                    // }
                    .list-table-header-element {
                        position: relative;
                        padding-left: 10px;
                        pointer-events: none;
                        //height: 48px;
                        //line-height: 48px;
                        &:after {
                            display: none;
                        }

                        span {
                            position: absolute;
                            display: inline-block;
                            max-width: calc(100% - 8px);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            &::after {
                                display: none;
                            }
                        }
                    }
                    .list-table-header-element:nth-child(1) {
                        width: calc(100% - 102px - 102px - 104px - 56px);
                    }
                    .list-table-header-element:nth-child(2) {
                        width: 102px;
                    }
                    .list-table-header-element:nth-child(3) {
                        width: 102px;
                    }
                    .list-table-header-element:nth-child(4) {
                        width: 104px;
                    }
                    .list-table-header-element:nth-child(5) {
                        width: 56px;
                    }
                }
                &.home-best-products {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 13px;
                    position: relative;
                    height: 21px;
                    .list-table-header-element {
                        position: relative;
                        padding-left: 8px;
                        pointer-events: none;
                        &:after {
                            display: none;
                        }

                        span {
                            position: absolute;
                            display: inline-block;
                            max-width: calc(100% - 8px);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            &::after {
                                display: none;
                            }
                        }
                    }
                    .list-table-header-element:nth-child(1) {
                        width: calc(100% - 80px - 102px - 102px - 60px);
                        span {
                            padding-left: 44px;
                        }
                    }
                    .list-table-header-element:nth-child(2) {
                        width: 80px;
                    }
                    .list-table-header-element:nth-child(3) {
                        width: 102px;
                    }
                    .list-table-header-element:nth-child(4) {
                        width: 102px;
                    }
                    .list-table-header-element:nth-child(5) {
                        width: 60px;
                    }
                }
                &.home-best-brightboxes {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 13px;
                    position: relative;
                    height: 21px;

                    .list-table-header-element {
                        position: relative;
                        padding-left: 10px;
                        pointer-events: none;
                        //height: 48px;
                        //line-height: 48px;
                        &:after {
                            display: none;
                        }

                        span {
                            position: absolute;
                            display: inline-block;
                            max-width: calc(100% - 8px);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            &::after {
                                display: none;
                            }
                        }
                    }
                    .list-table-header-element:nth-child(1) {
                        width: 208%;
                    }
                    .list-table-header-element:nth-child(2) {
                        width: 120%;
                    }
                    .list-table-header-element:nth-child(3) {
                        width: 120%;
                    }
                    .list-table-header-element:nth-child(4) {
                        width: 56%;
                    }
                }
                &.home-best-salers {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 13px;
                    position: relative;
                    height: 21px;

                    .list-table-header-element {
                        position: relative;
                        padding-left: 10px;
                        pointer-events: none;
                        //height: 48px;
                        //line-height: 48px;
                        &:after {
                            display: none;
                        }

                        span {
                            position: absolute;
                            display: inline-block;
                            max-width: calc(100% - 8px);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            &::after {
                                display: none;
                            }
                        }
                    }
                    .list-table-header-element:nth-child(1) {
                        width: 208%;
                    }
                    .list-table-header-element:nth-child(2) {
                        width: 120%;
                    }
                    .list-table-header-element:nth-child(3) {
                        width: 120%;
                    }
                    .list-table-header-element:nth-child(4) {
                        width: 56%;
                    }
                }
                &.home-best-films {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 13px;
                    position: relative;
                    height: 21px;

                    .list-table-header-element {
                        position: relative;
                        padding-left: 10px;
                        pointer-events: none;
                        //height: 48px;
                        //line-height: 48px;
                        &:after {
                            display: none;
                        }

                        span {
                            position: absolute;
                            display: inline-block;
                            max-width: calc(100% - 8px);
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            &::after {
                                display: none;
                            }
                        }
                    }
                    .list-table-header-element:nth-child(1) {
                        width: 318%;
                    }
                    .list-table-header-element:nth-child(2) {
                        width: 112%;
                    }
                    .list-table-header-element:nth-child(3) {
                        width: 196%;
                    }
                    .list-table-header-element:nth-child(4) {
                        width: 130%;
                    }
                    .list-table-header-element:nth-child(5) {
                        width: 130%;
                    }
                    .list-table-header-element:nth-child(6) {
                        width: 130%;
                    }
                    .list-table-header-element:nth-child(7) {
                        width: 56%;
                    }
                }
            }
            .list-table {
                &.home-best-categories {
                    margin-bottom: 0;
                    .list-table-item {
                        height: 48px;
                        justify-content: space-between;
                        position: relative;
                        border-bottom: 1px solid #e2e8f0;
                        display: flex;
                    }
                    .list-table-item-element {
                        font-size: 13px;
                        padding-left: 8px;
                        height: 48px;
                        line-height: 48px;
                        white-space: nowrap;
                        span {
                            width: calc(100% - 10px);
                        }
                        &.green {
                            color: #0dc268;
                            text-align: right;
                        }
                        &.orange {
                            color: #ff9e00;
                            text-align: right;
                        }
                    }
                    .list-table-item-element:nth-child(1) {
                        width: calc(100% - 102px - 102px - 104px - 56px);
                    }
                    .list-table-item-element:nth-child(2) {
                        width: 102px;
                    }
                    .list-table-item-element:nth-child(3) {
                        width: 102px;
                    }
                    .list-table-item-element:nth-child(4) {
                        width: 104px;
                    }
                    .list-table-item-element:nth-child(5) {
                        width: 56px;
                        span {
                            padding-left: 0;
                            padding-right: 8px;
                            // width: calc(100% - 18px);
                        }
                    }
                }
                &.home-best-products {
                    margin-bottom: 0;
                    .list-table-item {
                        height: 48px;
                        justify-content: space-between;
                        position: relative;
                        border-bottom: 1px solid #e2e8f0;
                        display: flex;
                    }
                    .list-table-item-element {
                        font-size: 13px;
                        padding-left: 8px;
                        cursor: default;
                        height: 48px;
                        line-height: 48px;
                        span {
                            width: calc(100% - 10px);
                        }
                        &.green {
                            color: #0dc268;
                            text-align: right;
                        }
                        &.orange {
                            color: #ff9e00;
                            text-align: right;
                        }
                    }
                    .list-table-item-element:nth-child(1) {
                        width: calc(100% - 80px - 102px - 102px - 60px);
                        img {
                            border-radius: 8px;
                            display: block;
                            height: 32px;
                            object-fit: cover;
                            position: absolute;
                            width: 32px;
                            left: 0;
                            top: calc(50% - 16px);
                        }
                        span {
                            padding-left: 44px;
                        }
                    }
                    .list-table-item-element:nth-child(2) {
                        width: 80px;
                    }
                    .list-table-item-element:nth-child(3) {
                        width: 102px;
                    }
                    .list-table-item-element:nth-child(4) {
                        width: 102px;
                    }
                    .list-table-item-element:nth-child(5) {
                        width: 60px;
                        span {
                            padding-left: 0;
                            padding-right: 8px;
                            // width: calc(100% - 18px);
                        }
                    }
                }
                &.home-best-brightboxes {
                    margin-bottom: 0;
                    .list-table-item {
                        height: 48px;
                        justify-content: space-between;
                        position: relative;
                        border-bottom: 1px solid #e2e8f0;
                        display: flex;
                        cursor: pointer;
                        &:hover {
                            background: #f4f4f8;
                        }
                    }
                    .list-table-item-element {
                        font-size: 13px;
                        padding-left: 8px;
                        height: 48px;
                        line-height: 48px;
                        span {
                            width: calc(100% - 10px);
                        }
                        &.green {
                            color: #0dc268;
                            text-align: right;
                        }
                        &.orange {
                            color: #ff9e00;
                            text-align: right;
                        }
                    }
                    .list-table-item-element:nth-child(1) {
                        width: 208%;
                    }
                    .list-table-item-element:nth-child(2) {
                        width: 120%;
                    }
                    .list-table-item-element:nth-child(3) {
                        width: 120%;
                    }
                    .list-table-item-element:nth-child(4) {
                        width: 56%;
                        span {
                            padding-left: 0;
                            padding-right: 8px;
                            // width: calc(100% - 18px);
                        }
                    }
                }
                &.home-best-salers {
                    margin-bottom: 0;
                    .list-table-item {
                        height: 48px;
                        justify-content: space-between;
                        position: relative;
                        border-bottom: 1px solid #e2e8f0;
                        display: flex;
                        cursor: pointer;
                        &:hover {
                            background: #f4f4f8;
                        }
                    }
                    .list-table-item-element {
                        font-size: 13px;
                        padding-left: 8px;
                        height: 48px;
                        line-height: 48px;
                        span {
                            width: calc(100% - 10px);
                        }
                        &.green {
                            color: #0dc268;
                            text-align: right;
                        }
                        &.orange {
                            color: #ff9e00;
                            text-align: right;
                        }
                    }
                    .list-table-item-element:nth-child(1) {
                        width: 208%;
                    }
                    .list-table-item-element:nth-child(2) {
                        width: 120%;
                    }
                    .list-table-item-element:nth-child(3) {
                        width: 120%;
                    }
                    .list-table-item-element:nth-child(4) {
                        width: 56%;
                        span {
                            padding-left: 0;
                            padding-right: 8px;
                            // width: calc(100% - 18px);
                        }
                    }
                }
                &.home-best-films {
                    margin-bottom: 0;
                    .list-table-item {
                        justify-content: space-between;
                        position: relative;
                        border-bottom: 1px solid #e2e8f0;
                        display: flex;
                        height: 48px;
                        cursor: pointer;
                        &:hover {
                            background: #f4f4f8;
                        }
                    }
                    .list-table-item-element {
                        font-size: 13px;
                        padding-left: 8px;
                        height: 48px;
                        line-height: 48px;

                        span {
                            width: calc(100% - 10px);
                        }
                        &.green {
                            color: #0dc268;
                            text-align: right;
                        }
                        &.orange {
                            color: #ff9e00;
                            text-align: right;
                        }
                    }
                    .list-table-item-element:nth-child(1) {
                        width: 318%;
                    }
                    .list-table-item-element:nth-child(2) {
                        width: 112%;
                    }
                    .list-table-item-element:nth-child(3) {
                        width: 196%;
                    }
                    .list-table-item-element:nth-child(4) {
                        width: 130%;
                    }
                    .list-table-item-element:nth-child(5) {
                        width: 130%;
                    }
                    .list-table-item-element:nth-child(6) {
                        width: 130%;
                    }
                    .list-table-item-element:nth-child(7) {
                        width: 56%;
                        span {
                            padding-left: 0;
                            padding-right: 8px;
                            // width: calc(100% - 18px);
                        }
                    }
                }
            }
            .form-container-progress-blocks {
                .form-container-progress-block.header {
                    margin-bottom: 18px;
                }
                .accordion-wrapper {
                    margin-top: 11px;
                    &:first-child {
                        margin-top: 0;
                    }
                    .accordion-header {
                        padding-right: 36px;
                        position: relative;
                        .header-name {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 13px;
                        }
                        svg {
                            position: absolute;
                            right: 0;
                            width: 24px;
                            height: 24px;
                            top: calc(50% - 12px);
                        }
                    }
                    .accordion-content {
                        padding-right: 36px;
                        .form-container-progress-block {
                            span {
                                height: 21px;
                            }
                            .form-container-progress-block-right {
                                height: 21px;
                            }
                            &:first-child {
                                margin-top: 19px;
                            }
                        }
                        &.open {
                            padding-bottom: 17px;
                            border-bottom: 1px solid #e2e8f0;
                        }
                    }
                }
                .form-container-progress-block {
                    display: flex;
                    font-size: 13px;
                    height: 21px;
                    justify-content: space-between;
                    line-height: 21px;
                    margin-top: 11px;
                    position: relative;
                    width: 100%;

                    b {
                        font-size: 15px;
                        font-weight: 600;
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    .form-container-progress-block-right {
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        width: 60%;

                        span {
                            position: relative;
                            display: block;
                            width: 44px;
                            text-align: right;
                            font-weight: 600;
                            &:last-child {
                                width: 90px;
                            }
                        }

                        .form-container-progress-block-line {
                            position: relative;
                            height: 6px;
                            border-radius: 6px;
                            width: calc(100% - 88px);
                            overflow: hidden;

                            .form-container-progress-block-linefill {
                                position: absolute;
                                display: block;
                                right: 0;
                                top: 0;
                                height: 6px;
                                border-radius: 6px;
                            }
                        }
                    }
                }
                &.pink {
                    .form-container-progress-block-right {
                        span {
                            color: #cc5ef5;
                        }

                        .form-container-progress-block-line {
                            .form-container-progress-block-linefill {
                                background: #cc5ef5;
                            }
                        }
                    }
                }
                &.green {
                    .form-container-progress-block-right {
                        span {
                            color: #0dc268;
                        }

                        .form-container-progress-block-line {
                            .form-container-progress-block-linefill {
                                background: #0dc268;
                            }
                        }
                    }
                }
                &.blue {
                    .form-container-progress-block-right {
                        span {
                            color: #005bff;
                        }

                        .form-container-progress-block-line {
                            .form-container-progress-block-linefill {
                                background: #005bff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.list-title {
    // position: relative;
    display: flex;
    justify-content: space-between;
    height: 56px;
    margin-bottom: 32px;
    .list-title-text {
        max-width: calc(100%) !important;
        sup {
            position: relative;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            line-height: 22px;
            font-weight: normal;
            margin-left: 8px;
            color: #94a0b1;
        }
    }
}

.list-table-item {
    .tooltip {
        background-color: #fff;
        right: unset;
        padding: 12px 16px;
        left: 12%;
        // left: -6%;
        // left: 48
        transform: translate(-50%, -100%);
        &-text {
            max-width: 242px;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.1px;
            text-align: center;
        }
        &-arrow {
            right: unset;
            left: 50%;
        }
    }
    &:hover {
        .tooltip {
            display: block;
        }
    }
}
