section.edit {
    .form-container-blocks {
        .form-container.first-col {
            .form-container-block {
                .flex-block.flex-start {
                    &.no-gap {
                        gap: 0;
                    }
                    .flex-block-column {
                        .flex-block-column-label {
                            display: flex;
                            span {
                                display: block;
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 24px;
                                overflow: hidden;
                                position: relative;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 196px;
                            }
                        }
                        .check-button-item {
                            cursor: pointer;
                            display: block;
                            font-size: 15px;
                            height: 24px;
                            line-height: 24px;
                            position: relative;
                            &::before {
                                background: #f4f4f8;
                                border: 1px solid #d1d8e1;
                                border-radius: 4px;
                                box-sizing: border-box;
                                content: "";
                                display: block;
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: calc(50% - 12px);
                                width: 24px;
                            }
                            &.disabled {
                                cursor: default;
                            }
                            &.selected {
                                &.disabled {
                                    &::before {
                                        background-color: #d1d8e1;
                                        border-color: #d1d8e1;
                                    }
                                }
                                &::before {
                                    background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat
                                        50% 50%;
                                    border-color: #005bff;
                                }
                            }
                        }
                    }
                    .form-field.inline-field {
                        margin-right: 16px;
                        width: auto;
                        .check-button-item {
                            cursor: pointer;
                            display: block;
                            font-size: 15px;
                            height: 24px;
                            line-height: 24px;
                            position: relative;
                            &::before {
                                background: #f4f4f8;
                                border: 1px solid #d1d8e1;
                                border-radius: 4px;
                                box-sizing: border-box;
                                content: "";
                                display: block;
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: calc(50% - 12px);
                                width: 24px;
                            }
                            &.selected {
                                &::before {
                                    background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat
                                        50% 50%;
                                    border-color: #005bff;
                                }
                            }
                            span {
                                display: block;
                                font-weight: 400;
                                overflow: hidden;
                                padding-left: 38px;
                                position: relative;
                                width: auto;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
            .form-divider {
                background: #e2e8f0;
                height: 1px;
                margin: 32px 0;
                position: relative;
            }
        }
        .form-container-right {
            .form-container-upload {
                align-items: center;
                border: 1px dashed #d1d8e1;
                border-radius: 80px;
                box-sizing: border-box;
                cursor: pointer;
                display: flex;
                height: 121px;
                justify-content: center;
                margin: 0 auto;
                position: relative;
                width: 121px;
                overflow: hidden;
                &:hover {
                    path {
                        fill: #005bff;
                    }
                }
                .form-container-block-preview-image {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                input {
                    border-radius: 80px;
                    cursor: pointer;
                    display: block;
                    height: 100%;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }
            .add-photo-button {
                color: #94a0b1;
                cursor: pointer;
                display: block;
                font-size: 15px;
                line-height: 24px;
                margin-top: 26px;
                position: relative;
                text-align: center;
                svg {
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    vertical-align: top;
                }
                &:hover {
                    color: #005bff;
                    path {
                        fill: #005bff;
                    }
                }
                input {
                    cursor: pointer;
                    display: block;
                    height: 100%;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }
        }
    }
}
