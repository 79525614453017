.home-table.blogger-stat {
    position: relative;
    display: block;
    padding: 24px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    &.half {
        width: calc(50% - 8px);
    }

    .flex-block:last-child {
        .form-field {
            margin-bottom: 0;
        }
    }
    .form-container-block-title {
        position: relative;
        margin-bottom: 32px;
        font-size: 15px;
        line-height: 24px;
        b {
            position: relative;
            display: block;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 16px;
        }
    }
    .list-table-header {
        &.home-best-categories {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
            position: relative;
            height: 21px;
            position: relative;
            pointer-events: none;
            background: rgba(0, 0, 0, 0);
            border: none;
            outline: none;
            box-sizing: border-box;
            color: #94a0b1;
            cursor: pointer;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            position: relative;
            position: relative;
            pointer-events: none;
            &:after {
                display: none;
            }
            .list-table-header-element {
                position: relative;
                padding-left: 10px;
                pointer-events: none;
                //height: 48px;
                //line-height: 48px;
                &:after {
                    display: none;
                }

                span {
                    position: absolute;
                    display: inline-block;
                    max-width: calc(100% - 8px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    &::after {
                        display: none;
                    }
                }
            }
            .list-table-header-element:nth-child(1) {
                width: calc(100% - 120px - 120px - 56px);
            }
            .list-table-header-element:nth-child(2) {
                width: 120px;
            }
            .list-table-header-element:nth-child(3) {
                width: 120px;
            }
            .list-table-header-element:nth-child(4) {
                width: 56px;
            }
        }
        &.home-best-products {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
            position: relative;
            height: 21px;
            .list-table-header-element {
                position: relative;
                padding-left: 8px;
                pointer-events: none;
                background: rgba(0, 0, 0, 0);
                border: none;
                outline: none;
                box-sizing: border-box;
                color: #94a0b1;
                cursor: pointer;
                font-size: 14px;
                line-height: 24px;
                padding-left: 20px;
                display: flex;
                position: relative;
                position: relative;
                padding-left: 8px;
                pointer-events: none;
                &:after {
                    display: none;
                }

                span {
                    position: absolute;
                    display: inline-block;
                    max-width: calc(100% - 8px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &::after {
                        display: none;
                    }
                }
            }
            .list-table-header-element:nth-child(1) {
                width: calc(100% - 80px - 102px - 102px - 60px);
                span {
                    padding-left: 44px;
                }
            }
            .list-table-header-element:nth-child(2) {
                width: 80px;
            }
            .list-table-header-element:nth-child(3) {
                width: 102px;
            }
            .list-table-header-element:nth-child(4) {
                width: 102px;
            }
            .list-table-header-element:nth-child(5) {
                width: 60px;
            }
        }
    }

    .list-table {
        position: relative;
        &.home-best-categories {
            margin-bottom: 0;
            .list-table-item {
                height: 48px;
                justify-content: space-between;
                position: relative;
                border-bottom: 1px solid #e2e8f0;
                display: flex;
            }
            .list-table-item-element {
                font-size: 13px;
                padding-left: 8px;
                height: 48px;
                line-height: 48px;
                white-space: nowrap;
                span {
                    width: calc(100% - 10px);
                }
                &.green {
                    color: #0dc268;
                    text-align: right;
                }
                &.orange {
                    color: #ff9e00;
                    text-align: right;
                }
            }
            .list-table-item-element:nth-child(1) {
                width: calc(100% - 120px - 120px - 56px);
            }
            .list-table-item-element:nth-child(2) {
                width: 120px;
            }
            .list-table-item-element:nth-child(3) {
                width: 120px;
            }
            .list-table-item-element:nth-child(4) {
                width: 56px;
                span {
                    padding-left: 0;
                    padding-right: 8px;
                    // width: calc(100% - 18px);
                }
            }
        }
        &.home-best-products {
            margin-bottom: 0;
            position: relative;
            .list-table-item {
                height: 48px;
                justify-content: space-between;
                position: relative;
                border-bottom: 1px solid #e2e8f0;
                display: flex;
                // &:hover {
                //   .tooltip {
                //     display: block;
                //     background-color: #fff;
                //     right: unset;
                //     padding: 12px 16px;
                //     left: 12%;
                //     transform: translate(-50%, -100%);
                //   }
                // }
            }
            .list-table-item-element {
                font-size: 13px;
                padding-left: 8px;
                cursor: default;
                height: 48px;
                line-height: 48px;
                font-size: 13px;
                padding-left: 8px;
                cursor: default;
                height: 48px;
                line-height: 48px;
                position: relative;
                span {
                    width: calc(100% - 10px);
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &.green {
                    color: #0dc268;
                    text-align: right;
                }
                &.orange {
                    color: #ff9e00;
                    text-align: right;
                }
            }
            .list-table-item-element:nth-child(1) {
                width: calc(100% - 80px - 102px - 102px - 60px);
                img {
                    border-radius: 8px;
                    display: block;
                    height: 32px;
                    object-fit: cover;
                    position: absolute;
                    width: 32px;
                    left: 0;
                    top: calc(50% - 16px);
                }
                span {
                    padding-left: 44px;
                }
            }
            .list-table-item-element:nth-child(2) {
                width: 80px;
            }
            .list-table-item-element:nth-child(3) {
                width: 102px;
            }
            .list-table-item-element:nth-child(4) {
                width: 102px;
            }
            .list-table-item-element:nth-child(5) {
                width: 60px;
                span {
                    padding-left: 0;
                    padding-right: 8px;
                    // width: calc(100% - 18px);
                }
            }
        }
    }
}
