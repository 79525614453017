.form-container-block {
    position: relative;
    .form-container-block-seasons.ui-sortable {
        display: block;
        font-size: 0;
        position: relative;
        .form-container-block-season,
        .form-container-block-season2 {
            background: #fff;
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            cursor: pointer;
            margin-bottom: 8px;
            position: relative !important;
            .form-container-block-season-header {
                .form-container-block-season-title {
                    font-size: 15px;
                    font-weight: 600;
                    height: 72px;
                    line-height: 72px;
                    margin-left: 64px;
                    position: relative;
                    span {
                        font-size: 15px;
                        font-weight: 600;
                        height: 72px;
                        span {
                            margin-left: 6px;
                            color: #94a0b1;
                            font-weight: 400;
                            font-size: 13px;
                        }
                    }
                }
                .form-container-block-season-arrow {
                    position: absolute;
                    right: 32px;
                    top: 24px;
                }
                &:hover {
                    .form-container-block-season-arrow {
                        path {
                            fill: #005bff;
                        }
                    }
                    .form-container-block-season-title {
                        color: #005bff;
                    }
                }
            }
            .form-container-block-season-move.ui-sortable-handle {
                display: block;
                left: 24px;
                position: absolute;
                top: 24px;
            }
            .form-container-block-season-container {
                display: none;
            }
            &.open {
                .form-container-block-season-container {
                    display: block;
                    position: relative;
                    .form-container-block-season-episodes.ui-sortable {
                        margin: 0 24px;
                        position: relative;
                        .form-container-block-season-episode,
                        .form-container-block-season-episode2 {
                            background: #fff;
                            border-bottom: 1px solid #e2e8f0;
                            box-sizing: border-box;
                            cursor: default;
                            display: block;
                            height: 48px;
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            .form-container-block-season-episode-title.action-button {
                                cursor: pointer;
                                display: inline-block;
                                font-size: 15px;
                                font-weight: 400;
                                padding-left: 40px;
                                position: relative;
                                display: flex;
                                align-items: center;
                                span {
                                    font-size: 15px;
                                    font-weight: 400;
                                    cursor: pointer;
                                    line-height: 48px;
                                    display: block;
                                    overflow: hidden;
                                    position: absolute;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    width: calc(100% - 40px);
                                }
                                &:hover {
                                    color: #005bff;
                                }
                            }
                            .season-episode-control {
                                display: flex;
                                gap: 8px;
                                justify-content: space-between;
                                position: relative;
                                .season-episode-control-item.action-button {
                                    align-items: center;
                                    cursor: pointer;
                                    display: flex;
                                    height: 40px;
                                    justify-content: center;
                                    position: relative;
                                    width: 40px;
                                    &:hover {
                                        path {
                                            fill: #005bff;
                                        }
                                    }
                                }
                            }
                            .form-container-block-season-episode-move.ui-sortable-handle {
                                cursor: pointer;
                                display: block;
                                height: 24px;
                                left: 0;
                                position: absolute;
                                top: calc(50% - 12px);
                            }
                        }
                    }
                    .season-control {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        margin: 16px 24px 24px;
                        position: relative;
                        .button.add-episode.action-button.border-button.small-button {
                            box-sizing: border-box;
                            font-weight: 600;
                            line-height: 36px;
                            padding-left: 40px;
                            padding-right: 20px;
                            white-space: nowrap;
                            width: 178px;
                            border-radius: 20px;
                            font-size: 14px;
                            height: 40px;
                            background: none;
                            border: 2px solid #d1d8e1;
                            color: #212121;
                            cursor: pointer;
                            position: relative;
                            text-align: center;
                            span {
                                color: #212121;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 36px;
                            }
                            &::after {
                                background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
                                content: "";
                                display: block;
                                height: 16px;
                                left: 16px;
                                position: absolute;
                                top: 10px;
                                width: 16px;
                            }
                            &:hover {
                                border: 2px solid #005bff;
                            }
                        }
                        .season-control-buttons {
                            display: flex;
                            gap: 32px;
                            justify-content: space-between;
                            position: relative;
                            .season-control-button.action-button {
                                color: #94a0b1;
                                display: block;
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 24px;
                                position: relative;
                                &:hover {
                                    color: #005bff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .button.add-season.action-button.border-button.small-button {
        box-sizing: border-box;
        font-weight: 600;
        line-height: 36px;
        padding-left: 40px;
        padding-right: 20px;
        white-space: nowrap;
        width: 178px;
        border-radius: 20px;
        font-size: 14px;
        height: 40px;
        background: none;
        border: 2px solid #d1d8e1;
        color: #212121;
        cursor: pointer;
        position: relative;
        text-align: center;
        span {
            color: #212121;
            font-size: 14px;
            font-weight: 600;
            line-height: 36px;
        }
        &::after {
            background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
            content: "";
            display: block;
            height: 16px;
            left: 16px;
            position: absolute;
            top: 10px;
            width: 16px;
        }
        &:hover {
            border: 2px solid #005bff;
        }
    }
}
.form-container-block-season2 {
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 16px;
    cursor: pointer;
    margin-bottom: 8px;
    .form-container-block-season-header {
        .form-container-block-season-title {
            font-size: 15px;
            font-weight: 600;
            height: 72px;
            line-height: 72px;
            margin-left: 64px;
            position: relative;
            span {
                font-size: 15px;
                font-weight: 600;
                height: 72px;
            }
        }
        .form-container-block-season-arrow {
            position: absolute;
            right: 32px;
            top: 24px;
        }
        &:hover {
            .form-container-block-season-arrow {
                path {
                    fill: #005bff;
                }
            }
            .form-container-block-season-title {
                color: #005bff;
            }
        }
    }
    .form-container-block-season-move.ui-sortable-handle {
        display: block;
        left: 24px;
        position: absolute;
        top: 24px;
    }
    .form-container-block-season-container {
        display: none;
    }
    &.open {
        .form-container-block-season-container {
            display: block;
            position: relative;
            .form-container-block-season-episodes.ui-sortable {
                margin: 0 24px;
                position: relative;
                .form-container-block-season-episode,
                .form-container-block-season-episode2 {
                    background: #fff;
                    border-bottom: 1px solid #e2e8f0;
                    box-sizing: border-box;
                    cursor: default;
                    display: block;
                    height: 48px;
                    line-height: 48px;
                    position: relative;
                    .form-container-block-season-episode-title.action-button {
                        cursor: pointer;
                        display: inline-block;
                        font-size: 15px;
                        font-weight: 400;
                        padding-left: 40px;
                        position: relative;
                        span {
                            font-size: 15px;
                            font-weight: 400;
                            cursor: pointer;
                            line-height: 48px;
                        }
                        &:hover {
                            color: #005bff;
                        }
                    }
                    .season-episode-control {
                        display: flex;
                        gap: 8px;
                        justify-content: space-between;
                        position: absolute;
                        right: 0;
                        top: calc(50% - 20px);
                        .season-episode-control-item.action-button {
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            height: 40px;
                            justify-content: center;
                            position: relative;
                            width: 40px;
                            &:hover {
                                path {
                                    fill: #005bff;
                                }
                            }
                        }
                    }
                    .form-container-block-season-episode-move.ui-sortable-handle {
                        cursor: pointer;
                        display: block;
                        height: 24px;
                        left: 0;
                        position: absolute;
                        top: calc(50% - 12px);
                    }
                }
            }
            .season-control {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 16px 24px 24px;
                position: relative;
                .button.add-episode.action-button.border-button.small-button {
                    box-sizing: border-box;
                    font-weight: 600;
                    line-height: 36px;
                    padding-left: 40px;
                    padding-right: 20px;
                    white-space: nowrap;
                    width: 178px;
                    border-radius: 20px;
                    font-size: 14px;
                    height: 40px;
                    background: none;
                    border: 2px solid #d1d8e1;
                    color: #212121;
                    cursor: pointer;
                    position: relative;
                    text-align: center;
                    span {
                        color: #212121;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 36px;
                    }
                    &::after {
                        background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
                        content: "";
                        display: block;
                        height: 16px;
                        left: 16px;
                        position: absolute;
                        top: 10px;
                        width: 16px;
                    }
                    &:hover {
                        border: 2px solid #005bff;
                    }
                }
                .season-control-buttons {
                    display: flex;
                    gap: 32px;
                    justify-content: space-between;
                    position: relative;
                    .season-control-button.action-button {
                        color: #94a0b1;
                        display: block;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 24px;
                        position: relative;
                        &:hover {
                            color: #005bff;
                        }
                    }
                }
            }
        }
    }
}

.form-container-block-season-episode2 {
    background: #fff;
    border-bottom: 1px solid #e2e8f0;
    box-sizing: border-box;
    cursor: default;
    display: block;
    height: 48px;
    line-height: 48px;
    position: relative;
    width: 100%;
    .form-container-block-season-episode-title.action-button {
        cursor: pointer;
        display: inline-block;
        font-size: 15px;
        font-weight: 400;
        padding-left: 40px;
        position: relative;
        span {
            font-size: 15px;
            font-weight: 400;
            cursor: pointer;
            line-height: 48px;
        }
        &:hover {
            color: #005bff;
        }
    }
    .season-episode-control {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        position: absolute;
        right: 0;
        top: calc(50% - 20px);
        .season-episode-control-item.action-button {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 40px;
            justify-content: center;
            position: relative;
            width: 40px;
            &:hover {
                path {
                    fill: #005bff;
                }
            }
        }
    }
    .form-container-block-season-episode-move.ui-sortable-handle {
        cursor: pointer;
        display: block;
        height: 24px;
        left: 0;
        position: absolute;
        top: calc(50% - 12px);
    }
}

.form-container-block-playlist {
    background: #fff;
    border-bottom: 1px solid #e2e8f0;
    box-sizing: border-box;
    cursor: default;
    display: flex;
    height: 48px;
    line-height: 48px;
    position: relative;
    justify-content: space-between;
    & > * {
        &:nth-child(1) {
            width: 544% !important;
        }
        &:nth-child(2) {
            width: 139% !important;
        }
        &:nth-child(3) {
            width: 325% !important;
        }
        &:nth-child(4) {
            width: 40% !important;
        }
    }
    .form-container-block-season-episode-title.action-button {
        cursor: pointer;
        display: flex;
        font-size: 15px;
        font-weight: 400;
        padding-left: 40px;
        position: relative;
        span {
            font-size: 15px;
            font-weight: 400;
            cursor: pointer;
            line-height: 48px;
        }
        &:hover {
            color: #005bff;
        }
    }
    .season-episode-control {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        top: calc(50% - 20px);
        .season-episode-control-item.action-button {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 40px;
            justify-content: center;
            position: relative;
            width: 40px;
            &:hover {
                path {
                    fill: #005bff;
                }
            }
        }
    }
    .form-container-block-season-episode-move.ui-sortable-handle {
        cursor: pointer;
        display: block;
        height: 24px;
        left: 0;
        position: absolute;
        top: calc(50% - 12px);
    }
}
