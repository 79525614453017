.statistics-range-visible {
    .calendar-icon {
        background: url("/public/images/icons/ui/forms/calendar.svg") no-repeat calc(100% - 16px) 15px;
        display: block;
        height: 54px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
    }
    .statistics-range-calendar-clear {
        cursor: pointer;
        display: none;
        line-height: 0;
        position: absolute;
        right: 45px;
        top: 16px;
        z-index: 1001;
        &.active {
            display: block;
        }
    }
    input {
        background: #f4f4f8;
        border: 1px solid #f4f4f8;
        border-radius: 8px;
        box-sizing: border-box;
        display: block;
        font-size: 15px;
        font-family: "Averta CY";
        height: 54px;
        outline: none;
        padding: 0 69px 0 20px;
        position: relative;
        width: 100%;
        &::placeholder {
            font-weight: 400;
            font-size: 15px;
            color: #212121;
        }
    }
}
.input-field.date-range-field {
    position: relative;
    display: block;
    z-index: 2;
    .date-picker-wrapper {
        display: none;
        z-index: 2;
    }
    .backdrop {
        display: none;
        z-index: 1;
    }
}

.personal-area-item.statistic {
    button.form-page-back.statistic-button {
        cursor: pointer;
        color: #94a0b1;
        display: block;
        width: 150px;
        font-size: 14px;
        line-height: 24px;
        margin-top: 8px;
        position: relative;
        margin-bottom: 24px;
        text-decoration: none;
        background: transparent;
        border: none;
        outline: none;
        font-family: "Averta CY";
        &:hover {
            color: #005bff;
            path {
                fill: #005bff;
            }
        }
        svg {
            display: inline-block;
            margin-right: 8px;
            position: relative;
            vertical-align: top;
        }
    }
    .personal-area.categories-header.statistic-header {
        h1.current-statistic {
            font-weight: 600;
            font-size: 32px;
        }
    }
    .statistics-bookmarks {
        border-bottom: 1px solid #e2e8f0;
        display: flex;
        gap: 32px;
        margin-bottom: 29px;
        margin-top: 23px;
        position: relative;
        button.statistics-bookmark {
            border: none;
            border-bottom: 2px solid transparent;
            outline: none;
            background: transparent;
            align-items: center;
            box-sizing: border-box;
            color: #212121;
            font-family: "Averta CY";
            display: block;
            font-size: 15px;
            font-weight: 400;
            line-height: 160%;
            padding-bottom: 18px;
            position: relative;
            cursor: pointer;
            &:hover {
                border-bottom: 2px solid #005bff;
            }
            &.active {
                border-bottom: 2px solid #005bff;
            }
        }
    }
    #fixed-statistics-itog {
        position: fixed;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 240px);
        bottom: 0;
        right: 0;
        height: 32px;
        z-index: 1;
        background: #f4f4f8;
        padding: 0 40px 0 48px;
        box-sizing: border-box;
        .fixed-staistics-data {
            position: relative;
            display: flex;
            justify-content: space-between;
            gap: 24px;
            font-size: 13px;
            line-height: 32px;
        }
    }
}
.input-field.date-range-field {
    .statistics-range-visible.active {
        position: relative;
        cursor: pointer;
        .backdrop {
            display: block;
        }
        .date-picker-wrapper {
            position: absolute;
            right: 0 !important;
            width: 328px;
            z-index: 1000 !important;
            height: auto;
            left: auto !important;
            background: #fff;
            border-radius: 16px;
            box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
            padding-bottom: 12px;
            display: block;
            .react-datepicker {
                position: relative;
                .react-datepicker__aria-live{
                    display: none;
                }
                .react-datepicker__navigation--previous {
                    cursor: pointer;
                    align-items: center;
                    display: flex;
                    font-size: 0;
                    height: 44px;
                    justify-content: center;
                    left: 6px;
                    line-height: 24px;
                    position: absolute;
                    top: 14px;
                    width: 44px;
                    z-index: 2;
                    border: none;
                    background: transparent;
                    &::after {
                        background: url("/public/images/icons/ui/navigation/drop_previous.svg") no-repeat 50% 50%;
                        content: "";
                        cursor: pointer;
                        display: block;
                        font-size: 15px;
                        height: 24px;
                        line-height: 24px;
                        position: relative;
                        top: 0;
                        width: 24px;
                    }
                }
                .react-datepicker__navigation--next {
                    cursor: pointer;
                    align-items: center;
                    display: flex;
                    font-size: 0;
                    height: 44px;
                    justify-content: center;
                    line-height: 24px;
                    position: absolute;
                    right: 6px;
                    top: 14px;
                    width: 44px;
                    z-index: 2;
                    border: none;
                    background: transparent;
                    &::after {
                        background: url("/public/images/icons/ui/navigation/drop_next.svg") no-repeat 50% 50%;
                        content: "";
                        cursor: pointer;
                        display: block;
                        font-size: 15px;
                        height: 24px;
                        line-height: 24px;
                        position: relative;
                        top: 0;
                        width: 24px;
                    }
                }
                .react-datepicker__month-container {
                    border-spacing: 1px;
                    padding: 72px 15px 0;
                    width: 100%;
                    display: table;
                    border-collapse: separate;
                    box-sizing: border-box;
                    text-indent: initial;
                    border-color: grey;
                    &::after {
                        background: #f4f4f8;
                        content: "";
                        display: block;
                        height: 1px;
                        left: 0;
                        position: absolute;
                        top: 105px;
                        width: 100%;
                    }
                    .react-datepicker__header {
                        display: table-header-group;
                        vertical-align: middle;
                        border-color: inherit;

                        .react-datepicker__current-month {
                            font-size: 15px;
                            left: 30px;
                            line-height: 24px;
                            position: absolute;
                            text-align: center;
                            top: 24px;
                            width: calc(100% - 60px);
                            z-index: 1;
                        }
                        .react-datepicker__day-names {
                            display: table-row;
                            vertical-align: inherit;
                            border-color: inherit;
                            .react-datepicker__day-name {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 32px;
                                padding-bottom: 8px;
                                display: table-cell;
                                vertical-align: inherit;
                                text-align: center;
                                vertical-align: inherit;
                            }
                        }
                    }
                    .react-datepicker__month {
                        display: table-row-group;
                        vertical-align: middle;
                        border-color: inherit;
                        .react-datepicker__week {
                            display: table-row;
                            vertical-align: inherit;
                            border-color: inherit;
                            .react-datepicker__day {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 32px;
                                display: table-cell;
                                vertical-align: inherit;
                                text-align: center;
                                border: 1px solid #fff;
                                border-radius: 4px;
                                box-sizing: border-box;
                                color: #212121;
                                text-align: center;
                                text-decoration: none;

                                &:hover {
                                    border: #005bff solid 1px;
                                }
                                &.react-datepicker__day--outside-month {
                                    opacity: 0.3;
                                }
                                &.react-datepicker__day--selecting-range-start,
                                &.react-datepicker__day--keyboard-selected {
                                    background: #005bff;
                                    border: 1px solid #005bff;
                                    color: #fff;
                                    &:hover {
                                        background: #005bff;
                                        border: 1px solid #005bff;
                                        color: #fff;
                                    }
                                }

                                &.react-datepicker__day--in-range {
                                    background: #abcdff;
                                    border: 1px solid #abcdff;
                                    color: #005bff;
                                }
                                &.react-datepicker__day--range-end {
                                    background: #005bff;
                                    border: 1px solid #005bff;
                                    color: #fff;
                                    &:hover {
                                        background: #005bff;
                                        border: 1px solid #005bff;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes dropdown {
    0% {
        display: block;
        overflow: hidden;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        height: 0;
    }
    100% {
        display: block;
        height: 53px;
    }
}

#statistics-filter-buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
    .statistics-all-filter-button,
    .statistics-column-button {
        position: relative;
        display: flex;
        align-items: center;
        height: 24px;
        justify-content: space-between;
        gap: 8px;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        cursor: pointer;
        &:hover {
            color: #005bff;
            svg {
                path {
                    fill: #005bff;
                }
            }
        }
    }
}
section .content-wrapper {
    .list-table-header.stat-list {
        display: flex;
        height: 21px;
        justify-content: space-between;
        margin-bottom: 13px;
        position: relative;
        .list-table-header-element {
            position: relative;
            padding-left: 10px;
            &::after {
                display: none;
            }
        }

        .list-table-header-element {
            position: relative;
            padding-left: 8px;
            justify-content: flex-start;
            &:after {
                display: none;
            }
            &.hidden {
                display: none !important;
            }
            span {
                position: absolute;
                display: inline-block;
                max-width: calc(100% - 8px);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                &:after {
                    position: relative;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    content: "";
                    background: url("/public/images/icons/ui/actions/gray/sort_filled.svg") no-repeat;
                    vertical-align: top;
                }
            }
        }
        .list-table-header-element:nth-child(1) {
            width: 104%;
        }
        .list-table-header-element:nth-child(2) {
            width: 81%;
        }
        .list-table-header-element:nth-child(3) {
            width: 81%;
        }
        .list-table-header-element:nth-child(4) {
            width: 100%;
        }
        .list-table-header-element:nth-child(5) {
            width: 116%;
        }
        .list-table-header-element:nth-child(6) {
            width: 130%;
        }
        .list-table-header-element:nth-child(7) {
            width: 127%;
        }
        .list-table-header-element:nth-child(8) {
            width: 200%;
        }
        .list-table-header-element:nth-child(9) {
            width: 108%;
        }
        .list-table-header-element:nth-child(10) {
            width: 100%;
        }
        .list-table-header-element:nth-child(11) {
            width: 100%;
        }
        .list-table-header-element:nth-child(12) {
            width: 100%;
        }
        .list-table-header-element:nth-child(13) {
            width: 100%;
        }
        .list-table-header-element:nth-child(14) {
            width: 85%;
        }
        .list-table-header-element:nth-child(15) {
            width: 125%;
        }
    }

    .list-table.stat-list {
        margin-bottom: 64px;
        .list-table-item.action-button {
            border-bottom: 1px solid #e2e8f0;
            display: flex;
            justify-content: space-between;
            position: relative;
            &:hover {
                background: #f4f4f8;
            }
            .list-table-item-element {
                font-size: 13px;
                padding-left: 8px;
                cursor: pointer;
                height: 48px;
                box-sizing: border-box;
                display: block;
                line-height: 48px;
                position: relative;
                span {
                    width: calc(100% - 10px);
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &.hidden {
                    display: none !important;
                }
            }
            .list-table-item-element:nth-child(1) {
                width: 104%;
            }
            .list-table-item-element:nth-child(2) {
                width: 81%;
            }
            .list-table-item-element:nth-child(3) {
                width: 100%;
            }
            .list-table-item-element:nth-child(4) {
                width: 116%;
            }
            .list-table-item-element:nth-child(5) {
                width: 130%;
            }
            .list-table-item-element:nth-child(6) {
                width: 127%;
            }
            .list-table-item-element:nth-child(7) {
                width: 200%;
            }
            .list-table-item-element:nth-child(8) {
                width: 108%;
            }
            .list-table-item-element:nth-child(9) {
                width: 100%;
            }
            .list-table-item-element:nth-child(10) {
                width: 100%;
            }
            .list-table-item-element:nth-child(11) {
                width: 100%;
            }
            .list-table-item-element:nth-child(12) {
                width: 100%;
            }
            .list-table-item-element:nth-child(13) {
                width: 85%;
            }
            .list-table-item-element:nth-child(14) {
                width: 123%;
            }
        }
    }
}
.statistics-filter-bg {
    background: rgba(0, 0, 0, 0.4);
    display: block;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all 0.3s linear;
    visibility: hidden;
    width: 100vw;
    z-index: 999;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

#statistics-filter-column,
#statistics-filter {
    background: #fff;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    right: -400px;
    width: 370px;
    z-index: 1001;
    top: 0;
    transition: all 0.3s ease-out;
    &.active {
        right: 0;
    }
    .form-field {
        .form-field-label {
            color: #94a0b1;
            display: block;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 5px;
            margin-top: -3px;
            position: relative;
        }
        .input-field {
            input {
                background: #f4f4f8;
                border: 1px solid #f4f4f8;
                border-radius: 8px;
                box-sizing: border-box;
                color: #212121;
                display: block;
                font-family: Averta CY;
                font-size: 15px;
                height: 54px;
                outline: none;
                padding: 0 20px;
                position: relative;
                width: 100%;
            }
        }
    }
    .statistics-filter-list-bar {
        position: absolute;
        display: block;
        width: 4px;
        pointer-events: none;
        top: 16px;
        right: 8px;
        height: calc(100% - 32px);
        border-radius: 2px;
        overflow: hidden;
        .constructor-filter-list-thumb {
            position: absolute;
            height: 30%;
            width: 100%;
            left: 0;
            top: 0;
            background: #94a0b1;
            opacity: 0.3;
            border-radius: 2px;
        }
    }

    .statistics-filter-close {
        position: absolute;
        display: block;
        right: 32px;
        top: 28px;
        cursor: pointer;
        &:hover {
            path {
                fill: #005bff;
            }
        }
    }
    .statistics-filter-scroller {
        display: block;
        height: calc(100vh - 83px);
        overflow-y: scroll;
        padding: 0 32px 0 24px;
        position: absolute;
        top: 5px;
        width: (370px - 4px);
        box-sizing: border-box;
        overflow-x: hidden;
        scrollbar-arrow-color: transparent;
        scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
        small {
            position: relative;
            display: block;
            color: #94a0b1;
            font-weight: 400;
            font-size: 15px;
            margin-bottom: 24px;
        }
        &::-webkit-scrollbar {
            width: 10px;
            position: absolute;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
            margin: 16px 0;
        }
        &::-webkit-scrollbar-thumb {
            position: relative;
            background: rgba($color: #94a0b1, $alpha: 0.3);
            border-radius: 3px;
        }
    }
    .statistics-filter-title {
        position: relative;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 40px;
        margin-top: 19px;
    }

    .statistics-filter-items {
        margin-bottom: 95px;

        .statistics-filter-items-block {
            border-bottom: 1px solid #e2e8f0;
            position: relative;
            .flex-block.flex-start {
                position: relative;
                display: flex;
                gap: 16px;
                justify-content: flex-start;
                margin-bottom: 0;
                z-index: 3;
                .flex-block-column {
                    .check-button-item {
                        cursor: pointer;
                        display: block;
                        font-size: 15px;
                        height: 24px;
                        line-height: 24px;
                        position: relative;
                        span {
                            display: block;
                            font-weight: 400;
                            overflow: hidden;
                            padding-left: 38px;
                            position: absolute;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            // width: calc(100% - 100px);
                        }
                        &::before {
                            border: 1px solid #d1d8e1;
                            border-radius: 4px;
                            box-sizing: border-box;
                            content: "";
                            display: block;
                            height: 24px;
                            left: 0;
                            position: absolute;
                            top: calc(50% - 12px);
                            width: 24px;
                        }
                        &.selected {
                            &::before {
                                background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50%
                                    50%;
                                border-color: #005bff;
                            }
                        }
                        &.disabled {
                            pointer-events: none;
                            &::before {
                                background: #d1d8e1;
                                border: 1px solid #d1d8e1;
                            }
                        }
                    }
                }
            }
            .statistics-filter-items-block-subitems {
                position: relative;
                display: none;
                padding-top: 10px;
                padding-bottom: 24px;
            }
            .statistics-filter-items-block-title {
                position: relative;
                line-height: 56px;
                font-size: 15px;
                font-weight: 600;
                cursor: pointer;
                svg {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 12px);
                }
                &:hover {
                    color: #005bff;
                    path {
                        fill: #005bff;
                    }
                }
            }
            .constructor-filter-items-block {
                display: block;
                margin-bottom: 16px;
                position: relative;
                z-index: 2;
                .constructor-filter-item-title {
                    cursor: pointer;
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 24px;
                    position: relative;
                    text-transform: uppercase;
                    span {
                        width: 270px;
                        display: block;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    svg {
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
                .constructor-filter-subitems {
                    display: none;
                    margin-top: 16px;
                    position: relative;
                }
                &.open {
                    .constructor-filter-subitems {
                        z-index: 1;
                        display: block;
                        .constructor-filter-subitem {
                            position: relative;
                            border-bottom: 1px solid #e2e8f0;
                            &:first-child {
                                border-top: 1px solid #e2e8f0;
                            }
                            &.open {
                                .constructor-filter-subitem-title {
                                    svg {
                                        transform: rotate(180deg);
                                    }
                                }
                                .constructor-filter-subitem-items {
                                    display: block;
                                }
                            }
                            .constructor-filter-subitem-title {
                                position: relative;
                                cursor: pointer;
                                padding: 16px 0;
                                font-weight: 600;
                                span {
                                    width: 270px;
                                    display: block;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                                svg {
                                    position: absolute;
                                    display: block;
                                    right: 0;
                                    top: calc(50% - 12px);
                                }
                                &:hover {
                                    color: #005bff;
                                    path {
                                        fill: #005bff;
                                    }
                                }
                                small {
                                    font-weight: 300;
                                    font-size: 13px;
                                    color: #94a0b1;
                                    margin-left: 3px;
                                }
                            }
                            .constructor-filter-subitem-items {
                                position: relative;
                                padding-bottom: 24px;
                                display: none;
                                .check-button-item {
                                    span {
                                        font-weight: 300;
                                        width: 270px;
                                        display: block;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }
                    .constructor-filter-item-title {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &.open {
                .statistics-filter-items-block-title {
                    svg {
                        transform: rotate(180deg);
                    }
                }
                .statistics-filter-items-block-subitems {
                    display: block;
                }
            }
        }
    }
    .statistics-buttons {
        z-index: 3;
        background: #fff;
        border-top: 1px solid #e2e8f0;
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        gap: 8px;
        justify-content: space-between;
        margin-left: 0;
        padding: 12px 24px;
        position: absolute;
        width: 100%;
        .button {
            background: #005bff;
            border-radius: 24px;
            box-sizing: border-box;
            color: #fff;
            cursor: pointer;
            display: block;
            font-size: 15px;
            height: 48px;
            line-height: 48px;
            padding: 0 32px;
            position: relative;
            text-align: center;
            font-weight: 600;
            font-size: 15px;
        }
        .button:nth-child(1) {
            width: 135px;
            background: none;
            border: 2px solid #d1d8e1;
            color: #212121;
            line-height: 44px;
            &:hover {
                border: 2px solid #005bff;
            }
        }
        .button:nth-child(2) {
            width: 152px;
            &:hover {
                background: #004ace;
            }
        }
    }
}
