body {
    color: #212121;
    font-family: "Averta CY";
}

@keyframes loaderAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main-modal {
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    height: 100vh;
    justify-content: center;
    left: 0;
    // overflow-y: scroll;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9900;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    .modal-content {
        display: block;
        padding: 50px 0;
        position: relative;
        .add-category-wrapper {
            display: block;
            background: #fff;
            border-radius: 16px;
            box-sizing: border-box;
            padding: 32px;
            position: relative;
            // width: 504px;
            .modal-field-title {
                font-size: 24px;
                font-weight: 600;
                line-height: 40px;
                margin-bottom: 32px;
                padding-right: 72px;
                position: relative;
            }
            .modal-field-close {
                cursor: pointer;
                display: block;
                position: absolute;
                right: 40px;
                top: 40px;
            }
            .flex-block {
                display: flex;
                gap: 16px;
                justify-content: space-between;
                margin-bottom: 24px;
                position: relative;
                .form-field {
                    display: block;
                    position: relative;
                    width: 100%;
                    .form-field-label {
                        color: #94a0b1;
                        display: block;
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 5px;
                        margin-top: -3px;
                        position: relative;
                    }
                    .input-field {
                        display: block;
                        position: relative;
                        input {
                            background: #f4f4f8;
                            border: 1px solid #f4f4f8;
                            border-radius: 8px;
                            box-sizing: border-box;
                            color: #212121;
                            display: block;
                            font-family: Averta CY;
                            font-size: 15px;
                            height: 54px;
                            font-weight: 400;
                            outline: none;
                            padding: 0 20px;
                            position: relative;
                            width: 100%;
                            &::placeholder {
                                color: #94a0b1;
                            }
                        }
                        textarea {
                            resize: none;
                            background: #f4f4f8;
                            border: 1px solid #f4f4f8;
                            border-radius: 8px;
                            box-sizing: border-box;
                            color: #212121;
                            display: block;
                            font-family: Averta CY;
                            font-size: 15px;
                            font-weight: 400;
                            outline: none;
                            padding: 10px 20px;
                            line-height: 150%;
                            position: relative;
                            width: 100%;
                            &::placeholder {
                                color: #94a0b1;
                            }
                        }
                    }
                }
                .flex-block-column {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    margin-top: 8px;
                    position: relative;
                    width: 100%;
                    .form-field-label {
                        color: #94a0b1;
                        display: block;
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 0;
                        margin-top: -3px;
                        position: relative;
                        span {
                            color: #212121;
                            display: block;
                            font-size: 15px;
                            font-weight: 600;
                            position: relative;
                        }
                    }
                    .radio-button-item {
                        cursor: pointer;
                        display: block;
                        font-size: 15px;
                        height: 24px;
                        line-height: 24px;
                        position: relative;
                        span {
                            display: block;
                            font-weight: 400;
                            overflow: hidden;
                            padding-left: 38px;
                            position: absolute;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .radio-button-item::before {
                        background: #f4f4f8;
                        border: 1px solid #d1d8e1;
                        border-radius: 12px;
                        box-sizing: border-box;
                        content: "";
                        display: block;
                        height: 24px;
                        left: 0;
                        position: absolute;
                        top: calc(50% - 12px);
                        width: 24px;
                    }
                    .radio-button-item.selected::before {
                        background: #005bff;
                        border-color: #005bff;
                        border: 1px solid #d1d8e1;
                        border-radius: 12px;
                        box-sizing: border-box;
                        content: "";
                        display: block;
                        height: 24px;
                        left: 0;
                        position: absolute;
                        top: calc(50% - 12px);
                        width: 24px;
                    }
                    .radio-button-item.selected::after {
                        background: #fff;
                        border-radius: 4px;
                        box-sizing: border-box;
                        content: "";
                        display: block;
                        height: 8px;
                        left: 8px;
                        position: absolute;
                        top: calc(50% - 4px);
                        width: 8px;
                    }
                    .check-button-item {
                        cursor: pointer;
                        display: block;
                        font-size: 15px;
                        height: 24px;
                        line-height: 24px;
                        position: relative;
                        span {
                            display: block;
                            font-weight: 400;
                            overflow: hidden;
                            padding-left: 38px;
                            position: absolute;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: calc(100% - 100px);
                        }
                    }
                    .check-button-item::before {
                        background: #f4f4f8;
                        border: 1px solid #d1d8e1;
                        border-radius: 4px;
                        box-sizing: border-box;
                        content: "";
                        display: block;
                        height: 24px;
                        left: 0;
                        position: absolute;
                        top: calc(50% - 12px);
                        width: 24px;
                    }
                    .check-button-item.selected::before {
                        background: #005bff url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
                        border-color: #005bff;
                    }
                }
            }
            .modal-buttons {
                height: auto;
                margin-top: 32px;
                display: flex;
                gap: 8px;
                justify-content: space-between;
                position: relative;
                &.delete {
                    gap: 83px;
                    button.action-button.border-button {
                        line-height: 44px;
                    }
                    .button.action-button {
                        height: 48px;
                        line-height: 44px;
                    }
                }
                .button.action-button.border-button {
                    position: relative;
                    background: transparent;
                    border: 2px solid #d1d8e1;
                    color: #212121;
                    padding: 0 32px;
                    text-align: center;
                    cursor: pointer;
                    display: block;
                    border-radius: 80px;
                    box-sizing: border-box;
                    line-height: 52px;
                    span {
                        position: relative;
                        font-size: 15px;
                        color: #212121;
                        line-height: 44px;
                    }
                    &:hover {
                        border: 2px solid #005bff;
                        background: transparent;
                    }
                }
                .button.action-button {
                    border: none;
                    outline: none;
                    background: #005bff;
                    border-radius: 80px;
                    box-sizing: border-box;
                    color: #fff;
                    cursor: pointer;
                    display: block;
                    font-size: 15px;
                    height: 56px;
                    padding: 0 32px;
                    line-height: 56px;
                    position: relative;
                    font-weight: 600;
                    text-align: center;
                    &:hover {
                        background: #004ace;
                    }
                }
                .button.action-button.preloader {
                    pointer-events: none;
                    font-size: 0;
                    color: #fff;
                    width: 122px;
                    &::after {
                        animation: loaderAnimation 1s linear infinite;
                        background: url("/public/images/icons/ui/status/white/loader.svg") no-repeat;
                        background-size: contain;
                        content: "";
                        display: block;
                        height: 20px;
                        left: calc(50% - 10px);
                        position: absolute;
                        top: calc(50% - 10px);
                        width: 20px;
                    }
                }
            }
        }
    }
}
.button.action-button.preloader {
    pointer-events: none;
    font-size: 0 !important;
    width: 207px;
    color: #fff;
    &::after {
        animation: loaderAnimation 1s linear infinite;
        background: url("/public/images/icons/ui/status/white/loader.svg") no-repeat;
        background-size: contain;
        content: "";
        display: block;
        height: 20px;
        left: calc(50% - 10px);
        position: absolute;
        top: calc(50% - 10px);
        width: 20px;
    }
}
