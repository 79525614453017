.show-more-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    &.white {
        button.button_show-more {
            background: white;
        }

    }
    button.button_show-more {
        padding: 16px 32px;
        display: block;
        border: 2px solid #d1d8e1;
        border-radius: 80px;
        outline: none;
        background: transparent;
        color: #212121;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        cursor: pointer;
        font-family: "Averta CY";
        &:hover {
            border-color: #005bff;
        }
    }
}
