section.personal-area-item.prices {
    background: #f4f4f8;
}
.prices-content-wrapper.content-wrapper {
    #prices-shops {
        display: flex;
        gap: 16px;
        justify-content: flex-start;
        margin-bottom: 24px;
        margin-top: 41px;
        position: relative;
        flex-wrap: wrap;
        .prices-shop {
            align-items: center;
            background: #fff;
            border: 1px solid #fff;
            border-radius: 16px;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            font-size: 24px;
            font-weight: 600;
            height: 104px;
            justify-content: center;
            position: relative;
            width: 268px;
            &:hover {
                border: 1px solid #005bff;
            }
            &.active {
                background: #005bff;
                border: 1px solid #005bff;
                color: #fff;
            }
        }
    }
    #base-price {
        position: relative;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        border-radius: 8px;
        height: 184px;
        margin-bottom: 56px;
        box-sizing: border-box;
        small {
            position: relative;
            display: block;
            font-weight: 400;
            font-size: 13px;
            line-height: 21px;
            color: #94a0b1;
            margin-bottom: 4px;
        }
        b {
            position: relative;
            display: block;
            font-weight: 600;
            font-size: 48px;
            line-height: 55px;
            margin-bottom: 18px;
            height: 55px;
        }
        .base-price-part {
            position: relative;
            margin: 32px;
            &:last-child {
                text-align: right;
            }
            .base-price-part-title {
                position: relative;
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
            }
            .base-price-history {
                position: absolute;
                display: block;
                left: 0;
                bottom: 0;
                margin-right: 8px;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #94a0b1;
                white-space: nowrap;
                cursor: pointer;
                svg {
                    margin-right: 15px;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                }
                &:hover {
                    color: #005bff;
                    svg {
                        path {
                            fill: #005bff;
                        }
                    }
                }
            }
        }
    }
    .list-title {
        display: flex;
        height: 56px;
        justify-content: space-between;
        margin-bottom: 32px;
        position: relative;
        .list-title-text {
            max-width: calc(100% - 200px);
            h2 {
                font-size: 32px;
                line-height: 37px;
                font-weight: 600;
            }
        }
        .button.with-icon.action-button {
            background: #005bff;
            border-radius: 24px;
            box-sizing: border-box;
            color: #fff;
            cursor: pointer;
            display: block;
            font-size: 15px;
            height: 48px;
            line-height: 48px;
            padding: 0 32px;
            position: relative;
            text-align: center;
            img {
                display: inline-block;
                margin-right: 6px;
                position: relative;
                top: -2px;
                vertical-align: middle;
            }
            &:hover {
                background: #004ace;
            }
        }
    }
    .list-table-header.seler-price-list {
        margin-top: 31px;
        .list-table-header-element {
            box-sizing: border-box;
            color: #94a0b1;
            cursor: pointer;
            font-size: 14px;
            line-height: 24px;
            padding-left: 20px;
            white-space: nowrap;
            &:hover {
                color: #005bff;
            }
        }
        .list-table-header-element:nth-child(1) {
            width: calc(100% - 399px);
            padding-left: 0;
        }
        .list-table-header-element:nth-child(2) {
            width: 216px;
            padding-left: 0;
        }
        .list-table-header-element:nth-child(3) {
            width: 183px;
            padding-left: 0;
        }
    }
    .list-table.seler-price-list {
        margin-bottom: 64px;
        .list-table-item {
            background: #fff;
            border: 0;
            border-radius: 8px;
            margin-bottom: 8px;
            display: flex;
            height: 64px;
            justify-content: space-between;
            position: relative;
            .list-table-item-element {
                box-sizing: border-box;
                display: block;
                line-height: 64px;
                padding-left: 20px;
                position: relative;
                span {
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    position: relative;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: calc(100% - 20px);
                    overflow: hidden;
                    line-height: 64px;
                }
            }
            .list-table-item-element:nth-child(1) {
                width: calc(100% - 399px);
                span {
                    position: relative;
                    display: block;
                    font-weight: 600;
                    font-size: 15px;
                }
            }
            .list-table-item-element:nth-child(2) {
                width: 216px;
                padding-left: 0;
                font-weight: 400;
                font-size: 14px;
                color: #94a0b1;
            }
            .list-table-item-element:nth-child(3) {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
                width: 183px;
                padding-left: 0;
                line-height: 0;
                b {
                    position: relative;
                    display: block;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    width: 80px;
                }
            }
            .list-table-item-history {
                cursor: pointer;
                &:hover {
                    svg {
                        path {
                            fill: #005bff;
                        }
                    }
                }
            }
            .list-table-item-plus {
                cursor: pointer;
                &:hover {
                    svg {
                        path {
                            fill: #005bff;
                        }
                    }
                }
            }
            .list-table-item-edit {
                cursor: pointer;
                &:hover {
                    svg {
                        path {
                            fill: #005bff;
                        }
                    }
                }
            }
        }
    }
}
