.statistic-block-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    position: relative;
    &.hidden {
        visibility: hidden;
    }
}
