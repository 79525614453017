.modal-content {
    .modal-field {
        background: #fff;
        border-radius: 16px;
        box-sizing: border-box;
        display: block;
        padding: 32px;
        position: relative;
        width: 699px;
        textarea {
            height: 54px;
            background: #f4f4f8;
            border: 1px solid #f4f4f8;
            border-radius: 8px;
            box-sizing: border-box;
            color: #212121;
            display: block;
            font-family: Averta CY;
            font-size: 15px;
            line-height: 24px;
            min-height: 54px;
            outline: none;
            padding: 15px 20px;
            position: relative;
            resize: none;
            width: 100%;
            scrollbar-width: thin;
            scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
            &::-webkit-scrollbar {
                border-radius: 2px;
                display: block;
                height: calc(100% - 32px);
                overflow: hidden;
                pointer-events: none;
                position: absolute;
                right: 8px;
                top: 16px;
                width: 4px;
                cursor: default;
                pointer-events: none;
            }
            &::-webkit-scrollbar-thumb {
                background: #94a0b160;
                border-radius: 2px;
                height: 30%;
                width: 100%;
                pointer-events: none;
            }
        }
        .modal-field-title {
            font-size: 24px;
            padding-right: 40px;
            font-weight: 600;
            line-height: 40px;
            margin-bottom: 32px;
            position: relative;
        }
        .modal-field-close {
            cursor: pointer;
            display: block;
            position: absolute;
            right: 40px;
            top: 40px;
        }
        .flex-block {
            display: flex;
            gap: 16px;
            justify-content: space-between;
            margin-bottom: 24px;
            position: relative;
            &.flex-start {
                justify-content: flex-start;
            }
            .form-field {
                display: block;
                position: relative;
                width: 100%;
            }
        }
        .form-field {
            display: block;
            position: relative;
            width: 100%;
            .form-field-label {
                color: #94a0b1;
                display: block;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 5px;
                margin-top: -3px;
                position: relative;
            }
            .input-field {
                display: block;
                position: relative;
                input {
                    background: #f4f4f8;
                    border: 1px solid #f4f4f8;
                    border-radius: 8px;
                    box-sizing: border-box;
                    color: #212121;
                    display: block;
                    font-family: Averta CY;
                    font-size: 15px;
                    height: 54px;
                    outline: none;
                    padding: 0 20px;
                    position: relative;
                    width: 100%;
                    &.error-input {
                        border: 1px solid #ed0a34;
                    }
                }
            }
        }
        .modal-buttons {
            display: flex;
            gap: 8px;
            height: 59px;
            justify-content: space-between;
            position: relative;
            height: auto;
            margin-top: 32px;
            .button {
                background: #005bff;
                border-radius: 80px;
                box-sizing: border-box;
                color: #fff;
                cursor: pointer;
                display: block;
                font-size: 15px;
                height: 56px;
                font-weight: 600;
                line-height: 56px;
                padding: 0 32px;
                position: relative;
                text-align: center;
                &:hover {
                    background: #004ace;
                }
            }
            .button.action-button.border-button {
                background: none;
                border: 2px solid #d1d8e1;
                color: #212121;
                line-height: 52px;
                height: 56px;
                font-size: 15px;
                padding: 0 32px;
                box-sizing: border-box;
                &:hover {
                    border: 2px solid #005bff;
                }
            }
        }
        .multiselect-container.multiSelectContainer#multiselectContainerReact {
            display: block;
            position: relative;
            width: auto;
            &.single {
                z-index: 1112;
                .search-wrapper.searchWrapper {
                    padding-left: 52px;
                    &::before {
                        background: url("/public/images/icons/ui/search.svg") no-repeat;
                        background-size: contain;
                        content: "";
                        display: block;
                        height: 20px;
                        left: 16px;
                        position: absolute;
                        top: 16px;
                        width: 20px;
                    }
                }
            }
        }
        &.add-new-price {
            width: 408px;

            .modal-buttons {
                margin-top: 32px;
                height: auto;
                .button {
                    text-align: center;
                }
            }
        }
        &.price-history-modal {
            width: 700px;
            padding-top: 21px;
            padding-bottom: 0;
            .modal-field-title {
                margin-bottom: 21px;
                line-height: 31px;
            }
            .modal-field-close {
                top: 24px;
                line-height: 0;
            }
            .price-history-list {
                position: relative;
                display: block;
                overflow: hidden;
                margin: 0 -32px;
                height: 428px;
                border-top: 1px solid #e2e8f0;

                .price-history-content-scroll {
                    position: relative;
                    display: block;
                    height: 428px;
                    overflow-y: scroll;
                    width: calc(100% - 8px);
                    scrollbar-width: thin;
                    scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
                    &::-webkit-scrollbar {
                        width: 4px;
                    }
                    &::-webkit-scrollbar-track {
                        background: transparent;
                        margin: 24px 0;
                    }
                    &::-webkit-scrollbar-thumb {
                        position: relative;
                        background: rgba($color: #94a0b1, $alpha: 0.3);
                        border-radius: 2px;
                    }
                    .price-history-content {
                        padding-bottom: 30px;
                        .price-history-item {
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            margin: 0 20px 0 32px;
                            line-height: 48px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #94a0b1;
                            border-bottom: 1px solid #e2e8f0;
                            &.price-history-item-current {
                                color: #212121;
                                padding-top: 45px;
                                margin-bottom: 8px;
                                border-bottom: 0;
                                small {
                                    position: absolute;
                                    display: block;
                                    left: 0;
                                    top: 24px;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 21px;
                                    color: #94a0b1;
                                }
                            }
                            .price-history-item-date {
                                position: relative;
                                width: 150px;
                            }
                            .price-history-item-user {
                                position: relative;
                                width: calc(100% - 270px);
                            }
                            .price-history-item-price {
                                position: relative;
                                font-size: 15px;
                                width: 120px;
                                font-weight: 600;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
        &.add-price-seler-modal {
            width: 504px;
            .modal-buttons {
                margin-top: 32px;
                height: auto;
                .button {
                    padding: 0;
                    width: 135px;
                    text-align: center;
                    &:nth-child(2) {
                        width: 210px;
                    }
                }
            }
        }
    }
}

.main-modal#mainModalWrap {
    .modal-content {
        .modal-field.add-video-modal {
            width: 319px;
            .modal-buttons {
                display: flex;
                flex-direction: column;
                gap: 8px;
                justify-content: space-between;
                position: relative;
                .modal-video-button.action-button {
                    border: 2px solid #d1d8e1;
                    border-radius: 4px;
                    box-sizing: border-box;
                    cursor: pointer;
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 55px;
                    padding-left: 20px;
                    position: relative;
                    width: 100%;
                    &:hover {
                        border-color: #005bff;
                    }
                    &:first-child {
                        &::after {
                            background: url("/public/images/icons/ui/different/gray/movie.svg");
                            content: "";
                            display: block;
                            height: 24px;
                            position: absolute;
                            right: 20px;
                            top: 16px;
                            width: 24px;
                        }
                    }
                    &:nth-child(2) {
                        &::after {
                            background: url("/public/images/icons/ui/different/gray/tv.svg");
                            content: "";
                            display: block;
                            height: 24px;
                            position: absolute;
                            right: 20px;
                            top: 16px;
                            width: 24px;
                        }
                    }
                    &:last-child {
                        &::after {
                            background: url("/public/images/icons/ui/different/gray/blogger.svg");
                            content: "";
                            display: block;
                            height: 24px;
                            position: absolute;
                            right: 20px;
                            top: 16px;
                            width: 24px;
                        }
                    }
                }
            }
        }
    }
}
