section.personal-area-item {
    header {
        display: flex;
        height: 56px;
        justify-content: space-between;
        margin-bottom: 32px;
        position: relative;
        h1 {
            font-weight: 600;
            font-size: 48px;
            line-height: 55px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            sup {
                color: #94a0b1;
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                margin-left: 8px;
                position: relative;
                vertical-align: top;
            }
        }
        button.button_add-category,
        a {
            cursor: pointer;
            font-family: "Averta CY";
            border: none;
            position: relative;
            white-space: nowrap;
            border-radius: 28px;
            height: 56px;
            line-height: 56px;
            background: #005bff;
            color: #fff;
            display: block;
            font-size: 15px;
            font-weight: 400;
            line-height: 36px;
            position: relative;
            padding: 0 32px;
            padding-left: 58px;
            a {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
            }
            img {
                display: block;
                left: 24px;
                position: absolute;
                top: 16px;
                margin-right: 6px;
            }
            &.button_add-sendord{
                margin-left: 10px;
                padding-left: 32px;
            }
        }
        button.button_add-category:hover {
            background: #004ace;
        }
    }
    .categories-content-wrapper {
        .category-item:last-child {
            border-bottom: 1px solid #e2e8f0;
        }
        .category-item.category-item-container {
            border-top: 1px solid #e2e8f0;
            box-sizing: border-box;
            display: block;
            position: relative;

            .category-item-header {
                align-items: center;
                cursor: pointer;
                display: flex;
                font-size: 15px;
                font-weight: 600;
                height: 64px;
                justify-content: space-between;
                padding-right: 16px;
                position: relative;
                svg {
                    transition: transform 0.3s ease-in-out;
                }
                &:hover {
                    span {
                        color: #005bff;
                    }
                }
            }
            .category-item-content {
                display: none;
                .category-item-control {
                    display: flex;
                    gap: 24px;
                    justify-content: flex-start;
                    margin-bottom: 29px;
                    position: relative;
                    .category-item-button.action-button {
                        cursor: pointer;
                        background: transparent;
                        border: none;
                        color: #94a0b1;
                        cursor: pointer;
                        display: flex;
                        font-size: 15px;
                        font-weight: 300;
                        gap: 8px;
                        justify-content: flex-start;
                        line-height: 24px;
                        position: relative;
                        font-family: "Averta CY";
                    }
                    .category-item-button.action-button:hover {
                        color: #005bff;
                        svg {
                            path {
                                fill: #005bff;
                            }
                        }
                    }
                    button.category-item-button.action-button:hover {
                        color: #005bff;
                        svg {
                            path {
                                fill: #005bff;
                            }
                        }
                    }
                }
                .category-item-add-block {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    position: relative;
                    span {
                        color: #94a0b1;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 24px;
                        position: relative;
                        text-transform: uppercase;
                    }
                    button {
                        box-sizing: border-box;
                        margin-top: -8px;
                        padding-left: 40px;
                        padding-right: 20px;
                        width: 127px;
                        border-radius: 20px;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 36px;
                        position: relative;
                        background: none;
                        border: 2px solid #d1d8e1;
                        color: #212121;
                        cursor: pointer;
                        display: block;
                        font-family: "Averta CY";
                    }
                    button::before {
                        background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
                        content: "";
                        display: block;
                        height: 16px;
                        left: 16px;
                        position: absolute;
                        top: 10px;
                        width: 16px;
                    }
                    button:hover {
                        border: 2px solid #005bff !important;
                    }
                }
                .category-subitems {
                    padding-bottom: 24px;
                    .category-subitem.category-item-container {
                        border: 1px solid #e2e8f0;
                        border-radius: 16px;
                        box-sizing: border-box;
                        display: block;
                        margin-bottom: 8px;
                        padding: 16px 24px;
                        position: relative;
                        .category-item-header {
                            height: 24px;
                            padding-right: 0;
                        }
                        .category-item-content {
                            display: none;
                            .category-item-control {
                                display: flex;
                                gap: 24px;
                                justify-content: flex-start;
                                margin-bottom: 29px;
                                padding-top: 16px;
                                position: relative;
                            }
                            .category-subitem-add-block {
                                align-items: center;
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 16px;
                                position: relative;
                                span {
                                    color: #94a0b1;
                                    font-size: 15px;
                                    font-weight: 400;
                                    line-height: 24px;
                                    position: relative;
                                }
                                button {
                                    box-sizing: border-box;
                                    margin-top: -8px;
                                    padding-left: 40px;
                                    padding-right: 20px;
                                    width: 127px;
                                    border-radius: 20px;
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 36px;
                                    position: relative;
                                    background: none;
                                    border: 2px solid #d1d8e1;
                                    color: #212121;
                                    cursor: pointer;
                                    display: block;
                                    font-family: "Averta CY";
                                }
                                button::before {
                                    background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
                                    content: "";
                                    display: block;
                                    height: 16px;
                                    left: 16px;
                                    position: absolute;
                                    top: 10px;
                                    width: 16px;
                                }
                                button:hover {
                                    border: 2px solid #005bff !important;
                                }
                            }
                            .category-subitems-items {
                                .category-subitems-item.category-item-container {
                                    align-items: center;
                                    border-bottom: 1px solid #e2e8f0;
                                    box-sizing: border-box;
                                    display: flex;
                                    font-size: 15px;
                                    font-weight: 400;
                                    height: 48px;
                                    justify-content: space-between;
                                    line-height: 48px;
                                    position: relative;
                                    &:last-child {
                                        border-bottom: none;
                                    }
                                    span {
                                        align-items: center;
                                        // border-bottom: 1px solid #e2e8f0;
                                        box-sizing: border-box;
                                        display: flex;
                                        font-size: 15px;
                                        font-weight: 400;
                                        height: 48px;
                                        justify-content: space-between;
                                        line-height: 48px;
                                        position: relative;
                                        font-family: "Averta CY";
                                    }
                                    .category-subitems-item-control {
                                        align-items: center;
                                        display: flex;
                                        gap: 24px;
                                        height: 24px;
                                        justify-content: flex-end;
                                        line-height: 0px;
                                        padding-right: 8px;
                                        position: relative;
                                        button {
                                            border: none;
                                            background: transparent;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .category-item.category-subitem.category-item-container.open {
                        .category-item-content {
                            display: block;
                        }
                    }
                }
            }
        }
        .category-item.category-item-container.open {
            border-top: 1px solid #e2e8f0;
            box-sizing: border-box;
            display: block;
            position: relative;

            .category-item-header.first-parent {
                align-items: center;
                cursor: pointer;
                display: flex;
                font-size: 15px;
                font-weight: 600;
                height: 64px;
                justify-content: space-between;
                padding-right: 16px;
                position: relative;
                svg {
                    transform: rotate3d(1, 0, 0, 180deg);
                }
                &:hover {
                    span {
                        color: #005bff;
                    }
                }
            }

            .category-item-content {
                display: block;
                overflow: hidden;
            }
        }
    }
}
