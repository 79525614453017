.home-table.blogger-users {
    width: 100%;
    .form-container-block-title {
        position: relative;
        margin-bottom: 32px;
        font-size: 15px;
        line-height: 24px;
        b {
            position: relative;
            display: block;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 16px;
        }
    }
    .form-container-progress-blocks {
        .form-container-progress-block.header {
            margin-bottom: 18px;
        }
        .accordion-wrapper {
            margin-top: 11px;
            &:first-child {
                margin-top: 0;
            }
            .accordion-header {
                padding-right: 36px;
                position: relative;
                .header-name {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                }
                svg {
                    position: absolute;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    top: calc(50% - 12px);
                }
            }
            .accordion-content {
                padding-right: 36px;
                .form-container-progress-block {
                    span {
                        height: 21px;
                    }
                    .form-container-progress-block-right {
                        height: 21px;
                    }
                    &:first-child {
                        margin-top: 19px;
                    }
                }
                &.open {
                    padding-bottom: 17px;
                    border-bottom: 1px solid #e2e8f0;
                }
            }
        }
        .form-container-progress-block {
            display: flex;
            font-size: 13px;
            height: 21px;
            justify-content: space-between;
            line-height: 21px;
            margin-top: 11px;
            position: relative;
            width: 100%;

            b {
                font-size: 15px;
                font-weight: 600;
            }

            &:first-child {
                margin-top: 0;
            }

            .form-container-progress-block-right {
                position: relative;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 60%;

                span {
                    position: relative;
                    display: block;
                    width: 44px;
                    text-align: right;
                    font-weight: 600;
                    &:last-child {
                        width: 90px;
                    }
                }

                .form-container-progress-block-line {
                    position: relative;
                    height: 6px;
                    border-radius: 6px;
                    width: calc(100% - 88px);
                    overflow: hidden;

                    .form-container-progress-block-linefill {
                        position: absolute;
                        display: block;
                        right: 0;
                        top: 0;
                        height: 6px;
                        border-radius: 6px;
                    }
                }
            }
        }
        &.pink {
            .form-container-progress-block-right {
                span {
                    color: #cc5ef5;
                }

                .form-container-progress-block-line {
                    .form-container-progress-block-linefill {
                        background: #cc5ef5;
                    }
                }
            }
        }
        &.green {
            .form-container-progress-block-right {
                span {
                    color: #0dc268;
                }

                .form-container-progress-block-line {
                    .form-container-progress-block-linefill {
                        background: #0dc268;
                    }
                }
            }
        }
        &.blue {
            .form-container-progress-block-right {
                span {
                    color: #005bff;
                }

                .form-container-progress-block-line {
                    .form-container-progress-block-linefill {
                        background: #005bff;
                    }
                }
            }
        }
    }
    .form-container-progress-blocks {
        .form-container-progress-block.header {
            margin-bottom: 18px;
        }
        .accordion-wrapper {
            margin-top: 11px;
            &:first-child {
                margin-top: 0;
            }
            .accordion-header {
                padding-right: 36px;
                position: relative;
                .header-name {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                }
                svg {
                    position: absolute;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    top: calc(50% - 12px);
                }
            }
            .accordion-content {
                padding-right: 36px;
                .form-container-progress-block {
                    span {
                        height: 21px;
                    }
                    .form-container-progress-block-right {
                        height: 21px;
                    }
                    &:first-child {
                        margin-top: 19px;
                    }
                }
                &.open {
                    padding-bottom: 17px;
                    border-bottom: 1px solid #e2e8f0;
                }
            }
        }
        .form-container-progress-block {
            display: flex;
            font-size: 13px;
            height: 21px;
            justify-content: space-between;
            line-height: 21px;
            margin-top: 11px;
            position: relative;
            width: 100%;

            b {
                font-size: 15px;
                font-weight: 600;
            }

            &:first-child {
                margin-top: 0;
            }

            .form-container-progress-block-right {
                position: relative;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 60%;

                span {
                    position: relative;
                    display: block;
                    width: 44px;
                    text-align: right;
                    font-weight: 600;
                    &:last-child {
                        width: 90px;
                    }
                }

                .form-container-progress-block-line {
                    position: relative;
                    height: 6px;
                    border-radius: 6px;
                    width: calc(100% - 88px);
                    overflow: hidden;

                    .form-container-progress-block-linefill {
                        position: absolute;
                        display: block;
                        right: 0;
                        top: 0;
                        height: 6px;
                        border-radius: 6px;
                    }
                }
            }
        }
        &.pink {
            .form-container-progress-block-right {
                span {
                    color: #cc5ef5;
                }

                .form-container-progress-block-line {
                    .form-container-progress-block-linefill {
                        background: #cc5ef5;
                    }
                }
            }
        }
        &.green {
            .form-container-progress-block-right {
                span {
                    color: #0dc268;
                }

                .form-container-progress-block-line {
                    .form-container-progress-block-linefill {
                        background: #0dc268;
                    }
                }
            }
        }
        &.blue {
            .form-container-progress-block-right {
                span {
                    color: #005bff;
                }

                .form-container-progress-block-line {
                    .form-container-progress-block-linefill {
                        background: #005bff;
                    }
                }
            }
        }
    }
}
