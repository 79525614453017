.content-wrapper {
    .multiselect-container.multiSelectContainer#multiselectContainerReact {
        .search-wrapper.searchWrapper {
            background: #f4f4f8;
            border: 1px solid #f4f4f8;
            border-radius: 8px;
            box-sizing: border-box;
            color: #212121;
            font-family: "Averta CY";
            font-size: 15px;
            height: auto;
            min-height: 54px;
            outline: none;
            padding: 11px 80px 8px 16px;
            position: relative;
            width: 100% !important;
            display: flex;
            &:hover {
                border: 1px solid #d1d8e1;
            }
            &::after {
                background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
                content: "";
                display: block;
                height: 24px;
                pointer-events: none;
                position: absolute;
                right: 16px;
                top: 14px;
                width: 24px;
            }
            input {
                font-family: "Averta CY";
                font-size: 15px;
                cursor: text;
                height: auto;
                margin: 0;
                overflow: hidden;
                padding: 0;
                position: relative;
                max-width: 100%;
                width: 100%;
                display: inline-block;
                &:focus {
                    border: none;
                }
                &:hover {
                    border: none;
                }
            }
            span {
                background: #d1d8e1;
                border-radius: 6px;
                box-sizing: border-box;
                color: #212121;
                cursor: pointer;
                font-size: 15px;
                line-height: 29px;
                margin-bottom: 4px;
                margin-right: 4px;
                max-width: 100%;
                padding: 0 24px 0 8px;
                position: relative;
                i {
                    display: block;
                    font-size: 1px;
                    height: 16px;
                    position: absolute;
                    right: 5px;
                    top: 6px;
                    width: 16px;
                    svg {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }
    }
    .list-table-header.brightbox-list {
        &.owner {
            .list-table-header-element:nth-child(1) {
                width: 9.55%;
            }
            .list-table-header-element:nth-child(2) {
                width: 23.57%;
            }
            .list-table-header-element:nth-child(3) {
                width: 18.57%;
            }
            .list-table-header-element:nth-child(4) {
                width: 15.18%;
            }
            .list-table-header-element:nth-child(5) {
                width: 17.85%;
            }
            .list-table-header-element:nth-child(6) {
                width: 11.16%;
            }
            .list-table-header-element:nth-child(7) {
                width: 11.6%;
            }
            .list-table-header-element:nth-child(8) {
                width: 11.07%;
            }
            .list-table-header-element:nth-child(9) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                padding-right: 8px;
            }
            .list-table-header-element:nth-child(10) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                padding-right: 8px;
            }
            .list-table-header-element:nth-child(11) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                padding-right: 8px;
            }
        }
        &.blogger {
            .list-table-header-element:nth-child(1) {
                width: 6.55%;
            }
            .list-table-header-element:nth-child(2) {
                width: 18.57%;
            }
            .list-table-header-element:nth-child(3) {
                width: 10.18%;
                min-width: 100px;
            }
            .list-table-header-element:nth-child(4) {
                width: 10.18%;
                min-width: 100px;
            }
            .list-table-header-element:nth-child(5) {
                width: 12.18%;
            }
            .list-table-header-element:nth-child(6) {
                width: 12.85%;
            }
            .list-table-header-element:nth-child(7) {
                width: 7.16%;
                min-width: 80px;
            }
            .list-table-header-element:nth-child(8) {
                width: 7.6%;
                min-width: 80px;
            }
            .list-table-header-element:nth-child(9) {
                min-width: 80px;
                width: 7.07%;
            }
            .list-table-header-element:nth-child(10) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                padding-right: 8px;
            }
            .list-table-header-element:nth-child(11) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                padding-right: 8px;
            }
            .list-table-header-element:nth-child(12) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                padding-right: 8px;
            }
        }
        .list-table-header-element {
            padding-left: 10px;
            position: relative;
            &.non-sort {
                pointer-events: none;
                span {
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .list-table-header-element:nth-child(1) {
            width: 9.55%;
        }
        .list-table-header-element:nth-child(2) {
            width: 23.57%;
        }
        .list-table-header-element:nth-child(3) {
            width: 15.18%;
        }
        .list-table-header-element:nth-child(4) {
            width: 17.85%;
        }
        .list-table-header-element:nth-child(5) {
            width: 11.16%;
        }
        .list-table-header-element:nth-child(6) {
            width: 11.6%;
        }
        .list-table-header-element:nth-child(7) {
            width: 11.07%;
        }
        .list-table-header-element:nth-child(8) {
            width: 40px;
            min-width: 40px;
            padding-left: 0;
            display: flex;
            align-items: center;
            padding-right: 8px;
        }
        .list-table-header-element:nth-child(9) {
            width: 40px;
            min-width: 40px;
            padding-left: 0;
            display: flex;
            align-items: center;
            padding-right: 8px;
        }
        .list-table-header-element:nth-child(10) {
            width: 40px;
            min-width: 40px;
            padding-left: 0;
            display: flex;
            align-items: center;
            padding-right: 8px;
        }
    }
    .list-table.brightbox-list {
        margin-bottom: 64px;
        .list-table-item.action-button {
            justify-content: space-between;
            position: relative;
            border-bottom: 1px solid #e2e8f0;
            display: flex;
            height: 48px;
            color: #212121;
            font-family: "Averta CY";
            &:hover {
                background: #f4f4f8;
            }
            &.owner {
                .list-table-item-element:nth-child(1) {
                    width: 9.55%;
                }
                .list-table-item-element:nth-child(2) {
                    width: 23.57%;
                }
                .list-table-item-element:nth-child(3) {
                    width: 18.57%;
                }
                .list-table-item-element:nth-child(4) {
                    width: 15.18%;
                }
                .list-table-item-element:nth-child(5) {
                    width: 17.85%;
                }
                .list-table-item-element:nth-child(6) {
                    width: 11.16%;
                }
                .list-table-item-element:nth-child(7) {
                    width: 11.6%;
                }
                .list-table-item-element:nth-child(8) {
                    width: 11.07%;
                }
                .list-table-item-element:nth-child(9) {
                    width: 40px;
                    min-width: 40px;
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    // padding-right: 8px;
                }
                .list-table-item-element:nth-child(10) {
                    width: 40px;
                    min-width: 40px;
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // padding-right: 8px;
                    .action-button {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            svg {
                                path {
                                    fill: #005bff;
                                }
                            }
                        }
                    }
                }
                .list-table-item-element:nth-child(11) {
                    width: 40px;
                    min-width: 40px;
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    // padding-right: 8px;
                    justify-content: center;
                    .action-button {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            svg {
                                path {
                                    fill: #005bff;
                                }
                            }
                        }
                    }
                }
            }
            &.blogger {
                .list-table-item-element:nth-child(1) {
                    width: 6.55%;
                }
                .list-table-item-element:nth-child(2) {
                    width: 18.57%;
                }
                .list-table-item-element:nth-child(3) {
                    width: 10.18%;
                    min-width: 100px;
                }
                .list-table-item-element:nth-child(4) {
                    width: 10.18%;
                    min-width: 100px;
                }
                .list-table-item-element:nth-child(5) {
                    width: 12.18%;
                }
                .list-table-item-element:nth-child(6) {
                    width: 12.85%;
                }
                .list-table-item-element:nth-child(7) {
                    min-width: 80px;
                    width: 7.16%;
                }
                .list-table-item-element:nth-child(8) {
                    min-width: 80px;
                    width: 7.6%;
                }
                .list-table-item-element:nth-child(9) {
                    min-width: 80px;
                    width: 7.07%;
                }
                .list-table-item-element:nth-child(10) {
                    width: 40px;
                    min-width: 40px;
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    padding-right: 8px;
                }
                .list-table-item-element:nth-child(11) {
                    width: 40px;
                    min-width: 40px;
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    // padding-right: 8px;
                    justify-content: center;
                    .action-button {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            svg {
                                path {
                                    fill: #005bff;
                                }
                            }
                        }
                    }
                }
                .list-table-item-element:nth-child(12) {
                    width: 40px;
                    min-width: 40px;
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    // padding-right: 8px;
                    justify-content: center;
                    .action-button {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            svg {
                                path {
                                    fill: #005bff;
                                }
                            }
                        }
                    }
                }
            }
            .list-table-item-element:nth-child(1) {
                width: 9.55%;
            }
            .list-table-item-element:nth-child(2) {
                width: 23.57%;
            }
            .list-table-item-element:nth-child(3) {
                width: 15.18%;
            }
            .list-table-item-element:nth-child(4) {
                width: 17.85%;
            }
            .list-table-item-element:nth-child(5) {
                width: 11.16%;
            }
            .list-table-item-element:nth-child(6) {
                width: 11.6%;
            }
            .list-table-item-element:nth-child(7) {
                width: 11.07%;
            }
            .list-table-item-element:nth-child(8) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                // padding-right: 8px;
            }
            .list-table-item-element:nth-child(9) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                // padding-right: 8px;
                .action-button {
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        svg {
                            path {
                                fill: #005bff;
                            }
                        }
                    }
                }
            }
            .list-table-item-element:nth-child(10) {
                width: 40px;
                min-width: 40px;
                padding-left: 0;
                display: flex;
                align-items: center;
                // padding-right: 8px;
                justify-content: center;
                .action-button {
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        svg {
                            path {
                                fill: #005bff;
                            }
                        }
                    }
                }
            }
            .list-table-item-element {
                cursor: pointer;
                font-size: 13px;
                line-height: 48px;
                padding-left: 8px;
                position: relative;
                box-sizing: border-box;
                display: block;
                span {
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: calc(100% - 10px);
                    cursor: pointer;
                    font-size: 13px;
                    line-height: 48px;
                }
            }
        }
    }
}

.search-wrapper {
    // react-select styles
    &:focus {
        border: 1px solid #d1d8e1 !important;
    }
    // span,
    .select__indicators {
        // display: none;
        .select__indicator-separator {
            display: none;
        }
        .select__indicator.select__dropdown-indicator {
            display: none;
        }
    }
    .select__control--is-focused {
        border: 1px solid #d1d8e1 !important;
        box-shadow: none;
    }
    .select__control {
        background: #f4f4f8;
        border: 1px solid #f4f4f8;
        border-radius: 8px;
        box-sizing: border-box;
        color: #212121;
        font-family: "Averta CY";
        font-size: 15px;
        height: auto;
        min-height: 54px;
        outline: none;
        padding: 11px 80px 8px 16px;
        position: relative;
        width: 100% !important;
        display: flex;
        cursor: pointer;
        .select__placeholder {
            font-weight: 400;
            font-size: 15px;
            color: #212121;
        }

        &:hover {
            border: 1px solid #d1d8e1;
        }
        &::after {
            background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
            content: "";
            display: block;
            height: 24px;
            pointer-events: none;
            position: absolute;
            right: 16px;
            top: 14px;
            width: 24px;
            margin: 0;
            padding: 0;
        }
        input {
            font-family: "Averta CY";
            font-size: 15px;
            cursor: text;
            height: auto;
            margin: 0;
            overflow: hidden;
            padding: 0;
            position: relative;
            max-width: 100%;
            width: 100%;
            display: inline-block;
            &:focus {
                border: none;
            }
            &:hover {
                border: none;
            }
        }
        span {
            background: #d1d8e1;
            border-radius: 6px;
            box-sizing: border-box;
            color: #212121;
            cursor: pointer;
            font-size: 15px;
            line-height: 29px;
            margin-bottom: 4px;
            margin-right: 4px;
            max-width: 100%;
            padding: 0 24px 0 8px;
            position: relative;
            i {
                display: block;
                font-size: 1px;
                height: 16px;
                position: absolute;
                right: 5px;
                top: 6px;
                width: 16px;
                svg {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
    .select__menu {
        background: #fff;
        border-radius: 8px;
        border-top: 0;
        box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
        // top: calc(100% + 8px);
        overflow: hidden;
        z-index: 1010;
        div {
            color: #444;
            margin: 0;
            max-height: 240px;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0;
            position: relative;
            width: calc(100% - 8px);
            padding: 16px 0 16px 0;
            scrollbar-width: thin;
            scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
            scrollbar-gutter: auto;
            &::-webkit-scrollbar {
                border-radius: 2px;
                display: block;
                height: calc(100% - 32px);
                overflow: hidden;
                pointer-events: none;
                position: absolute;
                right: 8px;
                top: 16px;
                width: 4px;
                cursor: default;
                pointer-events: none;
            }
            &::-webkit-scrollbar-track-piece {
                &:start {
                    background: transparent;
                }
            }
            &::-webkit-scrollbar-thumb {
                background: #94a0b160;
                border-radius: 2px;
                height: 30%;
                width: 100%;
                pointer-events: none;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
                margin: 16px 0;
            }
            div {
                cursor: pointer;
                display: list-item;
                font-size: 15px;
                height: 40px;
                line-height: 40px;
                padding: 0 20px;
                width: 100%;
                position: relative;
                text-overflow: ellipsis;
                white-space: nowrap;
                &:hover {
                    color: #005bff;
                }
                &:hover {
                    background: #f4f4f8;
                }
            }
        }
    }
}
