.radio-buttons-wrapper {
    display: flex;
    .radio-button-wrapper {
        display: flex;
        gap: 10px;
        cursor: pointer;
        &:hover {
            .radio-label {
                color: #005bff;
            }
        }
        .radio {
            width: 24px;
            height: 24px;
            background: #f4f4f8;
            border: 1px solid #d1d8e1;
            border-radius: 80px;
            &.selected {
                border: 8px #005bff solid;
            }
        }
        .radio-label {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
        }
    }
}
