.category-popup {
    max-width: 1300px;
    min-width: 1160px;
    @media (max-width: 1200px) {
        min-width: 1000px;
    }
    &__content {
        position: relative;
        display: flex;
        column-gap: 32px;
        padding-top: 15px;
        padding: 8px 32px 8px 0;
        &::before {
            content: "";
            position: absolute;
            width: calc(100% + 32px * 2);
            height: 3px;
            background-color: #e2e8f0;
            left: -32px;
            top: -12px;
        }
        li {
            list-style: none;

            cursor: pointer;
            transition: color, background-color 0.2 ease-in-out;
            &:hover {
                color: #0052e4;
            }
        }
    }
    &__collapse-btn {
        position: relative;
        font-family: "Averta CY";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #005bff;
        border: none;
        background: transparent;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 26px;
        &:after {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 0;
            top: 2px;
            content: url("/public/images/icons/ui/navigation/blue/drop_next.svg");
            transform: rotate(90deg);
        }
        &.open:after {
            transform: rotate(-90deg);
        }
    }
    &__parent-list {
        list-style: none;
        display: block !important;
        border-radius: 8px;
        li {
            padding-left: 12px;
            padding-right: 32px;
            list-style: none;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 160%;
            &.active {
                color: #0052e4;
            }
            &:after {
                width: 24px;
                height: 24px;
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                content: url("/public/images/icons/ui/navigation/gray/drop_next.svg");
                display: none;
            }
            &:hover {
                background-color: #f4f4f8;
                &:after {
                    display: block;
                }
            }
        }
    }
    &__child-list {
        flex: 1;
        ul {
            align-items: flex-start;
            padding-top: 8px;
            padding-bottom: 8px;
            flex-direction: column;
            row-gap: 8px;
            &:not(&.h100) {
                height: max-content;
            }
            height: max-content;
            display: inline-flex;
            vertical-align: top;
            &:not(:last-child) {
                // margin-right: 32px;
            }
            p {
                &.T15-bold {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 160%;
                }
            }
            li {
                font-size: 14px;
                line-height: 20px;

                &.T14-list.T-grey {
                    color: #94a0b1;
                    font-size: 14px;
                    line-height: 20px;
                    &:hover {
                        color: #0052e4;
                    }
                }
            }
        }
    }
    .modal {
        &-content__wrapper {
            box-sizing: border-box;
            padding: 32px;
            position: relative;
            background: #fff;
            border-radius: 16px;
            // &--centered {
            // @include col-center;
            // }
        }
        &-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
        }
        &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 130%;
        }
        &-subtitle {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 160%;
            margin-bottom: 24px;
        }
        &__bottom-btns {
            width: 100%;
            column-gap: 18px;
            display: flex;
            justify-content: space-between;
            position: relative;
        }
    }
}

.category-input {
    padding-right: 40px !important;
}
