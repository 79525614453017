.side-filters_city {
    min-height: 54px;
    z-index: 1;
    .select__control {
        input {
            height: auto !important;
        }
    }
    & {
        .search-wrapper.searchWrapper {
            z-index: 100;
            flex-wrap: wrap;
            padding-right: 40px !important;
        }
    }
}

.select__menu {
    z-index: 1000 !important;
}
