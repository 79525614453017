section {
    .content-wrapper {
        .list-filter.flex-block {
            margin-bottom: 48px;
            display: flex;
            gap: 16px;
            justify-content: space-between;
            position: relative;
            .form-field {
                display: block;
                position: relative;
                width: 100%;
                margin-bottom: 0;
                .form-field-label {
                    color: #94a0b1;
                    display: block;
                    font-size: 14px;
                    line-height: 22px;
                    height: 22px;
                    margin-bottom: 5px;
                    margin-top: -3px;
                    position: relative;
                }
                .input-field.left-icon {
                    display: block;
                    position: relative;
                    input {
                        background: #f4f4f8;
                        border: 1px solid #f4f4f8;
                        border-radius: 8px;
                        box-sizing: border-box;
                        color: #212121;
                        display: block;
                        font-family: "Averta CY";
                        font-size: 15px;
                        height: 54px;
                        outline: none;
                        padding: 0 20px 0 50px;
                        position: relative;
                        width: 100%;
                        &:hover {
                            border: 1px solid #d1d8e1;
                        }
                        &:focus {
                            border: 1px solid #005bff;
                        }
                    }

                    .input-search.input-icon {
                        display: block;
                        height: 20px;
                        cursor: pointer;
                        position: absolute;
                        left: 16px;
                        right: auto;
                        top: 18px;
                        width: 20px;
                        background: url("/public/images/icons/ui/navigation/search.svg");
                        background-size: contain;
                    }
                }
                .input-field {
                    display: block;
                    position: relative;
                    .select-block.multiselect {
                        background: #f4f4f8;
                        border: 1px solid #f4f4f8;
                        border-radius: 8px;
                        cursor: pointer;
                        display: block;
                        font-size: 15px;
                        height: 54px;
                        outline: none;
                        position: relative;
                        &:hover {
                            border: 1px solid #d1d8e1;
                        }
                        .select-block-button {
                            font-size: 15px;
                            line-height: 54px;
                            padding: 0 20px;
                            span {
                                display: block;
                                overflow: hidden;
                                position: absolute;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: calc(100% - 60px);
                            }
                            &::after {
                                background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
                                content: "";
                                display: block;
                                height: 24px;
                                pointer-events: none;
                                position: absolute;
                                right: 16px;
                                top: 16px;
                                width: 24px;
                            }
                        }

                        .select-block-list {
                            background: #fff;
                            border-radius: 8px;
                            box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
                            cursor: default;
                            display: none;
                            overflow: hidden;
                            position: absolute;
                            top: calc(100% + 8px);
                            width: 100%;
                            z-index: 100;
                            .select-block-list-scroll {
                                max-height: 268px;
                                display: block;
                                padding: 16px 0 16px 0;
                                overflow-y: scroll;
                                position: relative;
                                width: calc(100% - 8px);
                                scrollbar-width: thin;
                                scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
                                &::-webkit-scrollbar {
                                    width: 4px;
                                }
                                &::-webkit-scrollbar-thumb {
                                    position: relative;
                                    background: rgba($color: #94a0b1, $alpha: 0.3);
                                    border-radius: 2px;
                                }
                                &::-webkit-scrollbar-track {
                                    background: transparent;
                                    margin: 16px 0;
                                }
                                .select-block-item {
                                    position: relative;
                                    cursor: pointer;
                                    display: block;
                                    font-size: 15px;
                                    height: 40px;
                                    line-height: 40px;
                                    padding: 0 20px;
                                    span {
                                        padding-left: 38px;
                                        width: calc(100% - 100px);
                                        display: block;
                                        overflow: hidden;
                                        position: absolute;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    span:hover {
                                        color: #005bff;
                                    }
                                    &:first-child {
                                        margin-top: 0;
                                    }
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    &:hover {
                                        background: #f4f4f8;
                                    }
                                    &::before {
                                        background: #f4f4f8;
                                        border: 1px solid #d1d8e1;
                                        border-radius: 4px;
                                        box-sizing: border-box;
                                        content: "";
                                        display: block;
                                        height: 24px;
                                        left: 20px;
                                        position: absolute;
                                        top: calc(50% - 12px);
                                        width: 24px;
                                    }
                                }
                                .select-block-item.selected {
                                    position: relative;
                                    cursor: pointer;
                                    display: block;
                                    font-size: 15px;
                                    height: 40px;
                                    line-height: 40px;
                                    padding: 0 20px;
                                    span {
                                        padding-left: 38px;
                                        width: calc(100% - 100px);
                                        display: block;
                                        overflow: hidden;
                                        position: absolute;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    &::before {
                                        border: 1px solid #d1d8e1;
                                        background: #005bff url("/public/images/icons/ui/forms/white/check.svg")
                                            no-repeat 50% 50%;
                                        border-color: #005bff;
                                        border-radius: 4px;
                                        box-sizing: border-box;
                                        content: "";
                                        display: block;
                                        height: 24px;
                                        left: 20px;
                                        position: absolute;
                                        top: calc(50% - 12px);
                                        width: 24px;
                                    }
                                }
                            }
                            .select-block-control {
                                border-top: 1px solid #e2e8f0;
                                display: flex;
                                height: 44px;
                                justify-content: space-between;
                                position: relative;
                                .button-select {
                                    position: relative;
                                    background: transparent;
                                    border: none;
                                    outline: none;
                                    cursor: pointer;
                                    font-size: 15px;
                                    line-height: 44px;
                                    padding: 0 20px;
                                    font-family: "Averta CY";
                                }
                                .select-block-cancel {
                                    color: #94a0b1;
                                }
                                .select-block-select {
                                    color: #005bff;
                                }
                            }
                        }
                    }
                    .select-block.multiselect.active {
                        .select-block-list {
                            display: block;
                        }
                    }
                }
            }
        }
        .list-table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            position: relative;
            height: 24px;
            .list-table-header-element {
                background: transparent;
                border: none;
                outline: none;
                box-sizing: border-box;
                color: #94a0b1;
                cursor: pointer;
                font-size: 14px;
                line-height: 24px;
                padding-left: 20px;
                display: flex;
                position: relative;
                &.non-sort {
                    pointer-events: none;
                    span {
                        &:after {
                            display: none;
                        }
                    }
                }
                span {
                    align-items: center;
                    overflow: hidden;
                    position: absolute;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: calc(100% - 20px);
                    &.non-sort {
                        pointer-events: none;
                        cursor: default;
                        &::after {
                            display: none;
                        }
                    }
                    &:after {
                        background: url("/public/images/icons/ui/actions/gray/sort_filled.svg") no-repeat;
                        content: "";
                        display: inline-block;
                        height: 24px;
                        position: relative;
                        vertical-align: top;
                        width: 24px;
                    }
                    &:hover {
                        color: #005bff;
                        &:after {
                            background: url("/public/images/icons/ui/actions/blue/sort_filled.svg") no-repeat;
                        }
                    }
                }
            }
            &.video-list {
                .list-table-header-element:nth-child(1) {
                    width: 23.27%;
                    padding-left: 0;
                }
                .list-table-header-element:nth-child(2) {
                    width: 8.88%;
                }
                .list-table-header-element:nth-child(3) {
                    width: 23.16%;
                }
                .list-table-header-element:nth-child(4) {
                    width: 8.13%;
                }
                .list-table-header-element:nth-child(5) {
                    width: 13.67%;
                }
                .list-table-header-element:nth-child(6) {
                    width: 9.43%;
                }
                .list-table-header-element:nth-child(7) {
                    width: 184px;
                }
            }
        }
        .list-table {
            margin-bottom: 64px;
            position: relative;
            display: flex;
            flex-direction: column;
            &.video-list {
                .list-table-item {
                    border-bottom: 1px solid #e2e8f0;
                    display: flex;
                    height: 64px;
                    justify-content: space-between;
                    position: relative;
                    &:hover {
                        .tooltip {
                            display: none;
                        }
                    }
                    .list-table-item-element {
                        display: flex;
                        box-sizing: border-box;
                        // display: block;
                        line-height: 64px;
                        padding-left: 20px;
                        position: relative;
                        border: none;
                        outline: none;
                        &:hover {
                            .tooltip {
                                display: block;
                                left: 30px;
                            }
                        }
                        span {
                            display: block;
                            overflow: hidden;
                            position: absolute;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: calc(100% - 20px);
                            a {
                                display: block;
                                color: #212121;
                                text-decoration: none;
                                &:hover {
                                    color: #005bff;
                                }
                            }
                        }
                    }
                    .list-table-item-element:nth-child(1) {
                        width: 23.27%;
                        padding-left: 20px;
                    }
                    .list-table-item-element:nth-child(2) {
                        width: 8.88%;
                    }
                    .list-table-item-element:nth-child(3) {
                        width: 23.16%;
                    }
                    .list-table-item-element:nth-child(4) {
                        width: 8.13%;
                    }
                    .list-table-item-element:nth-child(5) {
                        width: 13.67%;
                    }
                    .list-table-item-element:nth-child(6) {
                        width: 9.43%;
                    }
                    // .list-table-item-element:nth-child(7) {
                    // width: 18.4%;
                    // }
                    .list-table-item-element:last-child,
                    .list-table-item-element:nth-child(7) {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        .list-table-item-status {
                            display: block;
                            height: 40px;
                            margin-left: -16px;
                            position: relative;
                            width: 40px;
                            .help-tip {
                                background: #fff;
                                border-radius: 8px;
                                bottom: calc(100% + 5px);
                                display: none;
                                filter: drop-shadow(8px 8px 28px rgba(46, 69, 102, 0.2));
                                left: 20px;
                                padding: 16px;
                                pointer-events: none;
                                position: absolute;
                                transform: translateX(-50%);
                                span {
                                    font-size: 13px !important;
                                    line-height: 20px !important;
                                    overflow: visible !important;
                                    position: relative !important;
                                    text-overflow: unset !important;
                                    width: auto !important;
                                }
                                img {
                                    left: calc(50% - 32px);
                                    position: absolute;
                                    top: 100%;
                                }
                            }
                            &:hover {
                                .help-tip {
                                    display: block;
                                }
                            }
                            &.disactive::after {
                                background: #ed0a34;
                            }
                            &::after {
                                background: #0dc268;
                                border-radius: 8px;
                                content: "";
                                display: block;
                                height: 8px;
                                left: calc(50% - 4px);
                                position: absolute;
                                top: calc(50% - 4px);
                                width: 8px;
                            }
                        }
                        .action-button {
                            align-items: center;
                            background-size: 24px 24px;
                            cursor: pointer;
                            display: flex;
                            height: 40px;
                            justify-content: center;
                            position: relative;
                            width: 40px;
                            &:hover {
                                path {
                                    fill: #005bff;
                                }
                            }
                        }
                        .list-table-item-submenu {
                            .list-table-item-submenu {
                                background: #fff;
                                border-radius: 8px;
                                box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
                                display: none;
                                padding: 12px 0;
                                position: absolute;
                                right: 0;
                                top: 40px;
                                z-index: 10;
                                &.active {
                                    display: block;
                                    .list-table-item-submenu-item {
                                        cursor: pointer;
                                        display: block;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 30px;
                                        padding: 4px 24px 4px 20px;
                                        position: relative;
                                        white-space: nowrap;
                                        &:hover {
                                            background: #f4f4f8;
                                            color: #005bff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.statistics-bookmarks {
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    gap: 32px;
    margin-bottom: 29px;
    margin-top: 23px;
    position: relative;
    button.statistics-bookmark {
        border: none;
        border-bottom: 2px solid transparent;
        outline: none;
        background: transparent;
        align-items: center;
        box-sizing: border-box;
        color: #212121;
        font-family: "Averta CY";
        display: block;
        font-size: 15px;
        font-weight: 400;
        line-height: 160%;
        padding-bottom: 18px;
        position: relative;
        cursor: pointer;
        &:hover {
            border-bottom: 2px solid #005bff;
        }
        &.active {
            border-bottom: 2px solid #005bff;
        }
    }
}
