.indicator-arrow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &.positive {
        transform: rotate(180deg);
        path {
            fill: red;
        }
    }
}
