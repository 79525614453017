section.edit {
    background: #f4f4f8;
    .personal-area-header {
        margin-bottom: 40px;
    }
    header.edit-videos-header {
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 0;
        button {
            cursor: pointer;
            position: relative;
            color: #94a0b1;
            display: block;
            width: 150px;
            font-size: 14px;
            line-height: 24px;
            position: relative;
            text-decoration: none;
            background: transparent;
            border: none;
            outline: none;
            font-family: "Averta CY";
            &:hover {
                color: #005bff;
                path {
                    fill: #005bff;
                }
            }
            svg {
                display: inline-block;
                margin-right: 8px;
                position: relative;
                vertical-align: top;
            }
        }
        .title-wrapper {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 16px;
            margin-top: 24px;
            .list-title-selects {
                margin-top: 13px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 24px;
                white-space: nowrap;
                .list-title-date {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 160%;
                    color: #94a0b1;
                }
                .list-title-select {
                    position: relative;
                    display: block;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 160%;
                    color: #212121;
                    .list-title-select-btn {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        font-size: 15px;
                        line-height: 24px;
                        white-space: nowrap;
                        font-weight: 400;
                        svg {
                            position: relative;
                            display: inline-block;
                            vertical-align: top;
                        }
                    }
                    &:hover {
                        .list-title-select-btn {
                            color: #005bff;
                            svg {
                                path {
                                    fill: #005bff;
                                }
                            }
                        }
                    }
                    &.active {
                        .list-title-select-submenu {
                            display: block;
                        }
                    }
                    .list-title-select-submenu {
                        position: absolute;
                        display: none;
                        right: 0;
                        top: 32px;
                        padding: 16px 20px;
                        background: #ffffff;
                        box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
                        border-radius: 8px;
                        z-index: 10;
                        .list-title-select-submenu-item {
                            position: relative;
                            display: block;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            white-space: nowrap;
                            cursor: pointer;
                            margin-bottom: 8px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:hover {
                                color: #005bff;
                            }
                        }
                    }
                }
            }
        }
        h1 {
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            margin-bottom: 32px;
            margin-top: 24px;
            position: relative;
            display: flex;
        }
    }
    .form-container-blocks {
        align-items: flex-start;
        display: flex;
        gap: 40px;
        justify-content: space-between;
        position: relative;
        .form-container.full-width {
            position: relative;
            display: block;
            width: 100%;
            .form-container-block.empty-datas {
                padding: 52px 32px;
                text-align: center;
                color: #94a0b1;
            }
        }
        .form-container.first-col,
        .full-width {
            display: block;
            position: relative;
            width: calc(100% - 379px);
            .form-container-block {
                background: #fff;
                border-radius: 8px;
                box-sizing: border-box;
                display: block;
                margin-bottom: 16px;
                padding: 32px;
                position: relative;
                &.empty-block {
                    padding: 52px 32px;
                    text-align: center;
                    color: #94a0b1;
                }
                .form-container-block-title {
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 32px;
                    position: relative;
                    b {
                        display: block;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 16px;
                        position: relative;
                    }
                }
                .form-container-progress-blocks {
                    .form-container-progress-block {
                        display: flex;
                        font-size: 14px;
                        height: 24px;
                        justify-content: space-between;
                        line-height: 24px;
                        margin-bottom: 16px;
                        position: relative;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .form-container-progress-block-right {
                            align-items: center;
                            display: flex;
                            justify-content: flex-end;
                            position: relative;
                            width: 60%;
                            .form-container-progress-block-line {
                                border-radius: 6px;
                                height: 6px;
                                overflow: hidden;
                                position: relative;
                                width: calc(100% - 88px);
                                .form-container-progress-block-linefill {
                                    border-radius: 6px;
                                    display: block;
                                    height: 6px;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                }
                            }
                            span {
                                display: block;
                                position: relative;
                                text-align: right;
                                width: 44px;
                            }
                        }
                    }
                    &.pink {
                        .form-container-progress-block-linefill {
                            background: #cc5ef5;
                        }
                        span {
                            color: #cc5ef5;
                        }
                    }
                    &.blue {
                        .form-container-progress-block-linefill {
                            background: #005bff;
                        }
                        span {
                            color: #005bff;
                        }
                    }
                    &.green {
                        .form-container-progress-block-linefill {
                            background: #0dc268;
                        }
                        span {
                            color: #0dc268;
                        }
                    }
                }
                .form-container-block-peoples {
                    display: block;
                    font-size: 0;
                    margin-bottom: 8px;
                    position: relative;
                    color: #212121;
                    .form-container-block-people {
                        background: #f4f4f8;
                        border-radius: 80px;
                        cursor: pointer;
                        display: inline-block;
                        margin-bottom: 8px;
                        margin-right: 8px;
                        position: relative;
                        span {
                            display: block;
                            font-size: 14px;
                            line-height: 40px;
                            padding: 0 35px 0 18px;
                            position: relative;
                        }
                        &:last-child {
                            margin-bottom: 16px;
                        }
                        .remove-people {
                            cursor: pointer;
                            display: block;
                            font-size: 0;
                            height: 20px;
                            position: absolute;
                            right: 10px;
                            top: 10px;
                        }
                    }
                }
                .button.add-people.action-button.border-button.small-button {
                    box-sizing: border-box;
                    font-weight: 600;
                    line-height: 36px;
                    margin-top: -8px;
                    white-space: nowrap;
                    width: 127px;
                    border-radius: 20px;
                    font-size: 14px;
                    height: 40px;
                    background: none;
                    border: 2px solid #d1d8e1;
                    color: #212121;
                    cursor: pointer;
                    display: block;
                    padding: 0 32px;
                    position: relative;
                    text-align: center;
                    padding-right: 20px;
                    padding-left: 40px;
                    &::after {
                        background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
                        content: "";
                        display: block;
                        height: 16px;
                        left: 16px;
                        position: absolute;
                        top: 10px;
                        width: 16px;
                    }
                    &:hover {
                        border: 2px solid #005bff;
                    }
                }
                .form-container-block-subtitle {
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 24px;
                    margin-top: 32px;
                    position: relative;
                    &.time-duration {
                        margin-bottom: 16px;
                    }
                }
                .flex-block.form-container-block-preview {
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D1D8E1' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    height: 360px;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    &:hover {
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23005BFF' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
                    }
                    .form-container-block-preview-image {
                        display: block;
                        height: 100%;
                        left: 0;
                        pointer-events: none;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }
                    input {
                        cursor: pointer;
                        display: block;
                        height: 100%;
                        left: 0;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }
                    .form-container-block-preview-data {
                        color: #94a0b1;
                        display: block;
                        font-size: 13px;
                        line-height: 18px;
                        position: relative;
                        text-align: center;
                        span {
                            color: #212121;
                            display: block;
                            font-size: 14px;
                            line-height: 20px;
                            margin-bottom: 40px;
                            margin-top: 17px;
                            position: relative;
                        }
                    }
                }
                .flex-block.form-container-photo-gallery {
                    display: block;
                    position: relative;
                    span {
                        color: #94a0b1;
                        display: block;
                        font-size: 14px;
                        line-height: 23px;
                        margin-bottom: 16px;
                        position: relative;
                    }
                    .form-container-photo-gallery-items {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
                        justify-content: flex-start;
                        position: relative;
                        width: 100%;
                        .form-container-photo-gallery-item {
                            align-items: center;
                            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D1D8E1' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
                            border-radius: 8px;
                            display: flex;
                            height: 152px;
                            justify-content: center;
                            position: relative;
                            width: 152px;
                            &:hover {
                                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23005BFF' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
                            }
                            .remove-preview-btn {
                                align-items: center;
                                background: #fff;
                                border-radius: 16px;
                                cursor: pointer;
                                display: flex;
                                height: 32px;
                                justify-content: center;
                                position: absolute;
                                right: 8px;
                                top: 8px;
                                width: 32px;
                                &:hover {
                                    svg {
                                        path {
                                            fill: #005bff;
                                        }
                                    }
                                }
                            }
                        }
                        .form-container-photo-gallery-item.empty {
                            cursor: pointer;
                            align-items: center;
                            border-radius: 8px;
                            display: flex;
                            height: 152px;
                            justify-content: center;
                            position: relative;
                            width: 152px;
                            &::after {
                                background: url("/public/images/icons/ui/actions/gray/plus_big.svg");
                                background-size: 24px 24px;
                                content: "";
                                display: block;
                                height: 24px;
                                left: calc(50% - 12px);
                                pointer-events: none;
                                position: absolute;
                                top: calc(50% - 12px);
                                width: 24px;
                            }
                            &.loading::after {
                                animation: loaderAnimation 1s linear infinite;
                                background: url("/public/images/icons/ui/status/blue/loader.svg") no-repeat;
                                background-size: contain;
                            }
                            input {
                                cursor: pointer;
                                display: block;
                                height: 100%;
                                left: 0;
                                opacity: 0;
                                position: absolute;
                                top: 0;
                                width: 100%;
                            }
                        }
                    }
                }
                .flex-block.flex-start {
                    display: flex;
                    gap: 16px;
                    justify-content: flex-start;
                    margin-bottom: 24px;
                    position: relative;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .form-field {
                        display: block;
                        position: relative;
                        width: 100%;
                        .time-duration {
                            position: relative;
                            font-size: 15px;
                            line-height: 24px;
                        }
                        .form-field-label {
                            color: #94a0b1;
                            display: block;
                            font-size: 14px;
                            line-height: 22px;
                            margin-bottom: 5px;
                            margin-top: -3px;
                            position: relative;
                        }
                        .input-field {
                            display: block;
                            position: relative;
                            textarea {
                                background: #f4f4f8;
                                border: 1px solid #f4f4f8;
                                border-radius: 8px;
                                box-sizing: border-box;
                                color: #212121;
                                display: block;
                                font-family: Averta CY;
                                font-size: 15px;
                                height: 54px;
                                line-height: 24px;
                                min-height: 54px;
                                outline: none;
                                padding: 15px 20px;
                                position: relative;
                                resize: none;
                                width: 100%;
                                &:read-only,
                                &:disabled {
                                    background: #d1d8e1;
                                    border: 1px solid #d1d8e1;
                                    cursor: default;
                                    color: #94a0b1;
                                    &:hover {
                                        border: 1px solid #d1d8e1;
                                    }
                                }
                                &::-webkit-scrollbar {
                                    border-radius: 2px;
                                    display: block;
                                    height: calc(100% - 32px);
                                    overflow: hidden;
                                    pointer-events: none;
                                    position: absolute;
                                    right: 8px;
                                    top: 16px;
                                    width: 4px;
                                    cursor: default;
                                    pointer-events: none;
                                }
                                &::-webkit-scrollbar-thumb {
                                    background: #94a0b160;
                                    border-radius: 2px;
                                    height: 30%;
                                    width: 100%;
                                    pointer-events: none;
                                }
                            }
                            input {
                                background: #f4f4f8;
                                border: 1px solid #f4f4f8;
                                border-radius: 8px;
                                box-sizing: border-box;
                                color: #212121;
                                display: block;
                                font-family: Averta CY;
                                font-size: 15px;
                                height: 54px;
                                outline: none;
                                padding: 0 20px;
                                position: relative;
                                width: 100%;
                                &.error {
                                    border: 1px solid #ed0a34;
                                }
                                &:read-only,
                                &:disabled {
                                    background: #d1d8e1;
                                    border: 1px solid #d1d8e1;
                                    cursor: default;
                                    color: #94a0b1;
                                    &:hover {
                                        border: 1px solid #d1d8e1;
                                    }
                                }
                            }
                            input:focus {
                                border-color: #005bff;
                            }
                            input:hover {
                                border: 1px solid #d1d8e1;
                            }
                            .select-block {
                                background: #f4f4f8;
                                border: 1px solid #f4f4f8;
                                border-radius: 8px;
                                cursor: pointer;
                                display: block;
                                font-size: 15px;
                                height: 54px;
                                outline: none;
                                position: relative;
                                .select-block-button {
                                    font-size: 15px;
                                    line-height: 54px;
                                    padding: 0 20px;
                                    span {
                                        display: block;
                                        overflow: hidden;
                                        position: absolute;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: calc(100% - 60px);
                                    }
                                }
                                &::after {
                                    background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
                                    content: "";
                                    display: block;
                                    height: 24px;
                                    pointer-events: none;
                                    position: absolute;
                                    right: 16px;
                                    top: 16px;
                                    width: 24px;
                                }
                                &:hover {
                                    border: 1px solid #d1d8e1;
                                }
                                .select-block-list {
                                    background: #fff;
                                    border-radius: 8px;
                                    box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
                                    cursor: default;
                                    display: none;
                                    overflow-x: hidden;
                                    position: absolute;
                                    top: calc(100% + 8px);
                                    width: 100%;
                                    z-index: 100;
                                    scrollbar-width: 4px;
                                    scrollbar-color: #94a0b160;
                                    .select-block-list-scroll {
                                        display: block;
                                        max-height: 280px;
                                        position: relative;
                                        width: calc(100% + 20px);
                                        .select-block-item:first-child {
                                            margin-top: 16px;
                                        }
                                        .select-block-item {
                                            cursor: pointer;
                                            display: block;
                                            font-size: 15px;
                                            height: 40px;
                                            line-height: 40px;
                                            padding: 0 20px;
                                            position: relative;
                                            &:hover {
                                                background: #f4f4f8;
                                                span {
                                                    color: #005bff;
                                                }
                                            }
                                        }
                                    }
                                    &::-webkit-scrollbar {
                                        border-radius: 2px;
                                        display: block;
                                        height: calc(100% - 32px);
                                        overflow: hidden;
                                        pointer-events: none;
                                        position: absolute;
                                        right: 8px;
                                        top: 16px;
                                        width: 4px;
                                        cursor: default;
                                        pointer-events: none;
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background: #94a0b160;
                                        border-radius: 2px;
                                        height: 30%;
                                        width: 100%;
                                        pointer-events: none;
                                    }
                                }
                            }
                            .select-block.active {
                                ::after {
                                    transform: rotate(180deg);
                                }
                                .select-block-list {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-container-right.second-col {
            background: #fff;
            border-radius: 8px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 32px;
            position: relative;
            width: 339px;
            .form-container-right-b {
                display: block;
                font-size: 15px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 11px;
                position: relative;
                span {
                    display: block;
                    font-weight: 400;
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 14px;
                    line-height: 23px;
                }
            }
            .form-container-right-color {
                display: block;
                font-size: 14px;
                line-height: 23px;
                margin-bottom: 4px;
                padding-left: 14px;
                position: relative;
                &:after {
                    background: #d1d8e1;
                    border-radius: 6px;
                    content: "";
                    display: block;
                    height: 6px;
                    left: 0;
                    position: absolute;
                    top: calc(50% - 3px);
                    width: 6px;
                }
                span {
                    display: block;
                    font-weight: 400;
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 14px;
                    line-height: 23px;
                    span {
                        color: #94a0b1;
                        display: inline-block;
                        position: relative;
                    }
                }
                &.blue::after {
                    background: #005bff;
                }
            }
            .form-container-right-progress {
                background: #e2e8f0;
                border-radius: 3px;
                display: block;
                height: 6px;
                margin-bottom: 24px;
                margin-top: 19px;
                overflow: hidden;
                position: relative;
                .form-container-right-progressline {
                    background: #005bff;
                    border-radius: 3px;
                    height: 6px;
                    position: absolute;
                }
            }
            a {
                text-decoration: none;
            }
            .button.action-button {
                background: #005bff;
                border-radius: 24px;
                box-sizing: border-box;
                color: #fff;
                cursor: pointer;
                display: block;
                font-size: 15px;
                height: 48px;
                line-height: 48px;
                padding: 0 32px;
                position: relative;
                text-align: center;
                text-decoration: none;
                &:hover {
                    background: #004ace;
                }
            }
            .form-container-right-divider {
                background: #e2e8f0;
                display: block;
                height: 1px;
                margin: 24px -32px;
                position: relative;
            }
            .form-container-right-item {
                display: block;
                font-size: 14px;
                line-height: 23px;
                margin-bottom: 4px;
                position: relative;
                span {
                    display: block;
                    font-weight: 400;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            .button.border-button {
                padding: 0 32px;
                position: relative;
                text-align: center;
                cursor: pointer;
                display: block;
                font-size: 15px;
                height: 48px;
                border-radius: 24px;
                box-sizing: border-box;
                background: none;
                border: 2px solid #d1d8e1;
                color: #212121;
                line-height: 44px;
                margin-bottom: 8px;
                &:hover {
                    border: 2px solid #005bff;
                }
            }
            .button.disabled {
                background: #d1d8e1;
                color: #94a0b1;
                pointer-events: none;
            }
            .remove-video-button.action-button {
                color: #94a0b1;
                cursor: pointer;
                display: block;
                font-size: 15px;
                line-height: 24px;
                margin-top: 26px;
                position: relative;
                text-align: center;
                svg {
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    vertical-align: top;
                }
                &:hover {
                    color: #005bff;
                    path {
                        fill: #005bff;
                    }
                }
            }
        }
    }
}
