.list-title-select {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: #212121;
  .list-title-select-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
    white-space: nowrap;
    font-weight: 400;
    svg {
      position: relative;
      display: inline-block;
      vertical-align: top;
    }
  }
  &:hover {
    .list-title-select-btn {
      color: #7027c2;
      svg {
        path {
          fill: #7027c2;
        }
      }
    }
  }
  &.active {
    .list-title-select-submenu {
      display: block;
    }
  }
  .list-title-select-submenu {
    position: absolute;
    display: none;
    right: 0;
    top: 32px;
    padding: 16px 20px;
    background: #ffffff;
    box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
    border-radius: 8px;
    z-index: 10;
    .list-title-select-submenu-item {
      position: relative;
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      cursor: pointer;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: #7027c2;
      }
    }
  }
}
