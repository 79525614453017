.politics-content-wrapper.content-wrapper {
    .list-table-item,
    .list-table-header {
        height: 48px;
        margin-bottom: 0;
    }
    .list-table-header.video-list {
        .list-table-header-element {
            display: flex;
            align-items: center;
            &:first-child {
                padding-left: 8px;
            }
            display: flex;
            align-items: center;
            span {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
    .list-table.video-list {
        .list-table-item {
            cursor: pointer;
            &:read-only {
                cursor: default;
                &:hover {
                    background: transparent;
                }
            }
            &:hover {
                background: #f4f4f8;
            }
            height: 48px;
            .list-table-item-element {
                &:first-child {
                    padding-left: 8px;
                }
                display: flex;
                align-items: center;
                height: 48px;
                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 21px;
                }
            }
        }
    }
}
.modal-field.edit-politic-modal {
    & > .flex-block-col + .flex-block-col {
        margin-top: 21px;
    }
    .flex-block-col {
        padding-bottom: 24px;
        border-bottom: 1px solid #e2e8f0;
        .form-field-label.bold {
            line-height: 24px;
            margin: 16px 0;
            font-weight: 600;
            font-size: 15px;
            color: #212121;
        }
        & > * + * {
            margin-top: 21px;
        }
    }
}
