// embing
.optionListContainer {
  background: #fff;
  border-radius: 8px;
  border-top: 0;
  box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
  top: calc(100% + 8px);
  z-index: 1010;
  scrollbar-width: thin;
  scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
  padding: 12px 0;
  .optionContainer {
    color: #444;
    margin: 0;
    max-height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: relative;
    width: 100%;
    scrollbar-width: thin;
    border: none;
    scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
    &::-webkit-scrollbar {
      border-radius: 2px;
      display: block;
      height: calc(100% - 32px);
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      right: 8px;
      top: 16px;
      width: 4px;
      cursor: default;
      pointer-events: none;
    }
    &::-webkit-scrollbar-thumb {
      background: #94a0b160;
      border-radius: 2px;
      height: 30%;
      width: 100%;
      pointer-events: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 8px 0;
    }
    li {
      cursor: pointer;
      display: list-item;
      font-size: 15px;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      position: relative;
      &:hover {
        color: #7027c2;
      }
      &:hover {
        background: #f4f4f8;
      }
    }
  }
}
.form-container-block-embedding {
  .embedding-content {
    margin-top: 32px;
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
    }
    .embedding-list {
      margin-top: 16px;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      border: 1px solid #e2e8f0;
      border-radius: 16px;
      padding: 8px 24px;
      &__item.first {
        border-top: none;
      }
      &__item {
        border-top: 1px solid #e2e8f0;
        display: flex;
        justify-content: space-between;
        height: 48px;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 15px;
        }
        .embedding-control-item {
          cursor: pointer;
          &:hover {
            path {
              fill: #7027c2;
            }
          }
        }
      }
    }
  }
  .actions-wrapper {
    height: 40px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-embedding {
      box-sizing: border-box;
      font-weight: 600;
      line-height: 36px;
      white-space: nowrap;
      width: 127px;
      border-radius: 20px;
      font-size: 14px;
      height: 40px;
      background: none;
      border: 2px solid #d1d8e1;
      color: #212121;
      cursor: pointer;
      display: block;
      padding: 0 32px;
      position: relative;
      text-align: center;
      padding-right: 20px;
      padding-left: 40px;
      &::before {
        background: url("/public/images/icons/ui/add-plus.svg") no-repeat;
        content: "";
        display: block;
        height: 16px;
        left: 16px;
        position: absolute;
        top: 10px;
        width: 16px;
      }
      &:hover {
        border: 2px solid #7027c2;
      }
    }
    .remove-embedding {
      color: #94a0b1;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      cursor: pointer;
      &:hover {
        color: #7027c2;
      }
    }
  }
}
