section.viewers {
    .list-table-header {
        &.seller-list.viewer-list {
            margin-top: 48px;
            padding-right: 16px;
            .list-table-header-element {
                background: transparent;
                border: none;
                outline: none;
                box-sizing: border-box;
                color: #94a0b1;
                cursor: pointer;
                font-size: 14px;
                line-height: 24px;
                padding-left: 20px;
                display: flex;
                position: relative;
                span {
                    line-height: 17px;
                    white-space: nowrap;
                }
                &:nth-child(1) {
                    span {
                        padding-left: 0;
                        width: 100%;
                    }
                }
            }
        }
    }

    .list-table {
        a {
            text-decoration: none;
            color: #212121;
            font-family: "Averta CY";
        }
        &.selers-list.viewer-list {
            .list-table-item {
                height: 64px;
                border-bottom: 1px solid #e2e8f0;
                display: flex;
                justify-content: space-between;
                position: relative;
                align-items: flex-start;
                margin-bottom: 8px;
                border: 1px solid #e2e8f0;
                border-radius: 8px;
                box-sizing: border-box;
                padding: 12px 0;
                cursor: pointer;
                padding-right: 16px;
                &:hover {
                    background: none;
                    border: 1px solid #005bff;
                }

                .list-table-item-element-info {
                    padding-left: 14px;
                    line-height: 30px;
                    margin-top: 0;
                    margin-bottom: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    white-space: nowrap;
                    position: absolute;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 68px);
                    height: 40px;
                    span {
                        display: block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: 22px;
                    }
                    small {
                        margin-top: 22px;
                        position: relative;
                        display: block;
                        text-align: left;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #94a0b1;
                    }
                }
                .list-table-item-element {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: 30px;
                    font-size: 14px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    position: relative;
                    height: 40px;
                    span,
                    small {
                        display: block;
                        align-items: center;
                        overflow: hidden;
                        position: absolute;
                        text-overflow: ellipsis;
                        white-space: nowrap !important;
                        width: calc(100% - 20px);
                    }
                }
                .list-table-item-element:nth-child(1) {
                    img {
                        border-radius: 48px;
                        left: -48px;
                        object-fit: cover;
                        position: relative;
                        width: 48px;
                        height: 48px;
                    }
                    padding-left: 0;
                    left: 60px;
                    width: 100%;
                }
            }
        }
    }
}
