.modal-field.create-people-modal.active,
.create-episode-modal.active.modal-field {
    .flex-block {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        margin-bottom: 24px;
        position: relative;
        .form-field {
            display: block;
            position: relative;
            width: 100%;
            margin-bottom: 0;
            .form-field-label {
                color: #94a0b1;
                display: block;
                font-size: 14px;
                line-height: 22px;
                height: 22px;
                margin-bottom: 5px;
                margin-top: -3px;
                position: relative;
            }
            .input-field {
                display: block;
                position: relative;
                input {
                    background: #f4f4f8;
                    border: 1px solid #f4f4f8;
                    border-radius: 8px;
                    box-sizing: border-box;
                    color: #212121;
                    display: block;
                    font-family: "Averta CY";
                    font-size: 15px;
                    height: 54px;
                    outline: none;
                    padding: 0 20px;
                    position: relative;
                    width: 100%;
                    &:hover {
                        border: 1px solid #d1d8e1;
                    }
                    &:focus {
                        border: 1px solid #005bff;
                    }
                    &:read-only,
                    &:disabled {
                        background: #d1d8e1;
                        border: 1px solid #d1d8e1;
                        cursor: default;
                        color: #94a0b1;
                        &:hover {
                            border: 1px solid #d1d8e1;
                        }
                    }
                }
                textarea {
                    &:read-only,
                    &:disabled {
                        background: #d1d8e1;
                        border: 1px solid #d1d8e1;
                        cursor: default;
                        color: #94a0b1;
                        &:hover {
                            border: 1px solid #d1d8e1;
                        }
                    }
                }
            }
        }
    }
    .flex-block.form-container-block-preview {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D1D8E1' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        height: 360px;
        justify-content: center;
        align-items: center;
        position: relative;
        &:hover {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23005BFF' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
        }
        .form-container-block-preview-image {
            display: block;
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
        }
        input {
            cursor: pointer;
            display: block;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .form-container-block-preview-data {
            color: #94a0b1;
            display: block;
            font-size: 13px;
            line-height: 18px;
            position: relative;
            text-align: center;
            span {
                color: #212121;
                display: block;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 40px;
                margin-top: 17px;
                position: relative;
            }
        }
    }
    .flex-block.form-container-photo-gallery {
        display: block;
        position: relative;
        span {
            color: #94a0b1;
            display: block;
            font-size: 14px;
            line-height: 23px;
            margin-bottom: 16px;
            position: relative;
        }
        .form-container-photo-gallery-items {
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            position: relative;
            width: 100%;
            flex-wrap: wrap;
            .form-container-photo-gallery-item {
                align-items: center;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D1D8E1' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
                border-radius: 8px;
                display: flex;
                height: 152px;
                justify-content: center;
                position: relative;
                width: 152px;
                &:hover {
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23005BFF' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
                }
                .remove-preview-btn {
                    align-items: center;
                    background: #fff;
                    border-radius: 16px;
                    cursor: pointer;
                    display: flex;
                    height: 32px;
                    justify-content: center;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    width: 32px;
                    &:hover {
                        svg {
                            path {
                                fill: #005bff;
                            }
                        }
                    }
                }
            }
            .form-container-photo-gallery-item.empty {
                cursor: pointer;
                align-items: center;
                border-radius: 8px;
                display: flex;
                height: 152px;
                justify-content: center;
                position: relative;
                width: 152px;
                &::after {
                    background: url("/public/images/icons/ui/actions/gray/plus_big.svg");
                    background-size: 24px 24px;
                    content: "";
                    display: block;
                    height: 24px;
                    left: calc(50% - 12px);
                    pointer-events: none;
                    position: absolute;
                    top: calc(50% - 12px);
                    width: 24px;
                }
                &.loading {
                    pointer-events: none;
                }
                &.loading::after {
                    animation: loaderAnimation 1s linear infinite;
                    background: url("/public/images/icons/ui/status/blue/loader.svg") no-repeat;
                    background-size: contain;
                }
                input {
                    cursor: pointer;
                    display: block;
                    height: 100%;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }
        }
    }
}
