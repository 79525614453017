.modal-field.create-playlists {
	.form-container-block-subtitle {
		display: block;
		font-size: 15px;
		font-weight: 600;
		margin-top: 32px;
		position: relative;
		&.time-duration {
			margin-bottom: 16px;
		}
	}
	.form-container-block-preview {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D1D8E1' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
		border-radius: 8px;
		cursor: pointer;
		display: flex;
		height: 360px;
		justify-content: center;
		align-items: center;
		position: relative;
		&:hover {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23005BFF' stroke-dasharray='8 4' stroke-linecap='square'/%3E%3C/svg%3E");
		}
		.form-container-block-preview-image {
			display: block;
			height: 100%;
			left: 0;
			pointer-events: none;
			position: absolute;
			top: 0;
			width: 100%;
		}
		input {
			cursor: pointer;
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
		.form-container-block-preview-data {
			color: #94a0b1;
			display: block;
			font-size: 13px;
			line-height: 18px;
			position: relative;
			text-align: center;
			span {
				color: #212121;
				display: block;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 40px;
				margin-top: 17px;
				position: relative;
			}
		}
	}
}
