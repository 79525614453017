.gadgets-statistic-header {
    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        align-items: center;
    }
}

.gadgets-statistic-content {
    width: 100%;
    .income-info-block.gadgets {
        padding-left: 0;
        padding-right: 0;
    }
}
.gadgets-statistic-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    .statistic-description-header,
    .statistic-description-content .statistic-content-wrapper {
        display: flex;
        width: 100%;
        gap: 10px;
        .statistic-description-row-element {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            &:first-child {
                justify-content: flex-start;
            }
        }
    }
    .statistic-description-header {
        .statistic-description-row-element {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: #94a0b1;
        }
    }
    .statistic-description-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 24px;
        align-items: center;
        margin-top: 11px;
        &.blue {
            margin-top: 21px;
        }
        .form-container-progress-block-right {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            * + * {
                margin-left: 16px;
            }
            span.percent-value {
                min-width: 30px;
                text-align: end;
            }
            span.current-value {
                text-align: end;
                min-width: 70px;
            }
            .form-container-progress-block-line {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                height: 4px;
                border-radius: 4px;
                .form-container-progress-block-linefill {
                    border-radius: 4px;
                }
            }
        }
        b {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            padding-left: 22px;
            position: absolute;
            &::before {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0;
                border-radius: 10px;
                top: calc(50% - 5px);
                background: #005bff;
            }
        }
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            white-space: nowrap;
        }
        &.blue {
            span {
                color: #005bff;
            }
            .form-container-progress-block-linefill,
            b::before {
                background: #005bff;
            }
        }
        &.green {
            span {
                color: #0dc268;
            }
            .form-container-progress-block-linefill,
            b::before {
                background: #0dc268;
            }
        }
        &.pink {
            span {
                color: #fd4aa7;
            }
            .form-container-progress-block-linefill,
            b::before {
                background: #fd4aa7;
            }
        }
        &.purple {
            span {
                color: #cc5ef5;
            }
            .form-container-progress-block-linefill,
            b::before {
                background: #cc5ef5;
            }
        }
    }
}
