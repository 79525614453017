.form-container.first-col.video-politics {
  .form-container {
    .form-container-block {
      .form-container-block-title {
        display: flex;
        justify-content: space-between;
        .button_edit-politics.button {
          display: flex;
          background: transparent;
          border: none;
          outline: none;
          align-items: center;
          cursor: pointer;
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
          }
          &:hover {
            color: #7027c2;
            svg {
              path {
                fill: #7027c2;
              }
            }
          }
          * + * {
            margin-left: 8px;
          }
        }
      }
      .flex-block-col {
        padding-bottom: 32px;
        border-bottom: 1px solid #e2e8f0;
        & > * + * {
          margin-top: 24px;
        }
        .form-field {
          .form-field-label {
            color: #94a0b1;
            display: block;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 5px;
            margin-top: -3px;
            position: relative;
          }
          .input-field {
            .selected-country-wrapper {
              margin-top: 24px;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              gap: 8px;
              .selected-country {
                padding: 8px 16px;
                background: #f4f4f8;
                border-radius: 80px;
                font-weight: 400;
                font-size: 13px;
              }
            }
          }
        }

        span,
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
        }
        p {
          margin-top: 16px;
          margin-bottom: 32px;
        }
      }
      .button.border-button {
        display: flex;
        align-items: center;
        margin-top: 24px;
        background: transparent;
        border: 2px solid #d1d8e1;
        border-radius: 80px;
        outline: none;
        padding: 9px 20px 9px 18px;
        cursor: pointer;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
        }
        &:hover {
          border: 2px solid #7027c2;
        }
        * + * {
          margin-left: 6px;
        }
      }
    }
  }
}

.modal-field.edit-politic-modal {
  & > .flex-block-col + .flex-block-col {
    margin-top: 21px;
  }
  .flex-block-col {
    padding-bottom: 24px;
    border-bottom: 1px solid #e2e8f0;
    .form-field-label.bold {
      line-height: 24px;
      margin: 16px 0;
      font-weight: 600;
      font-size: 15px;
      color: #212121;
    }
    & > * + * {
      margin-top: 21px;
    }
  }
}
