.playlist-control {
    // width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 16px 0 64px;
    cursor: default;
    .playlist-control-buttons {
        width: 100%;
        position: relative;
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #94a0b1;
        gap: 24px;
        .action-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            &:hover {
                color: #005bff;
                svg {
                    path {
                        fill: #005bff;
                    }
                }
            }
        }
        svg {
            path {
                fill: #94a0b1;
            }
        }
    }
    .video-type-card {
        margin-top: 20px;
        width: 350px;
        height: 69px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 12px 16px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        &__title {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: #94a0b1;
            line-height: 21px;
        }
        .card-content-wrapper {
            display: flex;
            justify-content: space-between;
            .video-type__name {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
            }
            .action-button {
                cursor: pointer;
                &:hover {
                    svg {
                        path {
                            fill: #005bff;
                        }
                    }
                }
            }
        }
    }
    .playlist-child-title {
        position: relative;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        &__text {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24%;
            color: #94a0b1;
        }
        .action-button.border-button.small-button {
            border: 2px solid #d1d8e1;
            border-radius: 80px;
            padding: 9px 20px 9px 18px;
            display: flex;
            gap: 6px;
            align-items: center;
            cursor: pointer;
            &:hover {
                border: 2px solid #005bff;
            }
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}
.form-container-block-season-episodes.ui-sortable.playlists {
    padding-bottom: 16px;
    .form-container-block-season-episode {
        &:last-child {
            border-bottom: none !important;
        }
    }
}
