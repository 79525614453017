.personal-area-header {
    display: block;
    height: 72px;
    margin-bottom: 32px;
    width: 100%;
    position: absolute;
    padding: 0 40px 0;
    z-index: 10;

    .personal-area-header-wrapper {
        height: 72px;
        display: block;
        position: relative;
        width: 100%;
    }
    button.button_aside-toggler {
        cursor: pointer;
        display: block;
        left: 0px;
        position: absolute;
        top: 32px;
        width: 24px;
        background: transparent;
        border: none;
        outline: none;
        img {
            width: 24px;
            height: 24px;
        }
    }

    .user-profile-icon {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 24px;
        span {
            cursor: default;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 40px;
            font-size: 16px;
            color: white;
            max-height: 40px;
            text-transform: uppercase;
            &:hover {
                color: white;
            }
        }
    }
}
