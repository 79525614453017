.income-info-block {
  position: relative;
  display: block;
  padding: 24px;
  padding-top: 20px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px;
  width: 100%;

  .recharts-cartesian-grid {
    .recharts-cartesian-grid-horizontal {
      line {
        stroke: #e2e8f0;
        stroke-width: 1px;
      }
    }
  }
  .recharts-responsive-container {
    height: 260px;
  }
  &.first-block {
    margin-top: 48px;
  }
  &.video-depth {
    height: 100%;
    .chart-info {
      display: flex;
    }
  }
  &.category {
    margin-top: 16px;
  }
  .list-title {
    position: initial !important;
    margin-bottom: 45px !important;
  }
  &__header {
    font-size: 15px;
    line-height: 24px;
    display: flex;
    width: 100%;
    &::after {
      content: "";
      width: 100%;
      top: 64px;
      left: 0;
      position: absolute;
      border-bottom: 1px solid #e2e8f0;
    }
    b {
      position: relative;
      display: block;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      &.disactive {
        color: #94a0b1;
        margin-left: 32px;
      }
    }
    button {
      position: relative;
      display: block;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      border: none;
      font-weight: 600;
      background: transparent;
      cursor: pointer;
      font-family: "Averta CY";
      color: #94a0b1;
      &:first-child {
        margin-right: 32px;
      }
      &.active {
        color: #212121;
      }
    }
  }
  .flex-block.flex-start.category {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 37px;
    position: relative;
    .items-wrapper {
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      position: relative;
    }
    button {
      width: 102px;
      height: 32px;
      display: flex;
      background: transparent;
      border: none;
      outline: none;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      span {
        position: relative;
        font-size: 15px;
        color: #212121;
        font-family: "Averta CY";
      }
      &:hover {
        span {
          color: #7027c2;
        }
        path {
          fill: #7027c2;
        }
      }
    }
  }
  .flex-block.flex-start {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    margin-bottom: 17px;
    position: relative;

    .form-field.inline-field {
      margin-right: 16px;
      width: auto;
      display: block;
      position: relative;
      position: relative;
      &.category {
        margin-right: 0;
      }
      .selected-items {
        border-radius: 80px;
        display: flex;
        padding: 4px 12px;
        align-items: center;
        cursor: pointer;
        span {
          line-height: 160%;
          margin-bottom: 0px;
          vertical-align: middle;
          text-align: center;
          position: relative;
          font-size: 13px;
          margin-right: 4px;
          color: white;
        }
      }
      &:first-child {
        .selected {
          &::before {
            background: #d015bb url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50% !important;
            border-color: #d015bb !important;
          }
        }
        // &:hover {
        //     span {
        //         color: #d015bb;
        //     }
        // }
      }
      &:last-child {
        .selected {
          &::before {
            background: #0fb965 url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50% !important;
            border-color: #0fb965 !important;
          }
        }
        // &:hover {
        //     span {
        //         color: #0fb965;
        //     }
        // }
      }
      .check-button-item {
        cursor: pointer;
        display: block;
        font-size: 15px;
        height: 24px;
        line-height: 24px;
        position: relative;
        &::before {
          background: #f4f4f8;
          border: 1px solid #d1d8e1;
          border-radius: 4px;
          box-sizing: border-box;
          content: "";
          display: block;
          height: 24px;
          left: 0;
          position: absolute;
          top: calc(50% - 12px);
          width: 24px;
        }

        &.selected {
          &::before {
            background: #7027c2 url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
            border-color: #7027c2;
          }
        }
        span {
          display: block;
          font-weight: 400;
          overflow: hidden;
          padding-left: 38px;
          position: relative;
          width: auto;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  &__content {
    height: 260px;
    width: 100%;
    display: block;
    #custom-tooltip {
      height: 69px;
      bottom: 87px;
      left: -50%;
      position: relative;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
      p.value {
        color: #212121;
        font-weight: 600;
        font-size: 15px;
        line-height: 160%;
      }
      p.name {
        color: #94a0b1;
        font-size: 15px;
        line-height: 160%;
      }
      img {
        width: 52px;
        left: calc(50% - 26px);
        position: absolute;
        top: 100%;
      }
    }
    #customActiveDot {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      opacity: 0.2;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .active-dot-wrapper {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
    }
  }
}

#home-filter {
  background: #fff;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: -400px;
  width: 370px;
  z-index: 1001;
  top: 0;
  transition: all 0.3s ease-out;
  &.active {
    left: 0;
  }
  .form-field {
    .form-field-label {
      color: #94a0b1;
      display: block;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 5px;
      margin-top: -3px;
      position: relative;
    }
    .input-field {
      input {
        background: #f4f4f8;
        border: 1px solid #f4f4f8;
        border-radius: 8px;
        box-sizing: border-box;
        color: #212121;
        display: block;
        font-family: Averta CY;
        font-size: 15px;
        height: 54px;
        outline: none;
        padding: 0 20px;
        position: relative;
        width: 100%;
      }
    }
  }
  .statistics-filter-list-bar {
    position: absolute;
    display: block;
    width: 4px;
    pointer-events: none;
    top: 16px;
    right: 8px;
    height: calc(100% - 32px);
    border-radius: 2px;
    overflow: hidden;
    .constructor-filter-list-thumb {
      position: absolute;
      height: 30%;
      width: 100%;
      left: 0;
      top: 0;
      background: #94a0b1;
      opacity: 0.3;
      border-radius: 2px;
    }
  }

  .statistics-filter-close {
    position: absolute;
    display: block;
    right: 44px;
    top: 28px;
    cursor: pointer;
    &:hover {
      path {
        fill: #7027c2;
      }
    }
  }
  .statistics-filter-scroller {
    display: block;
    height: 100%;
    overflow-y: scroll;
    padding: 0 44px 0 24px;
    position: absolute;
    width: 390px;
    box-sizing: border-box;
    overflow-x: hidden;
    small {
      position: relative;
      display: block;
      color: #94a0b1;
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 24px;
    }
  }
  .statistics-filter-title {
    position: relative;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 24px;
  }

  .statistics-filter-items {
    margin-bottom: 95px;

    .statistics-filter-items-block {
      // border-bottom: 1px solid #e2e8f0;
      position: relative;
      .flex-block.flex-start {
        position: relative;
        display: flex;
        gap: 16px;
        justify-content: flex-start;
        margin-bottom: 0;
        z-index: 3;
        .flex-block-column {
          .check-button-item {
            cursor: pointer;
            display: block;
            font-size: 15px;
            height: 24px;
            line-height: 24px;
            position: relative;
            span {
              display: block;
              font-weight: 400;
              overflow: hidden;
              padding-left: 38px;
              position: absolute;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: calc(100% - 100px);
            }
            &::before {
              border: 1px solid #d1d8e1;
              border-radius: 4px;
              box-sizing: border-box;
              content: "";
              display: block;
              height: 24px;
              left: 0;
              position: absolute;
              top: calc(50% - 12px);
              width: 24px;
            }
            &.selected {
              &::before {
                background: #7027c2 url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
                border-color: #7027c2;
              }
            }
            &.disabled {
              pointer-events: none;
              &::before {
                background: #d1d8e1;
                border: 1px solid #d1d8e1;
              }
            }
          }
        }
      }
      .statistics-filter-items-block-subitems {
        position: relative;
        display: none;
        padding-top: 10px;
        padding-bottom: 24px;
      }
      .statistics-filter-items-block-title {
        position: relative;
        line-height: 56px;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
        svg {
          display: block;
          position: absolute;
          right: 0;
          top: calc(50% - 12px);
        }
        &:hover {
          color: #7027c2;
          path {
            fill: #7027c2;
          }
        }
      }
      .constructor-filter-items-block {
        display: block;
        margin-bottom: 16px;
        position: relative;
        z-index: 2;
        .constructor-filter-item-title {
          cursor: pointer;
          display: block;
          font-size: 15px;
          font-weight: 600;
          line-height: 24px;
          position: relative;
          text-transform: uppercase;
          svg {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        .constructor-filter-subitems {
          display: none;
          margin-top: 16px;
          position: relative;
        }
        &.open {
          .constructor-filter-subitems {
            z-index: 1;
            display: block;
            .constructor-filter-subitem {
              position: relative;
              border-bottom: 1px solid #e2e8f0;
              &:first-child {
                border-top: 1px solid #e2e8f0;
              }
              &.open {
                .constructor-filter-subitem-title {
                  svg {
                    transform: rotate(180deg);
                  }
                }
                .constructor-filter-subitem-items {
                  display: block;
                }
              }
              .constructor-filter-subitem-title {
                position: relative;
                cursor: pointer;
                padding: 16px 0;
                font-weight: 600;
                svg {
                  position: absolute;
                  display: block;
                  right: 0;
                  top: calc(50% - 12px);
                }
                &:hover {
                  color: #7027c2;
                  path {
                    fill: #7027c2;
                  }
                }
                small {
                  font-weight: 300;
                  font-size: 13px;
                  color: #94a0b1;
                  margin-left: 3px;
                }
              }
              .constructor-filter-subitem-items {
                position: relative;
                padding-bottom: 24px;
                display: none;
                .check-button-item {
                  span {
                    font-weight: 300;
                  }
                }
              }
            }
          }
          .constructor-filter-item-title {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &.open {
        .statistics-filter-items-block-title {
          svg {
            transform: rotate(180deg);
          }
        }
        .statistics-filter-items-block-subitems {
          display: block;
        }
      }
    }
  }
  .statistics-buttons {
    z-index: 3;
    background: #fff;
    border-top: 1px solid #e2e8f0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-left: 0;
    padding: 12px 24px;
    position: absolute;
    width: 100%;
    .button {
      background: #7027c2;
      border-radius: 24px;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 15px;
      height: 48px;
      line-height: 48px;
      padding: 0 32px;
      position: relative;
      text-align: center;
    }
    .button:nth-child(1) {
      width: 135px;
      background: none;
      border: 2px solid #d1d8e1;
      color: #212121;
      line-height: 44px;
      &:hover {
        border: 2px solid #7027c2;
      }
    }
    .button:nth-child(2) {
      width: 152px;
      &:hover {
        background: #004ace;
      }
    }
  }
}
